import { ScheduledData, sourceDataSymbol } from '../interfaces';

export const isScheduledData = <V>(obj: unknown): obj is ScheduledData<V> => {
  return typeof obj === 'object' && !!obj && obj.hasOwnProperty(sourceDataSymbol);
};

export const getDataFromScheduled = <V>(obj: ScheduledData<V>): V => obj[sourceDataSymbol];

export const tryUnwrapScheduledData = <V>(obj: V | ScheduledData<V>): V =>
  isScheduledData(obj) ? getDataFromScheduled(obj) : obj;
