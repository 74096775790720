import { LectaEvents } from './events';
import { ILectaEventHelpers } from './interface';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function createEventHelpers<TEventData>(skyEvents: LectaEvents, event: string): ILectaEventHelpers<TEventData> {
  return {
    send: (data: TEventData) => skyEvents.send<TEventData>(event, data),
    listen: () => skyEvents.listen(event),
  };
}

export function listenCustomEvent<T = void>(eventName: string): Observable<T> {
  // ssr domino shim support
  const windowVar = typeof globalThis !== 'undefined' ? globalThis.window : window;

  return fromEvent<CustomEvent<T>>(windowVar, eventName).pipe(map(event => event.detail));
}

export function sendCustomEvent<T = void>(eventName: string, data?: T): void {
  const event = new CustomEvent(eventName, { detail: data });

  window.dispatchEvent(event);
}

export function createNativeEventHelpers<TEventData>(event: string): ILectaEventHelpers<TEventData> {
  return {
    send: (data: TEventData) => sendCustomEvent<TEventData>(event, data),
    listen: () => listenCustomEvent(event),
  };
}
