import { Injectable } from '@angular/core';
import { getDeviceWidth } from '../utils/get-device-width.utility';
import { getWindow } from '../utils/get-window.utility';
import { USER_AGENT } from '../lecta-device.const';

const SMALL_TABLET_MAX_WIDTH_PX = 1024;

const IS_MOBILE_FORCED = new URLSearchParams(getWindow()?.location.search.slice(1)).get('forceMobile') === 'true';
const IS_SHARE_LESSON = getWindow()?.location.pathname.indexOf('/share/') === 0;
const IS_SMALL_TABLET = getDeviceWidth() <= SMALL_TABLET_MAX_WIDTH_PX;

@Injectable({ providedIn: 'root' })
export class LectaDeviceService {
  private static IS_MOBILE = LectaDeviceService.initMobile(USER_AGENT);
  private static IS_TABLET = LectaDeviceService.initTablet(USER_AGENT);
  private static USER_AGENT = USER_AGENT;

  private static initMobile(parserResult: UAParser.IResult): boolean {
    const IS_MOBILE_UA = parserResult.device.type === 'mobile';
    const IS_TABLET_UA = parserResult.device.type === 'tablet';

    return IS_MOBILE_UA || IS_MOBILE_FORCED || (IS_TABLET_UA && IS_SMALL_TABLET && IS_SHARE_LESSON);
  }

  private static initTablet(parserResult: UAParser.IResult): boolean {
    const IS_TABLET_UA = parserResult.device.type === 'tablet';

    return IS_TABLET_UA && !LectaDeviceService.initMobile(parserResult);
  }

  static isMobile(): boolean {
    // force mobile for views started inside mobile app
    return LectaDeviceService.IS_MOBILE;
  }

  static isTouchDevice(): boolean {
    return 'ontouchstart' in window || !!navigator.maxTouchPoints;
  }

  static isTablet(): boolean {
    return LectaDeviceService.IS_TABLET;
  }

  static isDesktop(): boolean {
    return !LectaDeviceService.isMobile() && !LectaDeviceService.isTablet();
  }

  static isIos(): boolean {
    return (
      LectaDeviceService.USER_AGENT.browser.name === 'Mobile Safari' ||
      // iPad Safari >13, simulated macOS
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
      //  not Safari, but iOS
      LectaDeviceService.USER_AGENT.device.model === 'iPhone' ||
      LectaDeviceService.USER_AGENT.device.model === 'iPad' ||
      LectaDeviceService.USER_AGENT.device.model === 'iPod'
    );
  }

  static isSafari(): boolean {
    return !!LectaDeviceService.USER_AGENT.browser.name?.toLowerCase().includes('safari');
  }
}
