import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { CurrentUserService } from '../../service/current-user';
import { UserNavigationService } from '../../service/navigation';
import { UserRole } from '../../const';

@Injectable({ providedIn: 'root' })
export class SchoolAccountActivateRedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private userNavigationService: UserNavigationService,
  ) {}

  canActivate({ queryParams }: ActivatedRouteSnapshot): UrlTree {
    const user = this.currentUserService.getUser();
    const redirectPage = !user
      ? this.userNavigationService.getLoginUrl()
      : this.userNavigationService.getDefaultUrlByRole(UserRole.student);
    return this.router.createUrlTree([redirectPage], { queryParams: queryParams });
  }
}
