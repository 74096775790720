import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserRegistryService } from '../../service/user-registry';
import { UserRole } from 'user/core/const';

@Injectable({ providedIn: 'root' })
export abstract class SetRoleGuard implements CanActivate {
  protected constructor(protected userRegistryService: UserRegistryService) {}

  protected abstract getRole(): UserRole | null;

  /**
   * Установка роли приводит к перепроверке сохраненного токена.
   * Это может привести к разлогину / редиректу на логин
   * @see AuthService
   */
  canActivate(): boolean {
    const role = this.getRole();
    this.userRegistryService.setCurrentRole(role);
    return true;
  }
}
