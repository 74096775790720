import { Injectable, Injector, OnDestroy, Optional, Type } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { LectaDialogInstance } from './lecta-dialog-instance.class';
import { LectaDialogConfig } from './lecta-modal-dialog.const';
import { LectaOpenDialogConfig } from './lecta-modal-dialog.interface';

@Injectable({ providedIn: 'root' })
@DecorateUntilDestroy()
export class LectaDialogService implements OnDestroy {
  private openedDialogs: LectaDialogInstance<unknown, unknown>[] = [];

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private location: Location,
    @Optional() private baseDialogConfig?: LectaDialogConfig,
  ) {}

  ngOnDestroy(): void {
    this.detachOpenDialogs();
  }

  detachOpenDialogs(): void {
    this.openedDialogs.forEach(dialogInstance => dialogInstance.detach());
  }

  open<TComponentData = void, TValue = void>(
    component: Type<unknown>,
    componentData?: TComponentData,
    openDialogConfig?: LectaOpenDialogConfig,
  ): LectaDialogInstance<TValue, TComponentData> {
    const overlayRef = this.createOverlay();
    const dialogInstance = this.createDialog<TComponentData, TValue>(
      overlayRef,
      component,
      componentData!,
      openDialogConfig,
    );

    this.openedDialogs = [...this.openedDialogs, dialogInstance] as LectaDialogInstance<unknown, unknown>[];

    dialogInstance
      .onClose()
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        () => (this.openedDialogs = this.openedDialogs.filter(openedDialog => openedDialog !== dialogInstance)),
      );

    return dialogInstance;
  }

  private createOverlay(): OverlayRef {
    return this.overlay.create({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }

  private createDialog<TComponentData, TValue>(
    overlayRef: OverlayRef,
    component: Type<unknown>,
    componentData: TComponentData,
    openDialogConfig?: LectaOpenDialogConfig,
  ): LectaDialogInstance<TValue, TComponentData> {
    const dialogInstance = new LectaDialogInstance<TValue, TComponentData>(overlayRef, componentData, this.location, {
      ...this.baseDialogConfig,
      ...openDialogConfig,
    });

    const injector = Injector.create({
      providers: [
        {
          provide: LectaDialogInstance,
          useValue: dialogInstance,
        },
      ],
      parent: this.injector,
    });

    overlayRef.attach(new ComponentPortal(component, undefined, injector));

    return dialogInstance;
  }
}
