import { UAParser } from 'ua-parser-js';

export const USER_AGENT = new UAParser().getResult();

export enum MobileAppPlatform {
  android = 'Android',
  ios = 'iOS',
}

export enum MobileFeatures {
  canResolveStepConflicts = 'CAN_RESOLVE_STEP_CONFLICTS',
  hasNativeVideo = 'HAS_NATIVE_VIDEO',
  multiplatform = 'MULTIPLATFORM',
  needUseChatDb = 'NEED_USE_CHAT_DB',
}

export enum DeviceEnvironment {
  browser = 'browser',
  mobile = 'mobile',
}
