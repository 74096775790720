import { Observable, Subject, Subscription, SubscriptionLike } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { LectaDialogConfig } from './lecta-modal-dialog.const';
import { LectaDialogCloseEvent, LectaOpenDialogConfig } from './lecta-modal-dialog.interface';

export class LectaDialogInstance<TValue, TData> {
  private closed = new Subject<LectaDialogCloseEvent<TValue>>();
  private shouldClose = new Subject<void>(); // needs for animation
  private value: TValue | undefined;
  private rejected: boolean;
  private disposeByLocationChanges: SubscriptionLike;
  private detachmentsSubscriptions: Subscription;

  constructor(
    private overlayRef: OverlayRef,
    readonly data: TData,
    private location: Location,
    private config?: Partial<LectaOpenDialogConfig | LectaDialogConfig>,
  ) {
    if (this.config?.closeOnNavigation) {
      this.disposeByLocationChanges = this.location.subscribe(() => this.dispose({ forceRejected: true }));
    }

    this.detachmentsSubscriptions = this.overlayRef.detachments().subscribe(() => this.dispose());
  }

  close(options: { value?: TValue; rejected: boolean } = { rejected: false }): void {
    this.value = options.value;
    this.rejected = options.rejected;
    this.shouldClose.next();
    this.shouldClose.complete();
  }

  detach(): void {
    this.overlayRef.detach();
  }

  onShouldClose(): Observable<void> {
    return this.shouldClose.asObservable();
  }

  onClose(): Observable<LectaDialogCloseEvent<TValue>> {
    return this.closed.asObservable();
  }

  private dispose(disposeParams?: { forceRejected: boolean }): void {
    this.disposeByLocationChanges?.unsubscribe();
    this.detachmentsSubscriptions?.unsubscribe();

    this.closed.next({
      data: this.value,
      rejected: this.rejected ?? disposeParams?.forceRejected,
    });
    this.closed.complete();
    this.overlayRef.dispose();
  }
}
