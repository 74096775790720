import { Injectable } from '@angular/core';
import { LectaLocalStorageService } from 'core/local-storage';
import {combineLatest, Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'core/store';
import { IEduJwtData, UserRegistryEntry } from '../interface';
import { UserRole } from '../const';
import {getFullnameWithLeadingName} from "@lecta/misc/helpers/helpers";

interface UserRegistryCollection {
  [userId: string]: UserRegistryEntry;
}

const STORE_INITIAL_STATE = {
  role: null as UserRole | null,
  entries: {} as UserRegistryCollection,
};

const TOKEN_COLLECTION_KEY = 'edu-token-collection';

@Injectable({ providedIn: 'root' })
export class UserRegistryService {
  current$: Observable<UserRegistryEntry | null>;
  private store = new Store<typeof STORE_INITIAL_STATE>(STORE_INITIAL_STATE);
  private role: UserRole | null = null;

  menuIsOpen: Subject<boolean> = new Subject<boolean>;

  constructor(private localStorageService: LectaLocalStorageService) {
    this.init();
  }

  getCurrentUser(): UserRegistryEntry | null {
    if (this.role === null) {
      return Object.values(this.getEntries())[0];
    } else {
      return this.findUserByRole(this.getEntries(), this.role);
    }
  }

  getUserName(): string{
    const user = Object.values(this.getEntries())[0];
    this.setCurrentRole(user.jwtData.roles[0] as UserRole);
    const isTeacher = user.jwtData.roles[0] === 'ROLE_EDU_SKYSMART_TEACHER_USAGE';
    return getFullnameWithLeadingName({name: user.jwtData.name, surname: user.jwtData.surname, patronymic: user.jwtData.patronymic}, isTeacher);
  }

  getCurrentRole(): UserRole | null {
    return this.role;
  }

  setCurrentRole(role: UserRole | null): void {
    this.role = role;
    this.store.updateField('role', role);
  }

  hasUserWithId(userId: number): boolean {
    return !!this.getEntries()[userId];
  }

  addUser(userId: number, jwtData: IEduJwtData, token: string): void {
    // restore latest data from storage
    let entries = this.getEntries();

    const role = UserRegistryService.getRoleByJwtData(jwtData);
    if (role) {
      // clear all entries with the same role except current one
      const userIdsToRemove = Object.keys(entries).filter(
        entryUserId =>
          UserRegistryService.isUserWithRole(entries[entryUserId], role) && entryUserId !== userId.toString(),
      );
      // eslint-disable-next-line functional/immutable-data
      userIdsToRemove.forEach(userId => delete entries[userId]);

      this.setCurrentRole(role);
    }

    entries = { ...entries, [userId]: { jwtData, token, userId, updatedAt: Date.now() } };
    // order is critical: update store should go after setting entries
    this.setEntries(entries);
  }

  hasUserInRegistry(filterFunction: (entry: UserRegistryEntry) => boolean): boolean {
    const entries = this.getEntries();
    return !!Object.values(entries).find(filterFunction);
  }

  hasRegisteredUserInRegistry(role: UserRole): boolean {
    return this.hasUserInRegistry(entry => {
      return (entry.jwtData.roles[0]! as UserRole) === role;
    });
  }

  hasAcceptedRole(): boolean {
    const entries = this.getEntries();
    const currentUserEntry = Object.values(entries)[0];

    if (!currentUserEntry) {
      return false;
    }

    return Object.values(UserRole).includes(currentUserEntry.jwtData.roles[0]! as UserRole);
  }

  cleanUp(): void {
    this.setEntries({});
    this.setCurrentRole(null);
  }

  private init(): void {
    this.recoverFromStorage();

    const entries$ = this.store.select('entries');
    const role$ = this.store.select('role');

    this.current$ = combineLatest([entries$, role$]).pipe(map(([entries, role]) => this.findUserByRole(entries, role)));
  }

  private getEntries(): UserRegistryCollection {
    return this.getFromStorage();
  }

  private setEntries(entries: UserRegistryCollection): void {
    this.saveToStorage(entries);

    this.store.updateField('entries', entries);
  }

  private findUserByRole(entries: UserRegistryCollection, role: UserRole | null): UserRegistryEntry | null {
    const userId = this.findUserIdByRole(entries, role);
    return userId ? entries[userId] : null;
  }

  private findUserIdByRole(entries: UserRegistryCollection, role: UserRole | null): string | null {
    let usersWithRole = this.filterUsersByRole(entries, role);

    // select last updated (despite it's not possible to store multiple users of the same role at the moment)
    // eslint-disable-next-line functional/immutable-data
    usersWithRole = usersWithRole.sort((user1, user2) => user2.updatedAt - user1.updatedAt);
    return (usersWithRole[0] && usersWithRole[0].userId.toString()) || null;
  }

  private filterUsersByRole(entries: UserRegistryCollection, role: UserRole | null): UserRegistryEntry[] {
    return Object.keys(entries)
      .map(userId => entries[userId])
      .filter(user => UserRegistryService.isUserWithRole(user, role));
  }

  private static isUserWithRole(user: UserRegistryEntry, role: UserRole | null): boolean {
    if (!role) {
      return false;
    }

    return user.jwtData.roles.includes(role);
  }

  private static getRoleByJwtData(jwtData: IEduJwtData): UserRole | null {
    return (jwtData.roles[0] as UserRole) || null;
  }

  private recoverFromStorage(): void {
    this.store.updateField('entries', this.getFromStorage());
  }

  private getFromStorage(): UserRegistryCollection {
    return this.localStorageService.get<UserRegistryCollection>(TOKEN_COLLECTION_KEY) || {};
  }

  private saveToStorage(value: UserRegistryCollection): boolean {
    return this.localStorageService.set<UserRegistryCollection>(TOKEN_COLLECTION_KEY, value);
  }
}
