import { Injectable } from '@angular/core';
import type heic2any from 'heic2any';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConverterResults, FileConverter, LectaFileConverterOptions } from 'core/file/lecta-file.interfaces';
import { isFileExtensionIncluded } from 'core/file/lecta-file.helpers';

const CONVERTIBLE_NOTYPE_FILE_EXTENSIONS = ['heic', 'heif'];
const CONVERTIBLE_APPLE_FORMATS = ['image/heic', 'image/heif'];
const CONVERTED_TYPE = 'image/jpeg';
const JPEG_EXTENSION = 'jpeg';

const DEFAULT_CONVERTER_OPTIONS: LectaFileConverterOptions = {
  toType: [CONVERTED_TYPE, JPEG_EXTENSION],
};

@Injectable()
export class LectaHeicImageConverterService implements FileConverter {
  convert(file: File, { toType }: LectaFileConverterOptions = DEFAULT_CONVERTER_OPTIONS): Observable<ConverterResults> {
    const [type, extension] = toType;
    return LectaHeicImageConverterService.loadLibrary().pipe(
      switchMap(converter => {
        const fileName = `${file.name.replace(/\.[^/.]+$/, '')}.${extension}`;
        const startTime = performance.now();
        return from(converter({ blob: file as Blob, toType: type })).pipe(
          map(blob => (Array.isArray(blob) ? blob : [blob])),
          map(blob => new File(blob, fileName, { type, lastModified: file.lastModified })),
          map<File, ConverterResults>(file => ({ file, stats: { duration: performance.now() - startTime } })),
        );
      }),
    );
  }

  isConvertible(file: File): boolean {
    const isSupportedByFileExtension = isFileExtensionIncluded(file, CONVERTIBLE_NOTYPE_FILE_EXTENSIONS);
    return CONVERTIBLE_APPLE_FORMATS.includes(file.type) || (!file.type && isSupportedByFileExtension);
  }

  private static loadLibrary(): Observable<typeof heic2any> {
    return from(import('heic2any')).pipe(map(module => module.default));
  }
}
