import { Injectable } from '@angular/core';
import { NoPreloading, PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { isPrerenderMode } from 'core/misc';

@Injectable({
  providedIn: 'root',
})
export class EduPreloadingStrategyService implements PreloadingStrategy {
  private delegate: PreloadingStrategy;

  constructor(quickLinkPreloadingStrategy: QuicklinkStrategy, noopStrategy: NoPreloading) {
    this.delegate = isPrerenderMode() ? noopStrategy : quickLinkPreloadingStrategy;
  }

  preload(route: Route, fn: () => Observable<unknown>): Observable<unknown> {
    if (route.data?.preload === true) {
      return this.delegate.preload(route, fn);
    } else {
      return EMPTY;
    }
  }
}
