import { Injectable, Inject, Optional } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType,
  HttpEvent,
  HttpContext,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LectaApiCacheService } from './cache';
import {
  ILectaApiConfig,
  TLectaApiRequestEndpoints,
  ILectaApiRequestOptions,
  TLectaApiRequestMethod,
  TLectaApiResponseType,
} from '../lecta-api.interface';
import { LECTA_API_CACHE_CONTEXT_TOKEN, LECTA_API_CONFIG } from '../lecta-api.const';
import { map, catchError, tap, filter, retry, timeoutWith } from 'rxjs/operators';
import FormData from 'form-data';

export const REQUEST_AUTOREJECT_DEFAULT_TIMEOUT_MS = 6 * 1000;
export const REQUEST_AUTOREJECT_MAX_TIMEOUT_MS = 60 * 1000;
const CHANGE_ENDPOINT_ERROR_STATUSES = [302, 404, 408, 503];
export const REQUEST_AUTOREJECT_TIMEOUT_ERROR_TEXT = 'Request autorejected: ';

@Injectable({ providedIn: 'root' })
export class LectaApiService {
  private config: ILectaApiConfig;
  private usingEndpointsIndex = new Map<string[], number>();

  constructor(
    private http: HttpClient,
    private apiCacheService: LectaApiCacheService,
    @Optional() @Inject(LECTA_API_CONFIG) skyApiConfig?: ILectaApiConfig,
  ) {
    this.config = skyApiConfig ? skyApiConfig : {};
  }

  delete<T>(
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions?: ILectaApiRequestOptions,
  ): Observable<T> {
    return this.request<T>('DELETE', endpoints, path, baseRequestOptions).pipe(map(response => response.body!));
  }

  get<T>(
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions?: ILectaApiRequestOptions,
  ): Observable<T> {
    return this.request<T>('GET', endpoints, path, baseRequestOptions).pipe(map(response => response.body!));
  }

  getWholeResponse<T>(
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions?: ILectaApiRequestOptions,
  ): Observable<HttpResponse<T>> {
    return this.request<T>('GET', endpoints, path, baseRequestOptions);
  }

  patch<T>(
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions?: ILectaApiRequestOptions,
  ): Observable<T> {
    return this.request<T>('PATCH', endpoints, path, baseRequestOptions).pipe(map(response => response.body!));
  }

  post<T>(
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions?: ILectaApiRequestOptions,
  ): Observable<T> {
    return this.request<T>('POST', endpoints, path, baseRequestOptions).pipe(map(response => response.body!));
  }

  put<T>(
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions?: ILectaApiRequestOptions,
  ): Observable<T> {
    return this.request<T>('PUT', endpoints, path, baseRequestOptions).pipe(map(response => response.body!));
  }

  request<T>(
    method: TLectaApiRequestMethod,
    endpoints: TLectaApiRequestEndpoints,
    path: string,
    baseRequestOptions: ILectaApiRequestOptions = {},
  ): Observable<HttpResponse<T>> {
    if (!Array.isArray(endpoints)) {
      throw new Error('Wrong api endpoints format: ' + JSON.stringify(endpoints));
    }

    const startIndex = this.usingEndpointsIndex.get(endpoints) || 0;
    const activeEndpoints = endpoints.slice(startIndex);
    const requestAutorejectTimeoutMs =
      endpoints.length > 1
        ? this.config.requestAutorejectTimeoutMs || REQUEST_AUTOREJECT_DEFAULT_TIMEOUT_MS
        : REQUEST_AUTOREJECT_MAX_TIMEOUT_MS;

    return activeEndpoints.reduce(
      (request, endpoint, index) =>
        request.pipe(
          catchError((error: HttpErrorResponse | null) => {
            if ((index !== 0 && this.config.disableEndpointsRotation) || LectaApiService.isValidErrorResponse(error)) {
              return throwError(error);
            }

            const url = LectaApiService.formatUrl(endpoint, path);

            const request$ = this.doRequest<T>(method, url, baseRequestOptions).pipe(
              tap(() => {
                const successIndex = endpoints.indexOf(endpoint);
                this.usingEndpointsIndex.set(endpoints, successIndex);
              }),
            );

            if (baseRequestOptions.longRequest) {
              return request$;
            }

            return request$.pipe(
              timeoutWith(requestAutorejectTimeoutMs, throwError(REQUEST_AUTOREJECT_TIMEOUT_ERROR_TEXT + url)),
            );
          }),
        ),
      throwError(null) as Observable<HttpResponse<T>>,
    );
  }

  invalidateResponseCache(endpoints: TLectaApiRequestEndpoints, path: string, params?: Object): void {
    endpoints.forEach(endpoint => {
      const url = LectaApiService.formatUrl(endpoint, path);

      const request = this.getRequestOptions('GET', url, { params });
      const cacheKey = request.urlWithParams;

      this.apiCacheService.remove(cacheKey);
    });
  }

  invalidateAllResponseCache(): void {
    this.apiCacheService.removeAll();
  }

  getHttpParams(params: Object, httpParams = new HttpParams()): HttpParams {
    for (const key in params) {
      // @ts-ignore
      const value = params[key];
      // skip empty params
      if (value === undefined || value === null) {
        continue;
      }
      // @ts-ignore
      httpParams = httpParams.set(key, params[key]);
    }
    return httpParams;
  }

  private doRequest<T>(
    method: TLectaApiRequestMethod,
    url: string,
    baseRequestOptions: ILectaApiRequestOptions = {},
  ): Observable<HttpResponse<T>> {
    const requestOptions = this.getRequestOptions(method, url, baseRequestOptions);

    return this.http.request<T>(requestOptions).pipe(
      tap(
        event => LectaApiService.handleSuccessUploadEvents<T>(event, baseRequestOptions),
        (error: HttpErrorResponse) => LectaApiService.handleErrorUploadEvents(error, baseRequestOptions),
      ),
      filter((event): event is HttpResponse<T> => event instanceof HttpResponse),
      retry(baseRequestOptions.retry || 0),
    );
  }

  // NOTE: manual form params to string processing due to this issue https://github.com/angular/angular/issues/18719
  // currently HttpParams cant process array of items as `smth[]=`
  private getFormParamsString(params: Object): string {
    const paramsList: string[] = [];
    for (const valueKey in params) {
      // @ts-ignore
      const value = params[valueKey];
      // skip empty params
      if (value === undefined || value === null) {
        continue;
      }

      const encodedValueKey = LectaApiService.encodeValue(valueKey);

      // `{ smth: [ 1, 2 ] }` -> `smth[]=1&smth[]=2`
      if (Array.isArray(value)) {
        value.forEach(valueItem => {
          const encodedValueItem = LectaApiService.encodeValue(valueItem);
          // eslint-disable-next-line functional/immutable-data
          paramsList.push(`${encodedValueKey}[]=${encodedValueItem}`);
        });
      }
      // `{ smth: { a: 1, b: 2 } }` -> `smth[a]=1&smth[b]=2`
      else if (typeof value === 'object') {
        for (const valueItemKey in value) {
          const encodedValueItemKey = LectaApiService.encodeValue(`${valueKey}[${valueItemKey}]`);
          const encodedValueItem = LectaApiService.encodeValue(value[valueItemKey]);

          // eslint-disable-next-line functional/immutable-data
          paramsList.push(`${encodedValueItemKey}=${encodedValueItem}`);
        }
      } else {
        const encodedValue = LectaApiService.encodeValue(value);
        // eslint-disable-next-line functional/immutable-data
        paramsList.push(`${encodedValueKey}=${encodedValue}`);
      }
    }
    return paramsList.join('&');
  }

  private getRequestOptions(
    method: string,
    url: string,
    baseRequestOptions: ILectaApiRequestOptions = {},
  ): HttpRequest<unknown> {
    let body: FormData | string | Object | null = null;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let reportProgress = false;

    if (baseRequestOptions.formParams) {
      if (baseRequestOptions.formParams instanceof FormData) {
        // NOTE: proper header will be set by browser
        body = baseRequestOptions.formParams;
      } else {
        if (baseRequestOptions.json) {
          headers = headers.set('Content-Type', 'application/json; charset=UTF-8');
          body = JSON.stringify(baseRequestOptions.formParams);
        } else {
          headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          body = this.getFormParamsString(baseRequestOptions.formParams);
        }
      }
    }

    if (baseRequestOptions.params) {
      if (method === 'GET') {
        params = this.getHttpParams(baseRequestOptions.params);
      } else {
        body = baseRequestOptions.params;
      }
    }

    if (baseRequestOptions.queryParams) {
      params = this.getHttpParams(baseRequestOptions.queryParams, params);
    }

    if (baseRequestOptions.token) {
      headers = headers.set('Authorization', `Bearer ${baseRequestOptions.token}`);
    }

    if (baseRequestOptions.headers) {
      Object.keys(baseRequestOptions.headers).forEach(
        header => (headers = headers.set(header, baseRequestOptions.headers![header])),
      );
    }

    if (baseRequestOptions.uploadHandlers) {
      reportProgress = true;
    }

    const withCredentials = baseRequestOptions.withCredentials;
    const responseType: TLectaApiResponseType = baseRequestOptions.responseType || 'json';

    const requestOptions = new HttpRequest(method, url, body, {
      headers,
      params,
      reportProgress,
      responseType,
      withCredentials,
      context: new HttpContext(),
    });

    if (baseRequestOptions.cache) {
      requestOptions.context.set(LECTA_API_CACHE_CONTEXT_TOKEN, true);
    }

    return requestOptions;
  }

  private static encodeValue(param: string): string {
    return encodeURIComponent(param);
  }

  private static formatUrl(endpoint: string, path: string): string {
    return endpoint + path;
  }

  private static isValidErrorResponse(error: HttpErrorResponse | null): boolean {
    return !!error && !!error.status && !CHANGE_ENDPOINT_ERROR_STATUSES.includes(error.status);
  }

  private static handleErrorUploadEvents(error: HttpErrorResponse, baseRequestOptions: ILectaApiRequestOptions): void {
    if (!baseRequestOptions.uploadHandlers) {
      return;
    }

    if (baseRequestOptions.uploadHandlers.error) {
      baseRequestOptions.uploadHandlers.error(error);
    }
  }

  private static handleSuccessUploadEvents<T>(event: HttpEvent<T>, baseRequestOptions: ILectaApiRequestOptions): void {
    if (!baseRequestOptions.uploadHandlers) {
      return;
    }

    if (event.type === HttpEventType.Sent && baseRequestOptions.uploadHandlers.loadstart) {
      baseRequestOptions.uploadHandlers.loadstart(event);
    } else if (event.type === HttpEventType.UploadProgress && baseRequestOptions.uploadHandlers.progress) {
      baseRequestOptions.uploadHandlers.progress(event);
    } else if (event instanceof HttpResponse && baseRequestOptions.uploadHandlers.loadend) {
      baseRequestOptions.uploadHandlers.loadend(event);
    }
  }
}
