import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FILE_CONVERTER } from 'core/file/lecta-file.consts';
import { ConverterResults, FileConverter, LectaFileConverterOptions } from 'core/file/lecta-file.interfaces';

@Injectable({
  providedIn: 'root',
})
export class LectaFileConverterService implements FileConverter {
  constructor(@Inject(FILE_CONVERTER) private converters: FileConverter[]) {}

  convert(file: File, options?: LectaFileConverterOptions): Observable<ConverterResults> {
    const converter = this.converters?.find(converter => converter.isConvertible(file));
    return converter ? converter.convert(file, options) : of({ file });
  }

  isConvertible(file: File): boolean {
    return !!this.converters?.find(converter => converter.isConvertible(file));
  }
}
