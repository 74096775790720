<div #root class="root -type-{{ notification.type }}" [class.-mobile]="isMobile">
  <div *ngIf="!isMobile" class="icon">
    <lecta-icon [svgContent]="notification.icon"></lecta-icon>
  </div>
  <section #body class="body -{{ bodyDirection }}">
    <div class="content">
      <ng-container *ngIf="notification.content; else mainContentSlot">
        {{ notification.content }}
      </ng-container>
      <ng-template #mainContentSlot>
        <ng-content></ng-content>
      </ng-template>
    </div>
    <div class="buttons">
      <ng-content select="[lectaNotificationAction]"></ng-content>
    </div>
  </section>
  <div class="close">
    <button class="close-button" (click)="onClose()">
      <lecta-icon [svgContent]="closeIcon"></lecta-icon>
    </button>
  </div>
</div>
