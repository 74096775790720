import { PrerenderState } from '../../interfaces';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { createInternalServerErrorStatusState, createNotFoundStatusState } from '../create-state';

export const catchAndConvertHttpError = <T>(
  prerenderStateHandler: (state: PrerenderState) => void,
): MonoTypeOperatorFunction<T> =>
  catchError(error => {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 404) {
        prerenderStateHandler(createNotFoundStatusState());
      } else {
        prerenderStateHandler(createInternalServerErrorStatusState());
      }
    }
    return throwError(error);
  });
