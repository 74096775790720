import {ChangeDetectionStrategy, Component, Inject, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {SessionStorageService} from "@lecta/core/service/session-storage";


@Component({
  selector: 'auth-test',
  templateUrl: 'auth-test.component.html',
  styleUrls: ['auth-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthTestComponent {
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private sessionStorageService: SessionStorageService

  ) { }

  public ngOnInit() {
    this.sessionStorageService.set('redirectUrl', window.location.pathname);
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.text = `



  let uuid = null;
  const signIn = '/signIn';
  const signOut = '/logout';
  const action = function(url = null, action = null) {
    const block = document.getElementById('action');
    block.innerHTML = 'Действия не требуются';
    if (url) { block.innerHTML = 'Требуется: <a href="' + url + '">' + action + '</a>'; }
  };

    const actionBack = function(url = null, action = null) {

    const block = document.getElementById('action-back');
    block.innerHTML = 'Действия не требуются';
    console.log(url);
    if(url  === '/signIn'){

   block.innerHTML = 'Требуется: <a href="/auth-test-login">' + action + '</a>';
        return;
    }

    if (url) { block.innerHTML = 'Требуется: <a href="' + url + '">' + action + '</a>'; }
  };

  const check = function(state) {

    action();


    switch (state.authorized()) {
      case true:
        if (uuid === null) {
          action(signIn, 'войти');

// window.location.href = signIn;
          break;
        }
        if (state.actual().uuid() !== uuid) {
          action(signIn, 'пере-зайти');
// window.location.href = signIn;
        }

           actionBack(signOut, 'выйти');
        break;
      case false:
        if (uuid !== null) {
          action(signOut, 'выйти');
// window.location.href = signOut;
        }

          if (uuid === null) {
                   actionBack(signIn, 'войти');
          }
        break;


      default:
        break;
    }
  };
  const host = 'id.prosv.ru';
  const sdk = document.createElement('script');
  const getRecord = function(state) {
    let result = new Date() + ', ';
    switch (state.authorized()) {
      case true: result += '<span style="color: darkgreen;">authorized</span>' + ', uuid = ' + state.actual().uuid(); break;
      case false: result += '<span style="color: darkred;">not authorized</span>'; break;
      default: result += '<span style="color: darkgray;">unknown</span>'; break;
    }
    return result;
  }
  sdk.onload = function() {
    const api = new IdPState({
      redirectMethodOnFail: true, // default: false
    });

    const block = document.getElementById('state-result');
    api.periodic(function(state) {
      uuid = state?.actual()?.uuid() ?? null;
      check(state);
      const element = document.createElement('div');

      element.innerHTML = getRecord(state);

      block.prepend(element);
    }, 1000);
  };
  sdk.src = '//' + host + '/api/state.js';
  document.head.appendChild(sdk);
        `;

    this._renderer2.appendChild(this._document.body, script);
  }





}
