import { Observable } from 'rxjs';

export const UNLIMITED_SCHEDULER_LIMIT = -1;

export interface LectaScheduler {
  schedule<V, S = ScheduledInferredData<V>>(
    scheduleFactory: Scheduled<S>,
    settings?: SchedulingSettings,
  ): Observable<S>;

  terminate(queueKey?: string): void;

  skip(queueKey?: string): Observable<never>;

  destroy(queueKey?: string): void;
}

export interface LectaSchedulerConfig {
  limit: number;
}

export abstract class LectaRootSchedulerConfig implements LectaSchedulerConfig {
  limit = UNLIMITED_SCHEDULER_LIMIT;
}

export interface SchedulingOrder {
  order?: number;
}

export interface SchedulingSettings extends SchedulingOrder {
  teardown?: () => void;
  terminateOnComplete?: boolean;
  ignoreLimit?: boolean;
  queueName?: string;
}

export const sourceDataSymbol = Symbol('sourceData');

export interface ScheduledData<T> {
  [sourceDataSymbol]: T;
  schedulingSettings?: SchedulingSettings;
}

export type QueueResult<V> = Observable<V | ScheduledData<V>>;

export type ScheduledInferredData<T> = T extends ScheduledData<infer R> ? R : T;

export interface SchedulingInput<V> {
  source: () => QueueResult<V>;
  schedulingSettings?: SchedulingSettings;
}

export type Scheduled<V> = () => SchedulingInput<V> | QueueResult<V>;
