<div class="root" [class.-mobile]="isMobile">
  <lecta-notification-container
    *ngFor="let notification of notifications$ | async"
    class="notification"
    @notificationItem
    [notification]="notification"
    (closeClick)="onClose(notification)"
    (@notificationItem.done)="onAnimationDone()"
  ></lecta-notification-container>
</div>
