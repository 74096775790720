import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { CurrentUserService } from '../../service/current-user';
import { UserAuthStateService } from '../../service/auth-state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class NotFullyRegisteredGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected currentUserService: CurrentUserService,
    private userAuthStateService: UserAuthStateService,
  ) {}

  protected getPageUrlTree(_route?: ActivatedRouteSnapshot): UrlTree {
    return this.router.createUrlTree(['/']);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<UrlTree | boolean> {
    const user = this.currentUserService.getUser();
    if (!user) {
      return true;
    }

    return this.currentUserService.getConfig().pipe(
      map(userConfig => {
        if (!this.userAuthStateService.isFullyRegistered(userConfig)) {
          return true;
        } else {
          if(route?.firstChild?.url[0].path === 'subjects'){return true;}
          return this.getPageUrlTree(route);
        }
      }),
    );
  }
}
