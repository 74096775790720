import { HttpStatusCode } from '@angular/common/http';
import {
  BasePrerenderStateProperties,
  HttpForbiddenStatusState,
  HttpInternalServerErrorStatusState,
  HttpNotFoundStatusState,
  HttpRedirectStatusState,
  HttpServiceUnavailableStatusState,
  RedirectStatusState,
} from 'meta/interfaces';

export const createRedirectStatusState = (data?: RedirectStatusState): HttpRedirectStatusState => {
  return { httpStatus: HttpStatusCode.MovedPermanently, data };
};

export const createForbiddenStatusState = (data?: BasePrerenderStateProperties): HttpForbiddenStatusState => {
  return { httpStatus: HttpStatusCode.Forbidden, data };
};

export const createNotFoundStatusState = (data?: BasePrerenderStateProperties): HttpNotFoundStatusState => {
  return { httpStatus: HttpStatusCode.NotFound, data };
};

export const createServiceUnavailableStatusState = (
  data?: BasePrerenderStateProperties,
): HttpServiceUnavailableStatusState => {
  return { httpStatus: HttpStatusCode.ServiceUnavailable, data };
};

export const createInternalServerErrorStatusState = (
  data?: BasePrerenderStateProperties,
): HttpInternalServerErrorStatusState => {
  return { httpStatus: HttpStatusCode.InternalServerError, data };
};
