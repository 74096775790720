export interface DnevnikruParams {
  dnevnikRuToken: string;
  dnevnikRuState?: string;
}

export interface DnevnikruCreateHomeworkParams {
  dnevnikRuLesson: string;
  dnevnikRuGroup: string;
  dnevnikRuSubject: string;
  dnevnikRuType: string;
  createdFromIframe?: boolean;
  providerType?: string;
  returnUrl?: string;
}

export interface DnevnikruStartHomeworkParams {
  taskHash: string;
}

export interface DnevnikruCreateHomeworkSuccessPopupValue {
  redirectToLicenseForm: boolean;
}

export const DnevnikruCreateHomeworkErrorCode = 'homework_link_not_send';

export enum DnevnikruCreateHomeworkStateStatus {
  success = 'success',
  error = 'error',
}
