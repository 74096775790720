import { Injectable } from '@angular/core';
import { startGuard } from 'core/misc';
import { filterEmpty, IDestroyedStreamOptions, takeUntilDestroyed } from 'core/rxjs';
import { MetaService } from 'meta/services/meta.service';
import { EduRouteData } from 'routing/interface';
import { filter, map, mapTo, shareReplay, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { SkysmartRoutingHelperService } from './routing-helper';
import { RoutingHelpersService } from '@lecta/core/routing';

@Injectable({ providedIn: 'root' })
export class SeoRoutingService {
  private destroyOptions: IDestroyedStreamOptions = { destroyMethod: this.stop };

  constructor(
    private coreRoutingHelpersService: RoutingHelpersService,
    private skysmartRoutingHelper: SkysmartRoutingHelperService,
    private metaService: MetaService,
  ) {
    startGuard(this);
  }

  start(): void {
    const seoRouteData$ = this.coreRoutingHelpersService.routeChanged$.pipe(
      map(() => this.skysmartRoutingHelper.getCurrentRouteData<EduRouteData>()?.seo),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    const allowIndex$ = seoRouteData$.pipe(map(data => data?.allowIndex ?? false));
    const schemaOrgPageType$ = seoRouteData$.pipe(map(data => data?.schemaOrgPageType));
    const navigationStart$ = this.coreRoutingHelpersService.navigationStart$.pipe(mapTo(false));

    merge(allowIndex$, navigationStart$)
      .pipe(
        tap(allowIndex => this.metaService.setIndexable(allowIndex)),
        takeUntilDestroyed(this, this.destroyOptions),
      )
      .subscribe();

    merge(schemaOrgPageType$, navigationStart$.pipe(mapTo(undefined)))
      .pipe(
        tap(schemaOrgPageType => this.metaService.setBodySchema(schemaOrgPageType)),
        takeUntilDestroyed(this, this.destroyOptions),
      )
      .subscribe();

    const resetToDefaultMeta$ = seoRouteData$.pipe(
      map(seo => seo?.skipDefaultMeta),
      filter(skip => !skip),
    );

    const overrideDefaultMeta$ = seoRouteData$.pipe(
      map(seo => seo?.overrideDefaultMeta),
      filterEmpty(),
    );

    resetToDefaultMeta$
      .pipe(takeUntilDestroyed(this, this.destroyOptions))
      .subscribe(() => this.metaService.resetToDefaults());

    overrideDefaultMeta$
      .pipe(takeUntilDestroyed(this, this.destroyOptions))
      .subscribe(tags => this.metaService.update(tags?.openGraph));
  }

  stop(): void {}
}
