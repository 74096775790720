import { LectaIconService } from './services/lecta-icon.service';
import { IconSize, PackName } from './lecta-icon.interface';

export function addIconsToService(
  svgContext: __WebpackModuleApi.RequireContext,
  iconService: LectaIconService,
  rootDir = 'images',
  pack: PackName = 'default',
  size: IconSize = 'm',
): void {
  svgContext.keys().forEach((key: string) => {
    const path = key.split('/');
    const rootDirIndex = path.findIndex(item => item === rootDir);
    const name = [...path]
      .splice(rootDirIndex + 1, path.length - 1)
      .join('/')
      .slice(0, -4);
    const src = import(`${key}?raw`);

    src.then((content: { default: string }) => {
      iconService.add({ pack, name, content, size });
    });
  });
}
