import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Notification } from '../../lecta-notification.interface';
import { NotificationInstance } from '../../lecta-notification.class';
import { LectaNotificationItemComponent } from '../notification-item/lecta-notification-item.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-notification-container',
  templateUrl: './lecta-notification-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaNotificationContainerComponent implements OnInit {
  @Input() notification: Notification;
  @Output() closeClick = new EventEmitter<void>();

  notificationComponentInjector: Injector;
  notificationItemComponent = LectaNotificationItemComponent;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    const instance = new NotificationInstance(this.notification, () => this.closeClick.next());
    this.notificationComponentInjector = Injector.create({
      providers: [
        {
          provide: NotificationInstance,
          useValue: instance,
        },
      ],
      parent: this.injector,
    });
  }
}
