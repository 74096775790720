import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LectaSafeHtmlPipe } from 'lecta-ui/misc/pipes/lecta-safe-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LectaSafeHtmlPipe],
  exports: [LectaSafeHtmlPipe],
})
export class LectaMiscModule {}
