import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { updateComponentCssVars } from 'styles/theme/theme.helpers';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { DIALOG_CSS_VARS_PREFIX, DIALOG_CUSTOMISABLE_PARAMS } from '../../lecta-modal-dialog.const';
import { LectaDialogInstance } from '../../lecta-dialog-instance.class';
import { BreakpointService } from 'lecta-ui/layout';
import closeIconSrc from './images/close.svg?raw';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-modal-dialog',
  templateUrl: './lecta-modal-dialog.component.html',
  styleUrls: ['./lecta-modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DecorateUntilDestroy()
export class LectaModalDialogComponent implements OnInit {
  @Input() hasHeader = true;

  @ViewChild('root', { static: true }) rootRef: ElementRef<HTMLElement>;

  isMobile: boolean;
  closeIcon = closeIconSrc;

  constructor(
    private dialogInstance: LectaDialogInstance<unknown, unknown>,
    private renderer2: Renderer2,
    private breakpointService: BreakpointService,
  ) {this.isMobile = this.breakpointService.isSmall();}

  ngOnInit(): void {
    updateComponentCssVars({
      renderer: this.renderer2,
      rootElement: this.rootRef,
      componentPrefix: DIALOG_CSS_VARS_PREFIX,
      params: DIALOG_CUSTOMISABLE_PARAMS,
      type: 'modal',
    });

    this.dialogInstance
      .onShouldClose()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this.dialogInstance.detach();
      });
  }

  close(): void {
    this.dialogInstance.close({ rejected: true });
  }
}
