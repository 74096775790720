import { NgModule } from '@angular/core';
import { PopoverDirective } from './directive/popover';
import { PopoverComponent } from './component/popover/popover';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { DragPositionDirective } from './directive/drag-position';
import { LectaIconModule } from 'lecta-ui/icon/lecta-icon.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [OverlayModule, CommonModule, LectaIconModule, DragDropModule],
  declarations: [PopoverDirective, PopoverComponent, DragPositionDirective],
  exports: [PopoverDirective],
})
export class PopoverModule {}
