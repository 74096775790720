import { Params } from '@angular/router';
import { ExternalAuthProvider } from 'integrations/prosv/interface';

export function extractExternalProviderQueryParam(queryParams: Params): ExternalAuthProvider | null {
  const provider = queryParams.provider ? queryParams.provider.toLowerCase() : null;

  if (provider && ['mo', 'eis', 'dnevnik', 'vk',  'vs'].includes(provider)) {
    return provider;
  }

  return null;
}
