import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ProsvIdAuthService } from 'integrations/prosv/service/prosv-id-auth.service';

@Component({
  selector: 'app-login',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  constructor(private prosvIdAuthService: ProsvIdAuthService) {}

  ngOnInit(): void {
    this.prosvIdAuthService.openProsvIdAuth();
  }
}
