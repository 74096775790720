import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAppBuildNumber } from 'app/helpers';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class BuildVersionInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const buildNumber = getAppBuildNumber();

    if (!request.url.includes(environment.endpoints.api)) {
      return next.handle(request);
    }

    const modifiedRequest = request.clone({
      headers: request.headers.set('X-App-Build-Number', `${buildNumber}`),
    });

    return next.handle(modifiedRequest);
  }
}
