import { Injectable } from '@angular/core';
import { NEVER, Observable, Subscription } from 'rxjs';
import { filter, delay, tap } from 'rxjs/operators';
import { CurrentUserService } from 'user/core/service/current-user';
import { Router } from '@angular/router';
import { DnevnikruApi } from '../api/dnevnikru-api';
import { DnevnikruParams } from '../interface';
import { DnevnikruService } from './dnevnikru';
import { canBindUser } from '../helpers';

@Injectable({ providedIn: 'root' })
export class DnevnikruBindService {
  redirectToBinding$?: Subscription;

  constructor(
    private dnevnikruApi: DnevnikruApi,
    private dnevnikruService: DnevnikruService,
    private currentUserService: CurrentUserService,
    private router: Router,
  ) {}

  subscribeRedirectToBinding(): void {
    if (this.redirectToBinding$) {
      return;
    }

    if (!this.dnevnikruService.getParams()) {
      return;
    }

    this.redirectToBinding$ = this.currentUserService.authorizedUser$
      .pipe(
        filter(user => canBindUser(user)),
        filter(() => !!this.dnevnikruService.getParams()),
        // workaround to navigate to bind page after default redirect after login
        delay(300),
      )
      .subscribe(_user => {
        this.router.navigateByUrl(this.getBindUrl());
      });
  }

  activateBindMode(params: DnevnikruParams): void {
    this.dnevnikruService.setParams(params);
    this.subscribeRedirectToBinding();
  }

  deactivateBindMode(): void {
    this.dnevnikruService.removeParams();
  }

  bind(): Observable<void> {
    const params = this.dnevnikruService.getParams();
    if (!params) {
      return NEVER;
    }

    return this.dnevnikruApi.bind(params).pipe(tap(() => this.deactivateBindMode()));
  }

  private getBindUrl(): string {
    if (this.currentUserService.isTeacher()) {
      return '/dnevnikru/teacher/bind';
    }

    return '/dnevnikru/student/bind';
  }
}
