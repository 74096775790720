<button
  class="root -size-{{ getSize() }}"
  [class.-disabled]="disabled"
  [class.-round]="round"
  [class.-mobile]="isMobile"
  [class.-has-spinner]="spinner"
  [class.-has-left-icon]="leftIcon.childNodes.length"
  [class.-has-right-icon]="rightIcon.childNodes.length"
  [class.-icon-only]="iconOnly"
  (click)="checkPropagation($event)"
  [attr.type]="nativeType"
  #root
>
  <lecta-spinner *ngIf="spinner" class="spinner" [size]="getSpinnerSize()"></lecta-spinner>

  <div class="icon-alone">
    <ng-content select="[icon]"></ng-content>
  </div>

  <div class="icon-left" #leftIcon>
    <ng-content select="[iconLeft]"></ng-content>
  </div>

  <ng-content></ng-content>

  <div class="icon-right" #rightIcon>
    <ng-content select="[iconRight]"></ng-content>
  </div>
</button>
