export const BUTTON_CSS_VARS_PREFIX = 'button';
export const BUTTON_CUSTOMISABLE_PARAMS = [
  'color',
  'iconColor',
  'backgroundColor',
  'colorHover',
  'iconColorHover',
  'backgroundColorHover',
  'colorPressed',
  'iconColorPressed',
  'backgroundColorPressed',
  'colorDisabled',
  'iconColorDisabled',
  'backgroundColorDisabled',
];
