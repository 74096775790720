import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-dialog-overlay',
  templateUrl: './lecta-dialog-overlay.component.html',
  styleUrls: ['./lecta-dialog-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaDialogOverlayComponent {
  @Input() isMobile: boolean;
  @Input() limitDialogHeight = true;
  @Output() backdropClick = new EventEmitter<void>();

  @ViewChild('root', { static: true }) root: ElementRef<HTMLElement>;
  @ViewChild('container', { static: true }) container: ElementRef<HTMLElement>;

  onClick(event: MouseEvent): void {
    if (event.target === this.root.nativeElement || event.target === this.container.nativeElement) {
      this.backdropClick.emit();
    }
  }
}
