import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { createServiceUnavailableStatusState } from '../../../meta/utils/create-state';
import { ORIGINAL_URL_QUERY_PARAM } from '@lecta/core/routing';
import billingImgSrc from './images/billingError.png';

@Component({
  selector: 'app-billing-error',
  templateUrl: 'billing-error.html',
  styleUrls: ['billing-error.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingErrorComponent implements OnInit {
  serviceUnavailableErrorState = createServiceUnavailableStatusState();
  anotherButton = {
    link: '/',
    description: 'Вернуться на главную'
  }
  imgSrc = billingImgSrc;

  constructor(private activatedRoute: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    const originalUrl = this.activatedRoute.snapshot.queryParams[ORIGINAL_URL_QUERY_PARAM];

    if (originalUrl) {
      this.location.replaceState(originalUrl);
    }
  }
}
