<app-homework-layout [isMobile]="isMobile">
  <ng-container headerLogo>
    <app-header-logo [class.-disabled]="true"></app-header-logo>
  </ng-container>
  <ng-container body>
    <div class="root">
      <div class="content">
        <div class="image"><img [src]="image" alt="" /></div>
        <div class="title">
          <ng-content select="[title]"></ng-content>
        </div>
        <div class="desc">
          <ng-content select="[description]"></ng-content>
        </div>
        <div class="button-container">
          <span *ngIf="showReloadPageButton" class="button">
            <lecta-button size="s" type="primary-blue" (click)="reload()">Попробовать снова</lecta-button>
          </span>
          <span *ngIf="primaryActionButton" class="button">
            <lecta-button size="s" type="primary-blue" (click)="primaryAction()">
              {{this.primaryActionButton?.description}}
            </lecta-button>
          </span>
          <span *ngIf="anotherActionButton" class="button">
            <lecta-button size="s" type="secondary-blue" (click)="anotherAction()">
              {{this.anotherActionButton?.description}}
            </lecta-button>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</app-homework-layout>
