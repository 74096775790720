import { Injectable } from '@angular/core';
import { DnevnikruCreateHomeworkParams, DnevnikruParams, DnevnikruStartHomeworkParams } from '../interface';
import { CurrentUserService } from 'user/core/service/current-user';
import { Router, UrlTree } from '@angular/router';
import { UserNavigationService } from 'user/core/service/navigation';
import { SessionStorageService } from 'core/service/session-storage';

const DNEVNIKRU_PARAMS_STORAGE_KEY = 'skysmart-dnevnikru-params';
const DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY = 'skysmart-dnevnikru-create-homework-params';
const DNEVNIKRU_START_HOMEWORK_PARAMS_STORAGE_KEY = 'skysmart-dnevnikru-start-homework-params';

@Injectable({ providedIn: 'root' })
export class DnevnikruService {
  constructor(
    private sessionStorageService: SessionStorageService,
    private currentUserService: CurrentUserService,
    private userNavigationService: UserNavigationService,
    private router: Router,
  ) {}

  setParams(params: DnevnikruParams): void {
    this.sessionStorageService.set(DNEVNIKRU_PARAMS_STORAGE_KEY, params);
  }

  removeParams(): void {
    this.sessionStorageService.remove(DNEVNIKRU_PARAMS_STORAGE_KEY);
  }

  getParams(): DnevnikruParams | null {
    return this.sessionStorageService.get(DNEVNIKRU_PARAMS_STORAGE_KEY);
  }

  setCreateHomeworkParams(params: DnevnikruCreateHomeworkParams): void {
    this.sessionStorageService.set(DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY, params);
  }

  getCreateHomeworkParams(): DnevnikruCreateHomeworkParams | null {
    return this.sessionStorageService.get(DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY);
  }

  removeCreateHomeworkParams(): void {
    this.sessionStorageService.remove(DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY);
  }

  setStartHomeworkParams(params: DnevnikruStartHomeworkParams): void {
    this.sessionStorageService.set(DNEVNIKRU_START_HOMEWORK_PARAMS_STORAGE_KEY, params);
  }

  getStartHomeworkParams(): DnevnikruStartHomeworkParams | null {
    return this.sessionStorageService.get(DNEVNIKRU_START_HOMEWORK_PARAMS_STORAGE_KEY);
  }

  handleHomeworkCreate(): void {
    if (!this.getCreateHomeworkParams()) {
      return;
    }

    this.removeCreateHomeworkParams();
  }

  getAfterBindRedirectUrlTree(): UrlTree {
    if (this.currentUserService.isTeacher()) {
      return this.router.createUrlTree(['homework', 'new']);
    }

    const params = this.getStartHomeworkParams();
    if (params) {
      return this.router.createUrlTree(['student', params?.taskHash]);
    }

    return this.router.parseUrl(this.userNavigationService.getDefaultUrl());
  }
}
