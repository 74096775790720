import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { switchMap, map, share, tap } from 'rxjs/operators';
import {
  TLectaApiDelayedId,
  TLectaApiRequestCallbackResponse,
  ILectaApiDelayedRequestCallback,
  ILectaApiDelayedRequestOptions,
} from '../lecta-api.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IDelayedRequest<T, TId extends TLectaApiDelayedId> {
  ids: TId[];
  request: Observable<TLectaApiRequestCallbackResponse<T>>;
}

export const DEFAULT_TIMEOUT_MS = 20;

@Injectable({ providedIn: 'root' })
export class LectaApiDelayedService {
  private requestsCounter = 0;
  private pendingRequests: { [group: string]: IDelayedRequest<unknown, TLectaApiDelayedId> } = {};

  delayedRequest<T, TId extends TLectaApiDelayedId>(
    group: string,
    id: TId,
    requestCallback: ILectaApiDelayedRequestCallback<T, TId>,
    options: ILectaApiDelayedRequestOptions = {},
  ): Observable<T> {
    // NOTE: counter is needed when same group requests are added when previous is not done yet
    const requestGroup = group + this.requestsCounter;

    let idIndex = 0;

    if (!this.pendingRequests[requestGroup]) {
      // eslint-disable-next-line functional/immutable-data
      this.pendingRequests[requestGroup] = {
        ids: [id],
        request: this.request(requestGroup, requestCallback, options),
      };
    } else {
      // eslint-disable-next-line functional/immutable-data
      this.pendingRequests[requestGroup].ids = [...this.pendingRequests[requestGroup].ids, id];

      idIndex = this.pendingRequests[requestGroup].ids.length - 1;
    }

    return this.pendingRequests[requestGroup].request.pipe(
      // @ts-ignore
      map(data => (options.extractById ? (data[id as string | number] as T) : (data as T[])[idIndex])),
    );
  }

  private request<T, TId extends TLectaApiDelayedId>(
    requestGroup: string,
    requestCallback: ILectaApiDelayedRequestCallback<T, TId>,
    options: ILectaApiDelayedRequestOptions = {},
  ): Observable<TLectaApiRequestCallbackResponse<T>> {
    const timeoutMs = options.timeoutMs || DEFAULT_TIMEOUT_MS;

    return timer(timeoutMs).pipe(
      tap(() => (this.requestsCounter += 1)),
      map(() => this.pendingRequests[requestGroup].ids),
      switchMap(ids => requestCallback(ids as TId[])),
      tap(() => this.removeRequestFromQueue(requestGroup)),
      share(),
    );
  }

  private removeRequestFromQueue(requestGroup: string): void {
    const { [requestGroup]: _, ...requestQueue } = this.pendingRequests;

    this.pendingRequests = requestQueue;
  }
}
