function parseUrl(): { parts: string[]; urlDomain: string } {
  const parts = window.location.hostname.split('.');

  return {
    parts,
    urlDomain: parts.slice(-2, -1)[0],
  };
}

function isTestingServer(): boolean {
  const { parts } = parseUrl();

  return parts[parts.length - 1] === 'link';
}

// Используется простым копированием в нескольких других местах
export function getBaseDomain({ forceDomain }: { forceDomain?: string } = {}): string {
  // vimbox.skyeng.ru | vimbox.test-yXX.skyeng.link
  const { parts, urlDomain } = parseUrl();

  // Uglyhack for multidomain skyengschool
  if (urlDomain === 'skyengschool' && forceDomain === 'skyeng') {
    forceDomain = 'skyengschool';
  } else if (urlDomain === 'skysmartschool' && forceDomain === 'skysmart') {
    forceDomain = 'skysmartschool';
  } else if (urlDomain === 'osmi' && forceDomain === 'skyeng') {
    forceDomain = 'osmi';
  }

  const domainParts = [
    // optional testing subdomain `test-yXX`
    isTestingServer() ? parts.slice(-3, -2)[0] : undefined,
    // main domain
    forceDomain ?? urlDomain,
    // `.ru`/`.com`/`.link`/etc
    parts.slice(-1),
  ].filter(part => !!part);

  return domainParts.join('.');
}
