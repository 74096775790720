import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class ProsvIdUrlBuilderService {
  private prosvIdHost = environment.thirdpartyServices.prosvId.host;
  private consumer = environment.thirdpartyServices.prosvId.consumer;

  getLogoutUrlHref(): string {
    const origin = window.location.href;
    const returnUrl = encodeURIComponent(origin);

    return `${this.prosvIdHost}/signout?consumer=${this.consumer}&return=${returnUrl}`;
  }

  getLogoutUrl(): string {
    const origin = window.location.origin;
    const returnUrl = encodeURIComponent(origin);

    return `${this.prosvIdHost}/signout?consumer=${this.consumer}&return=${returnUrl}`;
  }

  getProfileUrl(): string {
    return `${this.prosvIdHost}/profile?consumer=${this.consumer}`;
  }

  isProsvIdHost(host: string): boolean {
    return this.prosvIdHost === host;
  }
}
