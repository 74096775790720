import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserNavigationService } from 'user/core/service/navigation';
import { extractExternalProviderQueryParam } from 'integrations/prosv/helpers';
import { ProsvIdAuthService } from 'integrations/prosv/service/prosv-id-auth.service';
import { AuthService } from 'user/core/service/auth';
import { DnevnikruCreateHomeworkService } from '../../dnevnikru/core/service/dnevnikru-create-homework.service';
import { extractDnevnikruCreateHomeworkParamsNew } from '../../dnevnikru/core/helpers';

@Injectable({ providedIn: 'root' })
export class ExternalAuthProviderGuard implements CanActivate {
  constructor(
    protected userNavigationService: UserNavigationService,
    private prosvIdAuthService: ProsvIdAuthService,
    private authService: AuthService,
    private dnevnikruCreateHomeworkService: DnevnikruCreateHomeworkService,
  ) {}

  canActivate({ queryParams, params }: ActivatedRouteSnapshot): boolean {
    const provider = extractExternalProviderQueryParam(queryParams);
    const homeworkHash = params.homeworkHash || null;

    if (provider) {
      this.authService.logout();

      if (provider === 'dnevnik') {
        const dnevnikParams = extractDnevnikruCreateHomeworkParamsNew(queryParams);

        if (dnevnikParams) {
          this.dnevnikruCreateHomeworkService.setCreateHomeworkParams(dnevnikParams);
        }
      }

      this.prosvIdAuthService.openProsvIdAuth({ provider, homeworkHash });

      return false;
    } else {
      return true;
    }
  }
}
