export enum ProductFeatures {
  homeworkContentItems = 'homeworkContentItems', // Не понятно где и зачем это используется?
  studentContest = 'studentContest',
  isVcUnavailable = 'isVcUnavailable',
  isNewBillingUnavailable = 'isNewBillingUnavailable',
  isMeshAvailable = 'isMeshAvailable',
}

export type ProductFeature =
  | ProductFeatures.homeworkContentItems
  | ProductFeatures.studentContest
  | ProductFeatures.isNewBillingUnavailable
  | ProductFeatures.isMeshAvailable;

export interface AppFeatureDetails {}

export interface AppFeature<DetailsType extends AppFeatureDetails = {}> {
  enable: boolean;
  details: DetailsType;
}

export type AppFeatures = { [K in ProductFeature]: AppFeature };
