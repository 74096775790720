import { fromEvent, Observable, of, race, throwError } from 'rxjs';
import { mapTo, shareReplay, switchMapTo, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { LECTA_MATHML_CONFIG } from '../lecta-mathml.consts';
import { LectaMathmlConfig } from '../lecta-mathml.interfaces';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MathJax: any;
  }
}

@Injectable({ providedIn: 'root' })
export class LectaMathmlService {
  constructor(@Inject(LECTA_MATHML_CONFIG) private skysmartMathmlConfig: LectaMathmlConfig) {
    window.MathJax = this.skysmartMathmlConfig.mathJaxConfig;
  }

  /**
   * Рендерит MathML элементы внутри переданных DOM элементов
   * Если не переданы - рендерит всю страницу
   *
   * @param elements
   */
  render(elements?: HTMLElement[]): Observable<void> {
    return this.loadScript('mathjax', 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js').pipe(
      tap(() => {
        // additionally check for MathJax method absence
        // for some reason it can be undefined event when script is loaded
        if (window.MathJax?.typeset) {
          window.MathJax.typeset(elements);
        }
      }),
    );
  }

  /**
   * Вычищает из памяти инстансы преобразованных MathML-объектов, которые лежат внутри переданных DOM элементов
   * Если элементы не переданы - очищает все объекты
   * см. http://docs.mathjax.org/en/latest/web/typeset.html#updating-previously-typeset-content
   *
   * @param elements
   */
  clear(elements?: HTMLElement[]): void {
    if (window.MathJax?.typesetClear) {
      window.MathJax.typesetClear(elements);
    }
  }

  loadScript(name: string, url: string): Observable<void> {
    const scriptId = `vim-core-script-${name}`;

    // script is already loaded
    if (document.querySelector(`#${scriptId}`)) {
      return of(undefined).pipe(shareReplay({ refCount: false, bufferSize: 1 }));
    }

    const scriptElement = document.createElement('script');
    /* eslint-disable functional/immutable-data */
    scriptElement.src = url;
    scriptElement.async = true;
    scriptElement.id = scriptId;
    /* eslint-enable functional/immutable-data */

    const loaded$ = fromEvent(scriptElement, 'load').pipe(mapTo(undefined));
    const error$ = fromEvent(scriptElement, 'error').pipe(
      switchMapTo(throwError(new Error(`VimCoreWidgetsService: cannot load '${name}' widgets from '${url}' url`))),
    );

    document.body.appendChild(scriptElement);

    return race(loaded$, error$).pipe(shareReplay({ refCount: false, bufferSize: 1 }));
  }
}
