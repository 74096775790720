import { getWindow } from './get-window.utility';

export function getDeviceOrientation(): 'portrait' | 'landscape' {
  const window = getWindow();

  if (!window) {
    return 'landscape';
  }

  if (window.screen.orientation) {
    return window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait';
  }

  // iOS/safari
  return Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait';
}
