import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MetaService } from 'meta/services/meta.service';
import { createNotFoundStatusState } from '../../../meta/utils/create-state';
import { ORIGINAL_URL_QUERY_PARAM } from '@lecta/core/routing';

@Component({
  selector: 'app-page-404',
  templateUrl: 'page-404.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page404Component implements OnInit {
  errorState = createNotFoundStatusState();

  constructor(private activatedRoute: ActivatedRoute, private location: Location, private metaService: MetaService) {}

  ngOnInit(): void {
    // https://github.com/angular/angular/issues/17004#issuecomment-414459149
    const originalUrl = this.activatedRoute.snapshot.queryParams[ORIGINAL_URL_QUERY_PARAM];

    if (originalUrl) {
      this.location.replaceState(originalUrl);
    }

    this.metaService.setTitle('Страница не найдена');
    this.metaService.update([{ name: 'description', content: 'Ошибка 404, такой страницы не существует.' }]);
  }
}
