import { Observable } from 'rxjs';
import { Notification } from 'lecta-ui/notification/lecta-notification.interface';

export interface NotificationRef {
  onClose: () => Observable<void>;
  close: () => void;
}

export class NotificationInstance {
  constructor(public notification: Notification, private closeCallback: () => void) {}

  close(): void {
    this.closeCallback();
  }
}
