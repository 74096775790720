import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LectaLocalStorageService } from '@lecta/core/local-storage';
import { Router } from '@angular/router';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@lecta/core/rxjs';

const TOKEN_KEY = 'lecta-cms-token';

@DecorateUntilDestroy()
@Injectable({ providedIn: 'root' })
export class CmsAuthService {
  private token = new BehaviorSubject<string | null>(null);

  token$ = this.token.asObservable();

  constructor(private localStorageService: LectaLocalStorageService, private router: Router) {
    this.init();
  }

  private init(): void {
    this.recoverFromStorage();
  }

  private recoverFromStorage(): void {
    this.token.next(this.getFromStorage());
  }

  authenticate(token: string): void {
    this.token.next(token);
    this.saveToStorage(token);
  }

  logout(): Observable<void> {
    this.token.next(null);
    this.saveToStorage(null);

    return of(undefined);
  }

  logoutAndRedirectToLogin(replaceUrl: boolean = false): void {
    this.logout()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/cms', 'login'], { replaceUrl });
      });
  }

  isAuthenticated(): boolean {
    return this.getCurrentToken() !== null;
  }

  getCurrentToken(): string | null {
    return this.token.getValue();
  }

  private getFromStorage(): string | null {
    return this.localStorageService.get<string>(TOKEN_KEY) || null;
  }

  private saveToStorage(value: string | null): boolean {
    return this.localStorageService.set<string | null>(TOKEN_KEY, value);
  }
}
