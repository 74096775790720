import { Injectable, Injector, OnDestroy } from '@angular/core';
import {
  Notification,
  NotificationConfig,
  NotificationPosition,
  NotificationRef,
} from '../lecta-notification.interface';
import { Store } from 'core/store';
import { Subject } from 'rxjs';
import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { LectaSecondaryContainerComponent } from '../components/secondary-container/lecta-secondary-container.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { LectaMainContainerComponent } from '../components/main-container/lecta-main-container.component';
import { BreakpointService } from '@lecta/ui/layout';
import revertAttentionIconSrc from '../components/notification-item/images/revert-attention.svg?raw';
import successIconSrc from '../components/notification-item/images/success.svg?raw';
import attentionIconSrc from '../components/notification-item/images/attention.svg?raw';

const INITIAL_STORE = {
  main: [] as Notification[],
  secondary: [] as Notification[],
};

@Injectable({
  providedIn: 'root',
})
export class LectaNotificationService implements OnDestroy {
  private store = new Store(INITIAL_STORE);
  private mainOverlayRef?: OverlayRef | null;
  private secondaryOverlayRef?: OverlayRef | null;
  private topOffset = 0;

  mainNotifications$ = this.store.select('main');
  secondaryNotifications$ = this.store.select('secondary');

  constructor(private overlay: Overlay, private injector: Injector, private dsBreakpointService: BreakpointService) {}

  ngOnDestroy(): void {}

  openInfoNotification(config: NotificationConfig): NotificationRef {
    return this.openNotification({
      ...config,
      icon: revertAttentionIconSrc,
      type: 'info',
    });
  }

  openSuccessNotification(config: NotificationConfig): NotificationRef {
    return this.openNotification({
      ...config,
      icon: successIconSrc,
      type: 'positive',
    });
  }

  openWarningNotification(config: NotificationConfig): NotificationRef {
    return this.openNotification({
      ...config,
      icon: attentionIconSrc,
      type: 'warning',
    });
  }

  openErrorNotification(config: NotificationConfig): NotificationRef {
    return this.openNotification({
      ...config,
      icon: attentionIconSrc,
      type: 'negative',
    });
  }

  openNotification(config: NotificationConfig): NotificationRef {
    const concatConfig = LectaNotificationService.getFullConfig(config);
    const notification: Notification = {
      content: concatConfig.content,
      component: concatConfig.component,
      autoClosing: concatConfig.autoClosing,
      icon: concatConfig.icon,
      type: concatConfig.type,
      onClose: new Subject<void>(),
    };

    const position = this.dsBreakpointService.isSmall() ? 'secondary' : concatConfig.position!;
    this.store.updateFields({
      [position]: [notification, ...this.store.get(position)],
    });

    this.createComponentIfNeeded(position);

    return {
      onClose: () => notification.onClose.asObservable(),
      close: () => this.closeNotification(notification, position),
    };
  }

  closeNotification(notification: Notification, position: NotificationPosition): void {
    const notifications = this.store.get(position);
    this.store.updateFields({
      [position]: notifications.filter(filterableNotification => filterableNotification !== notification),
    });
    notification.onClose.next();
    notification.onClose.complete();
  }

  destroyMainOverlayIfEmpty(): void {
    if (this.store.get('main').length !== 0) {
      return;
    }
    this.mainOverlayRef?.detach();
    this.mainOverlayRef?.dispose();
    this.mainOverlayRef = null;
  }

  destroySecondaryOverlayIfEmpty(): void {
    if (this.store.get('secondary').length !== 0) {
      return;
    }
    this.secondaryOverlayRef?.detach();
    this.secondaryOverlayRef?.dispose();
    this.secondaryOverlayRef = null;
  }

  setTopOffset(offset: number): void {
    this.topOffset = offset;
    this.repositionMainOverlay();
  }

  private repositionMainOverlay(): void {
    if (this.mainOverlayRef) {
      const positionStrategy = this.overlay
        .position()
        .global()
        .top(this.topOffset + 'px');
      positionStrategy.attach(this.mainOverlayRef);
      positionStrategy.apply();
    }
  }

  private createOverlay(): OverlayRef {
    return this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .top(this.topOffset + 'px'),
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
  }

  private createContainer<T>(overlayRef: OverlayRef, component: ComponentType<T>): ComponentPortal<T> {
    const portal = new ComponentPortal(component, undefined, this.injector);
    overlayRef.attach(portal);
    return portal;
  }

  private createComponentIfNeeded(position: NotificationPosition): void {
    if (position === 'main' && !this.mainOverlayRef) {
      this.mainOverlayRef = this.createOverlay();
      this.createContainer<LectaMainContainerComponent>(this.mainOverlayRef, LectaMainContainerComponent);
    }
    if (position === 'secondary' && !this.secondaryOverlayRef) {
      this.secondaryOverlayRef = this.createOverlay();
      this.createContainer<LectaSecondaryContainerComponent>(
        this.secondaryOverlayRef,
        LectaSecondaryContainerComponent,
      );
    }
  }

  private static getFullConfig(config: NotificationConfig): Required<NotificationConfig> {
    const defaultConfig: NotificationConfig = {
      autoClosing: true,
      position: 'secondary',
    };
    const fullConfig = {
      ...defaultConfig,
      ...config,
    } as Required<NotificationConfig>;
    if (!fullConfig.content && !fullConfig.component) {
      throw new Error('No content passed');
    }
    if (!fullConfig.icon) {
      throw new Error('No icon passed');
    }
    if (!fullConfig.type) {
      throw new Error('No type passed');
    }
    if (!fullConfig.position) {
      throw new Error('No position passed');
    }
    return fullConfig;
  }
}
