import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TSimplePopupBehaviour, TSimplePopupInputs } from 'ui/popup/interface';
import { PopupInstance } from 'ui/popup/popup-instance';

/**
 * @deprecated use DsDialogModule's components
 */
@Component({
  selector: 'app-ui-simple-popup',
  templateUrl: 'simple-popup.html',
  styleUrls: ['simple-popup.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimplePopupComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() confirmText?: string;
  @Input() cancelText?: string;
  @Input() behaviour?: TSimplePopupBehaviour = 'confirm';

  constructor(@Inject(PopupInstance) private popupInstance: PopupInstance<void, TSimplePopupInputs>) {}

  ngOnInit(): void {
    const data = this.popupInstance.data;
    this.title = data.title;
    this.message = data.message;
    this.confirmText = data.confirmText;
    this.cancelText = data.cancelText;
    this.behaviour = data.behaviour || 'confirm';
  }

  confirm(): void {
    this.popupInstance.close();
  }

  cancel(): void {
    this.popupInstance.close({ rejected: true });
  }
}
