import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointL, BreakpointM, BreakpointS, BreakpointXL } from '../layout.consts';
import { Breakpoint } from '../layout.interface';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private mediaQueryS = BreakpointService.getMediaQuery(BreakpointS);
  private mediaQueryM = BreakpointService.getMediaQuery(BreakpointM);
  private mediaQueryL = BreakpointService.getMediaQuery(BreakpointL);
  private mediaQueryXL = BreakpointService.getMediaQuery(BreakpointXL);

  constructor(private breakpointObserver: BreakpointObserver) {}

  isSize(breakpoint: Breakpoint): boolean {
    return this.breakpointObserver.isMatched(BreakpointService.getMediaQuery(breakpoint));
  }

  isSmall(): boolean {
    return this.breakpointObserver.isMatched(this.mediaQueryS);
  }

  isMedium(): boolean {
    return this.breakpointObserver.isMatched(this.mediaQueryM);
  }

  isLarge(): boolean {
    return this.breakpointObserver.isMatched(this.mediaQueryL);
  }

  isExtraLarge(): boolean {
    return this.breakpointObserver.isMatched(this.mediaQueryXL);
  }

  private static getMediaQuery(breakpoint: Breakpoint): string {
    const minWidthQuery = breakpoint.minWidth ? `(min-width: ${breakpoint.minWidth}px)` : null;
    const maxWidthQuery = breakpoint.maxWidth ? `(max-width: ${breakpoint.maxWidth}px)` : null;
    return [minWidthQuery, maxWidthQuery].filter(Boolean).join(' and ');
  }
}
