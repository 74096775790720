import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRegistryService } from './user-registry';
import { environment } from 'environments/environment';
import {IdAuthScriptService} from "../../../app/service/id-auth-script";

const ANONYMOUS_URLS = [
  new RegExp('^/api/v1/auth/registration/(teacher|student)'),
  new RegExp('^/api/v2/auth/registration/student'),
  new RegExp('^/api/v3/auth/registration/student'),
  new RegExp('^/api/v1/auth/auth'),
  new RegExp('^/api/v1/auth/auth/student'),
  new RegExp('^/api/v2/auth/auth/student'),
  new RegExp('^/api/v2/auth/school-admin'),
  new RegExp('^/api/v1/auth/reflink'),
  new RegExp('^/api/v1/auth/reflink/top'),
  new RegExp('^/api/v1/login-link/u'),
  new RegExp('^/api/v1/profile/referral-pay'),
  new RegExp('^/api/v1/auth/send-restore-link'),
  new RegExp('^/api/v2/auth/send-restore-link'),
  new RegExp('^/api/v1/geo/cities'),
  new RegExp('^/api/v1/school'),
  new RegExp('^/api/v1/content/get-workbooks'),
  new RegExp('^/api/v1/content/get-modules-for-workbook'),
  new RegExp('^/api/v1/content/step/load-only'),
  new RegExp('^/api/v1/billing/anonymous-order'),
  new RegExp('^/api/v1/user/add-lead'),
  new RegExp('^/api/v1/product-feature'),
  new RegExp('^/api/v1/promo/campaign/[^/]+'),
  new RegExp('^/api/v1/auth/prosv/[^/]+'),
  new RegExp('^/api/v1/task/preview'),
  new RegExp('^/api/v1/content/[^/]+'),
  new RegExp('^/api/v1/geo-location/user'),
];

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userRegistryService: UserRegistryService, private idAuthScript: IdAuthScriptService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {


    if (
      !(request.url.includes(environment.app.rootDomain) || request.url.includes(environment.app.skyengDomain)) ||
      request.url.includes(environment.endpoints.serverApi)
    ) {
      return next.handle(request);
    }

    const currentUser = this.userRegistryService.getCurrentUser();
    const token = currentUser ? currentUser.token : '';
    if (!token && this.isAuthRequired(request.url)) {
      this.idAuthScript.redirectToMain();
      throw new Error('Try to send request without authorization token for:' + request.url);
    }

    if (!token) {
      return next.handle(request);
    }

    const modifiedRequest = request.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    });

    return next.handle(modifiedRequest);
  }

  private isAuthRequired(url: string): boolean {
    if (!url.startsWith(environment.endpoints.api)) {
      return false;
    }
    const path = url.replace(environment.endpoints.api, '');

    return !ANONYMOUS_URLS.some(pattern => path.match(pattern));
  }
}
