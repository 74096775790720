import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpinnerColor, SpinnerPosition, SpinnerSize } from '../../lecta-spinner.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-spinner',
  templateUrl: './lecta-spinner.component.html',
  styleUrls: ['./lecta-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaSpinnerComponent {
  @Input() color: SpinnerColor = 'primary';
  @Input() size: SpinnerSize = 'm';
  @Input() position?: SpinnerPosition;
  @Input() zIndex?: number;
}
