import { Injectable } from '@angular/core';
import Cookies from 'js-cookie';
import { Observable, of } from 'rxjs';
import {catchError, map, pluck, publishLast, refCount, take} from 'rxjs/operators';

import { AppFeatureDetails, AppFeatures, ProductFeature, ProductFeatures } from '../interface';
import { ProductFeaturesApiService } from './product-features-api';

@Injectable({ providedIn: 'root' })
export class ProductFeaturesService {
  private features$: Observable<AppFeatures>;

  constructor(appFeaturesApiService: ProductFeaturesApiService) {
    this.features$ = appFeaturesApiService.getFeatures().pipe(
      pluck('items'),
      catchError(() => of(this.getDefaultFeaturesSettings())),
      publishLast(),
      refCount(),
    );
  }

  init(): void {
    this.features$.pipe(take(1)).subscribe();
  }

  isFeatureEnabled(feature: ProductFeature): Observable<boolean> {
    return this.features$.pipe(
      map(features => features[feature]?.enable || false),
      map(enabled => enabled || this.isEnabledByCookie(feature)),
      publishLast(),
      refCount(),
    );
  }

  private getDefaultFeaturesSettings(): AppFeatures {
    return {
      [ProductFeatures.homeworkContentItems]: {
        enable: false,
        details: {},
      },
      [ProductFeatures.studentContest]: {
        enable: false,
        details: {},
      },
      [ProductFeatures.isNewBillingUnavailable]: {
        enable: false,
        details: {},
      },
      [ProductFeatures.isMeshAvailable]: {
        enable: true,
        details: []
      }
    };
  }

  private isEnabledByCookie(feature: ProductFeature): boolean {
    const cookieName = `product_feature_${feature}`;
    return !!Cookies.get(cookieName) || false;
  }

  getFeatureDetails<DetailsType extends AppFeatureDetails>(feature: ProductFeature): Observable<DetailsType> {
    return this.features$.pipe(
      map(features => features[feature]?.details as DetailsType),
      map(details => details ?? this.getDefaultFeatureDetails<DetailsType>(feature)),
      publishLast(),
      refCount(),
    );
  }

  private getDefaultFeatureDetails<DetailsType extends AppFeatureDetails>(feature: ProductFeature): DetailsType {
    return this.getDefaultFeaturesSettings()[feature]?.details as DetailsType;
  }
}
