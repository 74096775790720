<app-error-page-layout
  [appMetaTags]=""
  [image]="image"
  [appPrerenderMetaTags]="errorState"
  [showReloadPageButton]="false"
  [primaryActionButton]="primaryButton"
>
  <ng-container title>{{title}}</ng-container>
  <ng-container description>
    <p>{{description}}</p>
  </ng-container>
</app-error-page-layout>
