import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-homework-layout',
  templateUrl: './homework-layout.component.html',
  styleUrls: ['./homework-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeworkLayoutComponent {
  @Input() isMobile: boolean;
}
