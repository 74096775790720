import { Injectable } from '@angular/core';
import { LectaDialogService } from '@lecta/ui/modal-dialog';
import { DnevnikruCreateHomeworkErrorPopupComponent } from '../../components/create-homework-error-popup/dnevnikru-create-homework-error-popup.component';
import { DnevnikruCreateHomeworkSuccessPopupComponent } from '../../components/create-homework-success-popup/dnevnikru-create-homework-success-popup.component';
import { Router } from '@angular/router';
import {
  DnevnikruCreateHomeworkParams,
  DnevnikruCreateHomeworkStateStatus,
  DnevnikruCreateHomeworkSuccessPopupValue,
} from '../interface';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@lecta/core/rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SessionStorageService } from '@lecta/core/service/session-storage';
import { Observable, of } from 'rxjs';

const DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY = 'lecta-dnevnikru-create-homework-params';

@DecorateUntilDestroy()
@Injectable({ providedIn: 'root' })
export class DnevnikruCreateHomeworkService {
  private state: DnevnikruCreateHomeworkStateStatus | null = null;
  private licenseFormRedirectUrl = '/application?landingName=teacher';

  constructor(
    private dialogService: LectaDialogService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {}

  setState(value: DnevnikruCreateHomeworkStateStatus | null): void {
    this.state = value;
  }

  run(): Observable<boolean> {
    if (!this.state) {
      return of(false);
    }

    if (this.state === DnevnikruCreateHomeworkStateStatus.success) {
      return this.openSuccessPopup();
    }

    return this.openErrorPopup();
  }

  setCreateHomeworkParams(params: DnevnikruCreateHomeworkParams): void {
    this.sessionStorageService.set(DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY, params);
  }

  getCreateHomeworkParams(): DnevnikruCreateHomeworkParams | null {
    return this.sessionStorageService.get(DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY);
  }

  removeCreateHomeworkParams(): void {
    this.sessionStorageService.remove(DNEVNIKRU_CREATE_HOMEWORK_PARAMS_STORAGE_KEY);
  }

  handleHomeworkCreate(): void {
    if (!this.getCreateHomeworkParams()) {
      return;
    }

    this.removeCreateHomeworkParams();
  }

  private openSuccessPopup(): Observable<boolean> {
    return this.dialogService
      .open<void, null | DnevnikruCreateHomeworkSuccessPopupValue>(DnevnikruCreateHomeworkSuccessPopupComponent)
      .onClose()
      .pipe(
        filter(({ rejected }) => !rejected),
        tap(({ data }) => {
          if (data && data.redirectToLicenseForm) {
            this.router.navigateByUrl(this.licenseFormRedirectUrl);
          }

          this.setState(null);
        }),
        map(() => true),
        takeUntilDestroyed(this),
      );
  }

  private openErrorPopup(): Observable<boolean> {
    return this.dialogService
      .open<void, boolean>(DnevnikruCreateHomeworkErrorPopupComponent)
      .onClose()
      .pipe(
        filter(({ rejected }) => !rejected),
        tap(() => {
          this.setState(null);
        }),
        map(() => true),
        takeUntilDestroyed(this),
      );
  }
}
