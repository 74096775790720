import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SimpleGaTrackingService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer= this.rendererFactory.createRenderer(null, null);
  }

  init(): void {
    this.initFirstContainer();
    this.initTwoContainer();
  }

  private initTwoContainer() {
    const appId = environment.thirdpartyServices.ga.appIdTwo;

    if (!appId) {
      return;
    }

    this.loadTwoContainer(appId);
  }

  private initFirstContainer() {
    const appId = environment.thirdpartyServices.ga.appId;

    if (!appId) {
      return;
    }

    this.loadGoogleAnalyticsScript(appId);
  }

  trackEvent(event: string): boolean {
    const appId = environment.thirdpartyServices.ym.appId || '';
    if (appId) {
      // @ts-ignore
      if (window['ym']) {
        // @ts-ignore
        window['ym'](appId, 'reachGoal', event);
        return true;
      }
    }

    return false;
  }

  private getScriptContentWithKey(key: string): string {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${key}');`;
  }

  private loadGoogleAnalyticsScript(key: string): void {
    const scriptTag = this.renderer.createElement('script');

    // eslint-disable-next-line functional/immutable-data
    scriptTag.innerHTML = this.getScriptContentWithKey(key);

    this.renderer.setAttribute(scriptTag, 'type', 'text/javascript');
    this.renderer.appendChild(this.document.head, scriptTag);
  }

  private getScriptContentWithKeyTwoContainer(key: string): string {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${key}');`;
  }

  private loadTwoContainer(key: string): void {
    const scriptTag = this.renderer.createElement('script');

    // eslint-disable-next-line functional/immutable-data
    scriptTag.innerHTML = this.getScriptContentWithKeyTwoContainer(key);

    this.renderer.setAttribute(scriptTag, 'type', 'text/javascript');
    this.renderer.appendChild(this.document.head, scriptTag);

    const noScriptTag = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(iframe, 'src', `https://www.googletagmanager.com/ns.html?id=${key}`);
    this.renderer.setAttribute(iframe, 'height', `0`);
    this.renderer.setAttribute(iframe, 'width', `0`);
    iframe.style.display='none';
    iframe.style.visibility='hidden';
    this.renderer.appendChild(this.document.body, noScriptTag);
    this.renderer.appendChild(noScriptTag, iframe);
  }
}
