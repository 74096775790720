<lecta-dialog>
  <div lectaDialogHeader>
    <div class="title-image"></div>
    <div class="title">Задание успешно создано!</div>
  </div>

  <ng-container lectaDialogBody>
    <div class="dialog-body">
      <p>
        Ссылка на созданное задание добавлена на страницу урока в
        <a [href]="lessonLink" target="_blank" class="link">Дневник.ру</a>
        . Она также появится в дневниках учеников.
      </p>
      <p>
        Все задания в цифровых рабочих тетрадях можно решить бесплатно, однако в бесплатной версии есть ряд ограничений.
      </p>
    </div>
  </ng-container>

  <div lectaDialogFooter>
    <div class="buttons">
      <lecta-button class="button" type="primary-blue" size="m" (click)="close()">Продолжить работу</lecta-button>
      <lecta-button class="button licence-button" type="secondary-blue" size="m" (click)="goToLicense()">
        Подробнее
      </lecta-button>
    </div>
  </div>
</lecta-dialog>
