import { Inject, Injectable } from '@angular/core';
import { LECTA_FILE_CONFIG } from 'core/file/lecta-file.consts';
import {
  LectaFileConfig,
  LectaFileCreateUploadIntentionParams,
  LectaFileCreateUploadIntentionResponse,
  LectaFileImageSize,
  LectaFileReportUploadResultParams,
  LectaImageSizeDataResponse,
} from 'core/file/lecta-file.interfaces';
import { Observable } from 'rxjs';
import { LectaApiService } from 'core/api';

@Injectable({ providedIn: 'root' })
export class LectaFileApiService {
  private endpoints: string[];

  constructor(@Inject(LECTA_FILE_CONFIG) fileConfig: LectaFileConfig, private apiService: LectaApiService) {
    this.endpoints = [fileConfig.endpoint];
  }

  deleteFile(fileId: string): Observable<void> {
    return this.apiService.delete<void>(this.endpoints, `/api/v1/file/${fileId}`);
  }

  getImageSizeData(fileId: string, size: LectaFileImageSize): Observable<LectaImageSizeDataResponse> {
    return this.apiService.get<LectaImageSizeDataResponse>(this.endpoints, `/api/v1/file/image-size/${fileId}`, {
      queryParams: size !== 'original' ? { size } : {},
    });
  }

  createIntention(params: LectaFileCreateUploadIntentionParams): Observable<LectaFileCreateUploadIntentionResponse> {
    return this.apiService.post<LectaFileCreateUploadIntentionResponse>(
      this.endpoints,
      `/api/v1/file/create-upload-intention`,
      {
        params,
      },
    );
  }

  reportUploadResult(params: LectaFileReportUploadResultParams): Observable<void> {
    return this.apiService.post<void>(this.endpoints, `/api/v1/file/storage-upload-result`, {
      params,
    });
  }
}
