import { LectaButtonComponent } from 'lecta-ui/button/components/button/lecta-button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LectaSpinnerModule } from 'lecta-ui/spinner';

@NgModule({
  imports: [CommonModule, LectaSpinnerModule],
  declarations: [LectaButtonComponent],
  exports: [LectaButtonComponent],
})
export class LectaButtonModule {}
