import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PrerenderMetaService } from 'meta/services/prerender-meta.service';
import { MetaTags, PrerenderState } from '../interfaces';
import { MetaService } from '../services/meta.service';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { SkysmartRoutingHelperService } from 'routing/service/routing-helper';

@Directive({
  selector: '[appMetaTags]',
  exportAs: 'metaTags',
})
@DecorateUntilDestroy()
export class MetaTagsDirective implements OnInit, OnChanges, OnDestroy {
  @Input('appMetaTags') tags: MetaTags;
  @Input('appPrerenderMetaTags') prerenderMetaTags: PrerenderState;

  constructor(
    private metaService: MetaService,
    private prerenderMetaService: PrerenderMetaService,
    private routingHelper: SkysmartRoutingHelperService,
  ) {}

  ngOnInit(): void {
    this.routingHelper.navigationStart$.pipe(takeUntilDestroyed(this)).subscribe(() => this.clearAllSetTags());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tags'] && this.tags) {
      this.metaService.setTags(this.tags);
    }
    if (changes['prerenderMetaTags'] && this.prerenderMetaTags) {
      this.prerenderMetaService.setPrerenderState(this.prerenderMetaTags);
    }
  }

  ngOnDestroy(): void {
    this.clearAllSetTags();
  }

  private clearAllSetTags(): void {
    if (this.tags?.openGraph) {
      this.metaService.removeTagsBy('property', this.tags.openGraph);
    }
    if (this.prerenderMetaTags) {
      this.prerenderMetaService.clearPrerenderState(this.prerenderMetaTags);
    }
  }
}
