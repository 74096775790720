import { Injectable } from '@angular/core';
import { LectaApiService } from 'core/api';
import { environment } from 'environments/environment';
import {  Observable } from 'rxjs';
import {
  TeacherNewWorkbookInfoResponse,
} from 'user/core/interface';


@Injectable({ providedIn: 'root' })
export class TeacherApi {
  private endpoints = [environment.endpoints.api];

  constructor(private apiService: LectaApiService) {}
  getUserConfig(): Observable<TeacherNewWorkbookInfoResponse[]> {
    return this.apiService.get<TeacherNewWorkbookInfoResponse[]>(this.endpoints, `/api/v1/content/promo/single-workbook/for-teacher`);
  }

}
