import { BehaviorSubject, Observable } from 'rxjs';
import { getClassMethodName } from './get-class-method-name.utility';

const DEFAULT_START_METHOD_NAME = 'start';
const DEFAULT_STOP_METHOD_NAME = 'stop';

export interface StartGuardParams {
  startMethod?: Function;
  stopMethod?: Function;
  label?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function startGuard(target: InstanceType<any>, params: StartGuardParams = {}): Observable<boolean> {
  const startMethodName = params.startMethod
    ? getClassMethodName(target, params.startMethod)!
    : DEFAULT_START_METHOD_NAME;
  const stopMethodName = params.stopMethod ? getClassMethodName(target, params.stopMethod)! : DEFAULT_STOP_METHOD_NAME;
  const inited = new BehaviorSubject<boolean>(false);

  const originalStartMethod: Function = target[startMethodName];
  const originalStopMethod: Function = target[stopMethodName];

  if (!originalStartMethod) {
    throwError(`cannot find '${startMethodName}' start method`);
  }

  if (!originalStopMethod) {
    throwError(`cannot find '${stopMethodName}' stop method`);
  }

  // eslint-disable-next-line functional/immutable-data
  target[startMethodName] = function (...args: unknown[]): unknown {
    if (inited.value) {
      throwError(`cannot init twice`);
    }

    const startReturnValue = originalStartMethod.apply(this, args);

    inited.next(true);

    return startReturnValue;
  };

  // eslint-disable-next-line functional/immutable-data
  target[stopMethodName] = function (...args: unknown[]): unknown {
    if (!inited.value) {
      throwError(`cannot stop not-started`);
    }

    const stopReturnValue = originalStopMethod.apply(this, args);

    inited.next(false);

    return stopReturnValue;
  };

  return inited.asObservable();

  function throwError(text: string): void {
    throw new Error(`startGuard(${params.label ?? target.constructor.name}): ${text}`);
  }
}
