import { ORIGINAL_URL_QUERY_PARAM } from './const';
import { NavigationExtras } from '@angular/router';

// https://github.com/angular/angular/issues/17004#issuecomment-414459149
export function getSkipLocationChangeExtras(currentUrl: string): NavigationExtras {
  return {
    skipLocationChange: true,
    queryParams: {
      [ORIGINAL_URL_QUERY_PARAM]: currentUrl,
    },
  };
}
