import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LectaApiService } from 'core/api';
import { Observable } from 'rxjs';
import { DnevnikruParams } from '../interface';

@Injectable({ providedIn: 'root' })
export class DnevnikruApi {
  private endpoints = [environment.endpoints.api];

  constructor(private apiService: LectaApiService) {}

  bind(params: DnevnikruParams): Observable<void> {
    return this.apiService.post(this.endpoints, `/api/v1/dnevnikru/bind`, {
      params: params,
      retry: 1,
    });
  }
}
