import { Injectable } from '@angular/core';
import { LectaMemoryStorageService } from './lecta-memory-storage.service';
import { IStorageEngine } from '../lecta-local-storage.interfaces';

@Injectable({ providedIn: 'root' })
export class LectaLocalStorageService {
  private readonly isSupported: boolean = false;
  private storageEngine: IStorageEngine;

  constructor(private memoryStorageService: LectaMemoryStorageService) {
    this.isSupported = LectaLocalStorageService.checkSupport();

    this.storageEngine = this.isSupported ? window.localStorage : this.memoryStorageService;
  }

  get<T>(key: string): T | null;
  get<T>(key: string, defaultValue: T): T;
  get<T>(key: string, defaultValue?: T): T | null {
    const storageValue = this.storageEngine.getItem(key);

    if (!storageValue) {
      return defaultValue || null;
    }

    let value: T | null;

    try {
      value = JSON.parse(storageValue);
    } catch (err) {
      value = null;
    }

    return defaultValue && (value === null || value === undefined) ? (defaultValue as T) : value;
  }

  set<T>(key: string, value: T): boolean {
    const storageValue = JSON.stringify(value);

    try {
      this.storageEngine.setItem(key, storageValue);
    } catch (_) {
      return false;
    }

    return true;
  }

  remove(key: string): boolean {
    try {
      this.storageEngine.removeItem(key);
    } catch (_) {
      return false;
    }

    return true;
  }

  private static checkSupport(): boolean {
    try {
      const supported = !!window.localStorage;

      if (supported) {
        // When Safari (OS X or iOS) is in private browsing mode, it
        // appears as though localStorage is available, but trying to
        // call .setItem throws an exception.
        //
        // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
        // to add something to storage that exceeded the quota."
        const key = `__${Math.round(Math.random() * 1e7)}`;
        window.localStorage.setItem(key, '');
        window.localStorage.removeItem(key);
      }

      return supported;
    } catch (err) {
      return false;
    }
  }
}
