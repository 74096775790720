import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { LectaDialogInstance } from 'lecta-ui/modal-dialog/lecta-dialog-instance.class';
import { BreakpointService } from 'lecta-ui/layout';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-dialog',
  templateUrl: './lecta-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DecorateUntilDestroy()
export class LectaDialogComponent implements OnInit {
  @Input() width = '546px';
  @Input() height: string;
  @Input() closeable = true;
  @Input() closeableByButtonOnly = true;
  @Input() closeButtonDisabled = false;
  @Input() isCustomBody: boolean;
  @Input() limitDialogHeight = true;
  @Input() overflowYInitial = false;

  isMobile: boolean;

  constructor(
    private dialogInstance: LectaDialogInstance<unknown, unknown>,
    private breakpointService: BreakpointService,
  ) {this.isMobile = this.breakpointService.isSmall();}

  ngOnInit(): void {
    this.dialogInstance
      .onShouldClose()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this.dialogInstance.detach();
      });
  }

  private close(): void {
    this.dialogInstance.close({ rejected: true });
  }

  closeByOverlayClick(): void {
    if (this.closeable) {
      this.close();
    }
  }

  closeByButtonClick(): void {
    if (this.closeable || this.closeableByButtonOnly) {
      this.close();
    }
  }
}
