import { Injectable } from '@angular/core';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { AuthService } from 'user/core/service/auth';

@DecorateUntilDestroy()
@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  private storageEngine: Storage;

  constructor(private authService: AuthService) {
    this.storageEngine = window.sessionStorage;

    this.authService.onLogout$.pipe(takeUntilDestroyed(this)).subscribe(() => {
      this.storageEngine.clear();
    });
  }

  get<T>(key: string): T | null;
  get<T>(key: string, defaultValue: T): T;
  get<T>(key: string, defaultValue?: T): T | null {
    const storageValue = this.storageEngine.getItem(key);

    if (!storageValue) {
      return defaultValue || null;
    }

    let value: T | null;

    try {
      value = JSON.parse(storageValue);
    } catch (err) {
      value = null;
    }

    return defaultValue && (value === null || value === undefined) ? (defaultValue as T) : value;
  }

  set<T>(key: string, value: T): boolean {
    const storageValue = JSON.stringify(value);

    try {
      this.storageEngine.setItem(key, storageValue);
    } catch (_) {
      return false;
    }

    return true;
  }

  remove(key: string): boolean {
    try {
      this.storageEngine.removeItem(key);
    } catch (_) {
      return false;
    }

    return true;
  }
}
