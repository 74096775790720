import {Inject, Injectable} from '@angular/core';
import { LectaApiService } from 'core/api';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import {ContentFeedbackMeta, FeedbackPageMeta} from 'step-feedback/interfaces';
import {
  ActivateSchoolStudentParams,
  CreateFeatureParams,
  PromoSchoolLeadMeta,
  UserConfig,
  UserFeedbackMeta,
  UserFeedbackType,
  UserLeadType,
  UserMailingMeta,
} from 'user/core/interface';
import { GeolocationWebPermission } from '../const';
import { ProsvetActivatedLicence } from 'student/core/interface';
import {DOCUMENT} from "@angular/common";

@Injectable({ providedIn: 'root' })
export class UsersApi {
  private endpoints = [environment.endpoints.api];

  constructor(private apiService: LectaApiService, @Inject(DOCUMENT) private document: Document) {}

  activateSchoolStudentAccount(params: ActivateSchoolStudentParams): Observable<ProsvetActivatedLicence[]> {
    return this.apiService
      .post<{ schoolLicences: ProsvetActivatedLicence[] }>(this.endpoints, '/api/v1/school/student/activate', {
        params: params,
      })
      .pipe(map(data => data.schoolLicences));
  }

  getUserConfig(): Observable<UserConfig> {
    return this.apiService.get<UserConfig>(this.endpoints, '/api/v1/user/config', {
      queryParams: {
        /**
         * uglyhack для сброса кеша конфига после перехода по страницам разных ролей [связанный баг: EDU-6534, быстрое решение: EDU-12452].
         * Поскольку такие кейсы могут возникнуть и в других эндпоинтах из-за мультиаккаунтности,
         * TODO: необходимо выяснить и устранить причину [EDU-12603].
         */
        timestamp: Date.now(),
      },
      retry: 1,
    });
  }

  acceptOffer(): Observable<void> {
    return this.apiService.put(this.endpoints, '/api/v1/user/config/accept-offer');
  }

  setGeolocationWebPermission(permission: GeolocationWebPermission): Observable<void> {
    return this.apiService.post<void>(this.endpoints, '/api/v1/user/config/set-getting-geolocation-web-permission', {
      params: { permission: permission },
      retry: 1,
    });
  }

  saveFeedback(
    type: UserFeedbackType,
    meta?: UserFeedbackMeta | ContentFeedbackMeta | UserMailingMeta,
  ): Observable<void> {

    const newMeta = {...meta,userAgent: {...(meta as FeedbackPageMeta).userAgent,viewportSize:`${this.document.defaultView?.innerHeight}x${this.document.defaultView?.innerWidth}`}}
    return this.apiService.post<void>(this.endpoints, '/api/v1/user/add-feedback', {
      params: { type, meta:newMeta },
      retry: 1,
    });
  }

  saveUserLead(type: UserLeadType, meta: PromoSchoolLeadMeta): Observable<void> {
    return this.apiService.post<void>(this.endpoints, '/api/v1/user/add-lead', {
      params: { type, meta },
      retry: 1,
    });
  }

  createFeature(params: CreateFeatureParams): Observable<void> {
    return this.apiService.post<void>(this.endpoints, '/api/v1/user-feature/create', {
      params,
      retry: 1,
    });
  }

}
