import { Injectable } from '@angular/core';
import { IStorageEngine } from '../lecta-local-storage.interfaces';

@Injectable({ providedIn: 'root' })
export class LectaMemoryStorageService implements IStorageEngine {
  private data: Record<string, string> = {};

  getItem(key: string): string {
    return this.data[key];
  }

  setItem(key: string, value: string): void {
    this.data[key] = value;
  }

  removeItem(key: string): void {
    // eslint-disable-next-line functional/immutable-data
    delete this.data[key];
  }
}
