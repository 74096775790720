import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimeZoneHeader implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {

    const modifiedRequest = request.clone({
      headers: request.headers.set('Time-Zone', Intl.DateTimeFormat().resolvedOptions().timeZone),
    });

    return next.handle(modifiedRequest);
  }
}
