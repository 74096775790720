import { MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { ILectaEvent, ILectaEventsConfig } from './interface';
import { concatAll, filter, map, share } from 'rxjs/operators';
import { EVENTS_BUFFER_DELAY_MS, EVENTS_CUSTOM_EVENT_NAME } from './const';
import { bufferDelay, takeUntilDestroyed } from 'core/rxjs';
import { listenCustomEvent, sendCustomEvent } from './helpers';

export class LectaEvents {
  private sendEventQueue = new Subject<ILectaEvent<unknown>>();
  private events$: Observable<ILectaEvent<unknown>>;
  private inited = true;
  private customEventName: string;

  constructor(private config: ILectaEventsConfig) {
    this.customEventName = this.config.noEventNamePrefix
      ? this.config.group
      : `${EVENTS_CUSTOM_EVENT_NAME}-${this.config.group}`;

    // batch multiple events to reduce hundreds events sending overhead
    this.sendEventQueue
      .asObservable()
      .pipe(bufferDelay(EVENTS_BUFFER_DELAY_MS), this.takeUntilDestroyed())
      .subscribe(events => sendCustomEvent(this.customEventName, events));
  }

  destroy(): void {
    if (!this.inited) {
      return;
    }

    this.inited = false;
  }

  listenAll(): Observable<ILectaEvent<unknown>> {
    if (!this.inited) {
      throw new Error('LectaEvents cant `listenAllEvents` after destroying');
    }

    this.initEventsListening();

    return this.events$.pipe(this.takeUntilDestroyed());
  }

  listen<T = void>(eventName: string): Observable<T> {
    if (!this.inited) {
      throw new Error('LectaEvents cant `listenEvent` after destroying');
    }

    return this.listenAll().pipe(
      filter(({ name }) => name === eventName),
      map(({ data }) => data as T),
      this.takeUntilDestroyed(),
    );
  }

  send<T = void>(name: string, data?: T): void {
    if (!this.inited) {
      throw new Error('LectaEvents cant `dispatchEvent` after destroying');
    }

    this.sendEventQueue.next({ name, data });
  }

  private takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntilDestroyed<T, this>(this, { destroyMethod: this.destroy });
  }

  // workaround for SSR, listen for `window` events eagerly to fix server code initialization when no `window` is available
  private initEventsListening(): void {
    if (this.events$) {
      return;
    }

    this.events$ = listenCustomEvent<ILectaEvent<unknown>[]>(this.customEventName).pipe(concatAll(), share());
  }
}
