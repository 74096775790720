import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'lectaSafeHtml',
})
export class LectaSafeHtmlPipe implements PipeTransform {
  constructor(private domSanitized: DomSanitizer) {}

  // NOTE have to support null for async pipe
  transform(value: string | null): SafeHtml {
    return this.domSanitized.bypassSecurityTrustHtml(value || '');
  }
}
