import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LectaDialogInstance } from '@lecta/ui/modal-dialog';

@Component({
  selector: 'app-dnevnikru-create-homework-error-popup',
  templateUrl: './dnevnikru-create-homework-error-popup.component.html',
  styleUrls: ['./dnevnikru-create-homework-error-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DnevnikruCreateHomeworkErrorPopupComponent {
  constructor(
    @Inject(LectaDialogInstance)
    private dialogInstance: LectaDialogInstance<null, null>,
  ) {}

  close(): void {
    this.dialogInstance.close({ rejected: false });
  }
}
