import { Routes } from '@angular/router';
import { SetSchoolAdminRoleGuard } from '../../../user/core/routing/guard/set-school-admin-role';

export const landingRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('../../../landing/+school/module').then(module => module.SchoolLandingModule),
    canActivate: [SetSchoolAdminRoleGuard],
  },
  {
    path: 'application',
    loadChildren: () =>
      import('../../../landing/+application/module').then(module => module.ApplicationFormLandingModule),
  },

  {
    path: 'subject/preview',
    loadChildren: () =>
      import('../../../landing/preview-subjects/module').then(module => module.PreviewSubjectsModule),
  },
];
