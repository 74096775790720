

<style>body { padding-top: 10px !important; }</style>
<main>
  <section>
    <div class="container">
      <h1>backend</h1>
      <div id="action-back">Действия не требуются</div>
      <h1>frontend</h1>
      <div id="action">Действия не требуются</div>
      <h1>state-api events</h1><hr>
      <div id="state-result"><div></div></div>
    </div>
  </section>
</main>

