import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PAYMENT_SDK_CONFIG, PaymentSdkConfigModel } from './models/payment-sdk-config.model';

@NgModule({
  imports: [CommonModule],
})
export class PaymentSdkModule {
  static forRoot(configFactory: () => PaymentSdkConfigModel): ModuleWithProviders<PaymentSdkModule> {
    return {
      ngModule: PaymentSdkModule,
      providers: [{ provide: PAYMENT_SDK_CONFIG, useFactory: configFactory }],
    };
  }
}
