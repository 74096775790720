<div #root class="root" [class.-mobile]="isMobile">
  <header *ngIf="hasHeader" class="header">
    <button class="close-button" (click)="close()">
      <span *ngIf="!isMobile" class="close-text">{{ 'Закрыть' }}</span>
      <lecta-icon [svgContent]="closeIcon"></lecta-icon>
    </button>
  </header>
  <section class="body">
    <ng-content></ng-content>
  </section>
</div>
