import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { NotFullyRegisteredGuard } from './not-fully-registered-guard';

@Injectable({ providedIn: 'root' })
export class NotFullyRegisteredStudentGuard extends NotFullyRegisteredGuard implements CanActivate {
  protected getPageUrlTree(): UrlTree {
    return this.router.createUrlTree(['/', 'student', 'profile']);
  }
}
