import { Injectable, NgZone } from '@angular/core';
import { returnToAngularZone } from '@lecta/core/rxjs';
import { Observable, timer, interval } from 'rxjs';
import { mapTo, publishReplay, refCount, tap, skip, take } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ITimeoutQueue {
  timer: Observable<void> | null;
}

@Injectable({ providedIn: 'root' })
export class LectaTimeoutService {
  private timeoutQueue: {
    [length: number]: ITimeoutQueue;
  } = {};

  constructor(private ngZone: NgZone) {}

  waitTimeoutOnce(): Observable<void> {
    return this.waitShortTimeout(1);
  }

  waitTimeoutTwice(): Observable<void> {
    return this.waitShortTimeout(2);
  }

  waitShortTimeout(length = 1): Observable<void> {
    if (!this.timeoutQueue[length]) {
      this.resetTimeoutQueue(length);
    }

    if (!this.timeoutQueue[length].timer) {
      // eslint-disable-next-line functional/immutable-data
      this.timeoutQueue[length].timer = this.setupShortTimer(length);
    }

    return this.timeoutQueue[length].timer!;
  }

  waitLongTimeout(durationMs: number): Observable<void> {
    if (!this.timeoutQueue[durationMs]) {
      this.resetTimeoutQueue(durationMs);
    }

    if (!this.timeoutQueue[durationMs].timer) {
      // eslint-disable-next-line functional/immutable-data
      this.timeoutQueue[durationMs].timer = timer(durationMs).pipe(
        mapTo(undefined),
        tap(() => this.onTimeoutDone(durationMs)),
        publishReplay(1),
        refCount(),
      );
    }

    return this.timeoutQueue[durationMs].timer!;
  }

  private resetTimeoutQueue(length: number): void {
    this.timeoutQueue[length] = { timer: null };
  }

  private onTimeoutDone(length: number): void {
    this.resetTimeoutQueue(length);
  }

  private setupShortTimer(length: number): Observable<void> {
    // using `new Observable()` only for running timer outside of zone
    return new Observable<void>(subscriber => {
      const subscription = this.ngZone.runOutsideAngular(() =>
        interval(0)
          .pipe(
            skip(length - 1),
            take(1),
            mapTo(undefined),
            tap(() => this.onTimeoutDone(length)),
            // ensure timer run outside of zone
            // dunno how to check it with unit tests so here will be runtime exception
            tap(() => NgZone.assertNotInAngularZone()),
          )
          .subscribe(subscriber),
      );

      return () => subscription.unsubscribe();
    }).pipe(returnToAngularZone(this.ngZone), publishReplay(1), refCount());
  }
}
