import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaTagsDirective } from './directives/meta-tags.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MetaTagsDirective],
  exports: [MetaTagsDirective],
})
export class MetaModule {}
