import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import { filter, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoutingHelpersService {
  routeChanged$: Observable<NavigationEnd>;
  navigationStart$: Observable<NavigationStart>;

  constructor(private router: Router) {
    this.routeChanged$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

    this.navigationStart$ = this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
  }

  getCurrentRouteData<TRouteData extends Object = {}>(): TRouteData {
    const snapshot = this.router.routerState.snapshot;
    let data = {} as TRouteData;

    if (!snapshot.root) {
      return data;
    }

    let currentRoute: ActivatedRouteSnapshot | null = snapshot.root;

    while (currentRoute) {
      data = { ...data, ...currentRoute.data };
      currentRoute = currentRoute.firstChild;
    }

    return data;
  }
}
