import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LectaMiscModule } from 'lecta-ui/misc';
import { LectaIconComponent } from './components/icon/lecta-icon.component';

@NgModule({
  imports: [CommonModule, LectaMiscModule],
  declarations: [LectaIconComponent],
  exports: [LectaIconComponent],
})
export class LectaIconModule {}
