<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="serviceUnavailableErrorState"
  [showReloadPageButton]="true"
  [anotherActionButton]="anotherButton"
  [image]="imgSrc"
>
  <ng-container title>Сервис лицензирования недоступен</ng-container>
  <ng-container description>
    <p class="text">К сожалению, сервис лицензирования сейчас недоступен. Пожалуйста, попробуйте снова через несколько минут</p>
  </ng-container>
</app-error-page-layout>
