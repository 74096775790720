import { Routes } from '@angular/router';
import { NotFullyRegisteredStudentGuard } from 'user/core/routing/guard/not-fully-registred-student-guard';
import { SchoolAccountActivateRedirectGuard } from 'user/core/routing/guard/school-account-activate-redirect';
import { SetStudentRoleGuard } from 'user/core/routing/guard/set-student-role';

export const studentRoutes: Routes = [
  {
    canActivate: [SetStudentRoleGuard],
    path: 'student/create-homework',
    loadChildren: () =>
      import('../../../student/+create-homework/module').then(module => module.StudentCreateHomeworkModule),
  },
  {
    canActivate: [SetStudentRoleGuard, NotFullyRegisteredStudentGuard],
    path: 'student',
    loadChildren: () =>
      import('../../../landing/+student/student-landing.module').then(module => module.StudentLandingModule),
  },
  {
    canActivate: [SetStudentRoleGuard],
    path: 'student/profile',
    loadChildren: () => import('../../../student/+cabinet/module').then(module => module.StudentCabinetModule),
  },
  {
    canActivate: [SetStudentRoleGuard],
    path: 'student',
    loadChildren: () => import('../../../student/+start/module').then(module => module.StudentStartHomeworkModule),
  },
  {
    path: 'activate-school-student-account',
    canActivate: [SetStudentRoleGuard, SchoolAccountActivateRedirectGuard],
    children: [],
  },
];
