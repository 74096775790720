import { HttpStatusCode } from '@angular/common/http';
import {
  HttpInternalServerErrorStatusState,
  HttpNotFoundStatusState,
  HttpRedirectStatusState,
  PrerenderState,
} from '../interfaces';

export const isRedirectStatusState = (obj: PrerenderState): obj is HttpRedirectStatusState => {
  return obj.httpStatus === HttpStatusCode.MovedPermanently || obj.httpStatus === HttpStatusCode.Found;
};

export const isNotFoundStatusState = (obj: PrerenderState): obj is HttpNotFoundStatusState => {
  return obj.httpStatus === HttpStatusCode.NotFound;
};

export const isInternalServerErrorStatusState = (obj: PrerenderState): obj is HttpInternalServerErrorStatusState => {
  return obj.httpStatus === HttpStatusCode.InternalServerError;
};
