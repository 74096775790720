import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SetRoleGuard } from './set-role';
import { UserRole } from 'user/core/const';

@Injectable({ providedIn: 'root' })
export class SetSchoolAdminRoleGuard extends SetRoleGuard implements CanActivate {
  getRole(): UserRole {
    return UserRole.schoolAdmin;
  }
}
