import { combineLatest, Observable, ObservableInput } from 'rxjs';
import { map } from 'rxjs/operators';

export type ObservableReturnType<T> = T extends Observable<infer R> ? R : unknown;

export type ObjectWithObservableReturnTypes<T> = {
  [P in keyof T]: ObservableReturnType<T[P]>;
};

// https://gist.github.com/Goobles/45f0b3027d31884dba0b164ee2cc50ea
export function combineToObject<TObj extends { [key: string]: ObservableInput<unknown> }>(
  streams: TObj,
): Observable<ObjectWithObservableReturnTypes<TObj>> {
  const keys = Object.keys(streams);

  return combineLatest(Object.values(streams)).pipe(
    map(resultArray => {
      const result = {};

      // eslint-disable-next-line functional/immutable-data
      // @ts-ignore
      keys.forEach((key, index) => (result[key] = resultArray[index]));

      return result;
    }),
  ) as Observable<ObjectWithObservableReturnTypes<TObj>>;
}
