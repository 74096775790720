import { Injectable } from '@angular/core';
import { CurrentUserService } from './current-user';
import { UserRole } from '../const';

@Injectable({ providedIn: 'root' })
export class UserNavigationService {
  constructor(private currentUserService: CurrentUserService) {}

  getDefaultUrl(): string {
    // treat unknown role user as a teacher;
    const role = this.currentUserService.getMainRole() || UserRole.teacher;
    return this.getDefaultUrlByRole(role);
  }

  getDefaultUrlByRole(role: UserRole): string {
    switch (role) {
      case UserRole.teacher:
        return '/teacher/profile';
      case UserRole.student:
        return '/student/profile';
      case UserRole.schoolAdmin:
        return '/school';
      default:
        throw new Error('Cannot handle unknown role');
    }
  }

  getLoginUrl(): string {
    return '/login';
  }
}
