import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createForbiddenStatusState } from '../../../meta/utils/create-state';
import imageSrc from './images/mesh.svg';

@Component({
  selector: 'app-mesh-came-back',
  templateUrl: 'mesh-came-back.html',
  styleUrls: ['mesh-came-back.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeshCameBackComponent implements OnInit {
  errorState = createForbiddenStatusState();
  image: string = imageSrc;
  title = 'Вы из Москвы?';
  description = 'Цифровые рабочие тетради «Просвещения» доступны в Библиотеке МЭШ.';
  primaryButton = {
    link: 'https://uchebnik.mos.ru/',
    description: 'Перейти в Библиотеку'
  }

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // https://github.com/angular/angular/issues/17004#issuecomment-414459149
    const params = this.activatedRoute.snapshot.queryParams;
    if(params?.mo){
      this.title = 'Вы из Московской области?';
      this.description = 'Цифровые рабочие тетради «Просвещения» доступны в Библиотеке ФГИС "Моя школа".';
    }

  }
}
