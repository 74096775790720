import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LectaMathmlDirective } from './directives/lecta-mathml.directive';
import { LECTA_MATHML_CONFIG } from './lecta-mathml.consts';
import { LectaMathmlConfig } from './lecta-mathml.interfaces';

@NgModule({
  imports: [CommonModule],
  declarations: [LectaMathmlDirective],
  exports: [LectaMathmlDirective],
})
export class LectaMathmlModule {
  static forRoot(configFactory: () => LectaMathmlConfig): ModuleWithProviders<LectaMathmlModule> {
    return {
      ngModule: LectaMathmlModule,
      providers: [{ provide: LECTA_MATHML_CONFIG, useFactory: configFactory }],
    };
  }
}
