<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="errorState"
  [image]="imgSrc"
  [anotherActionButton]="anotherButton">
  <ng-container title>Дорогие друзья!</ng-container>
  <ng-container description>
    <p class="text">К сожалению, доступ к цифровым рабочим тетрадям «Просвещения» ограничен для пользователей МЭШ. <br />
      Если вы уже успели воспользоваться какой-либо цифровой тетрадью в МЭШ в текущем учебном году, то у вас останется доступ к этой тетради до конца учебного года. В противном случае, доступ будет заблокирован (как сейчас).<br />
      Если этот материал вам выдали в качестве домашнего задания, пожалуйста, предупредите преподавателя о возникшей ошибке. Подобрать похожие обучающие материалы вы всегда можете в Библиотеке МЭШ.<br />
      Если вам понравились цифровые рабочие тетради, и вы хотели бы продолжить ими пользоваться, свяжитесь с нами по адресу <a type="email" href="mailto:hw@lecta.ru" style="color:#0B5DD8">hw&#64;lecta.ru</a>. <br /><br />
      С уважением, ваше «Просвещение»</p>
  </ng-container>
</app-error-page-layout>
