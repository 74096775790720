import { BehaviorSubject, Observable, Subject, queueScheduler } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, take, tap, observeOn } from 'rxjs/operators';
import { StoreSelector, StoreUpdateReducer, StoreUpdateParams, StoreConfig } from './interface';

interface StoreEvent<TEvents, TEvent extends keyof TEvents = keyof TEvents> {
  type: TEvent;
  data?: TEvents[TEvent];
}

// TODO: make TState optional and second param to avoid excplicit generic declaration on usage
// `new Store<typeof STATE, IStoreEvents>(STATE)` -> `new Store<IStoreEvents>(STATE)`
export class StoreReadonly<TState, TEvents = void> {
  // create events subject lazily on use
  protected events: Subject<StoreEvent<TEvents>>;
  protected state: BehaviorSubject<TState>;

  constructor(initialState: TState = {} as TState, protected config: StoreConfig = {}) {
    this.state = new BehaviorSubject<TState>(initialState);
  }

  get<T>(selector: StoreSelector<TState, T>): T;
  get(): TState;
  get<A extends keyof TState>(...path: [A]): TState[A];
  get<A extends keyof TState, B extends keyof TState[A]>(...path: [A, B]): TState[A][B];
  get<A extends keyof TState, B extends keyof TState[A], C extends keyof TState[A][B]>(
    ...path: [A, B, C]
  ): TState[A][B][C];
  get<A extends keyof TState, B extends keyof TState[A], C extends keyof TState[A][B], D extends keyof TState[A][B][C]>(
    ...path: [A, B, C, D]
  ): TState[A][B][C][D];
  get<
    A extends keyof TState,
    B extends keyof TState[A],
    C extends keyof TState[A][B],
    D extends keyof TState[A][B][C],
    E extends keyof TState[A][B][C][D],
  >(...path: [A, B, C, D, E]): TState[A][B][C][D][E];
  get(pathOrSelector?: string | number | StoreSelector<TState, unknown>, ...path: (string | number)[]): unknown {
    let value = this.state.getValue();

    if (typeof pathOrSelector === 'function') {
      return pathOrSelector(value);
    }

    if (!pathOrSelector) {
      return value;
    }

    // @ts-ignore
    value = value[pathOrSelector];

    for (const pathField of path) {
      if (value === undefined || value === null) {
        break;
      }

      // @ts-ignore
      value = value[pathField];
    }

    return value;
  }

  on<TEvent extends keyof TEvents>(type: TEvent): Observable<TEvents[TEvent]> {
    this.ensureEventsSubjectExists();

    return this.events.asObservable().pipe(
      filter((event): event is StoreEvent<TEvents, TEvent> => event.type === type),
      map(event => event.data!),
    );
  }

  select<T>(selector: StoreSelector<TState, T>): Observable<T>;
  select(): Observable<TState>;
  select<A extends keyof TState>(...path: [A]): Observable<TState[A]>;
  select<A extends keyof TState, B extends keyof TState[A]>(...path: [A, B]): Observable<TState[A][B]>;
  select<A extends keyof TState, B extends keyof TState[A], C extends keyof TState[A][B]>(
    ...path: [A, B, C]
  ): Observable<TState[A][B][C]>;
  select<
    A extends keyof TState,
    B extends keyof TState[A],
    C extends keyof TState[A][B],
    D extends keyof TState[A][B][C],
  >(...path: [A, B, C, D]): Observable<TState[A][B][C][D]>;
  select<
    A extends keyof TState,
    B extends keyof TState[A],
    C extends keyof TState[A][B],
    D extends keyof TState[A][B][C],
    E extends keyof TState[A][B][C][D],
  >(...path: [A, B, C, D, E]): Observable<TState[A][B][C][D][E]>;
  select(
    pathOrSelector?: string | number | StoreSelector<TState, unknown>,
    ...path: (string | number)[]
  ): Observable<unknown> {
    let data$: Observable<unknown> = this.state.asObservable();

    if (typeof pathOrSelector === 'function') {
      data$ = data$.pipe(map(state => pathOrSelector(state as TState)));
    } else if (pathOrSelector) {
      data$ = data$.pipe(pluck(...([pathOrSelector, ...path] as string[])));
    }

    return data$.pipe(distinctUntilChanged());
  }

  protected ensureEventsSubjectExists(): void {
    if (this.events) {
      return;
    }

    this.events = new Subject<StoreEvent<TEvents>>();
  }
}

export class Store<TState, TEvents = void> extends StoreReadonly<TState, TEvents> {
  fire<TEvent extends keyof TEvents>(type: TEvent, data?: TEvents[TEvent]): void {
    this.ensureEventsSubjectExists();

    this.events.next({ type, data });
  }

  /**
   * @deprecated use specific selects instead
   */
  getReadonly(): StoreReadonly<TState, TEvents> {
    return this;
  }

  update(reducer: StoreUpdateReducer<TState>, updateParams: StoreUpdateParams = {}): void {
    const queue = updateParams.queue !== undefined ? updateParams.queue : this.config.queueUpdate;

    this.state
      .pipe(
        take(1),
        // prevent race condition on multiple sequential updates, see tests
        // https://github.com/ngrx/platform/issues/2572#issuecomment-641418960
        // about wrong state right after second update (why we use param and no queue by default)
        // https://github.com/ngrx/platform/issues/2322
        queue ? observeOn(queueScheduler) : tap(),
      )
      .subscribe(state => {
        const newState = reducer(state);
        this.state.next(newState);
      });
  }

  // TODO: rename to `setField`
  updateField<T extends keyof TState>(fieldName: T, filedValue: TState[T], updateParams: StoreUpdateParams = {}): void {
    this.update(state => ({ ...state, [fieldName]: filedValue }), updateParams);
  }

  // TODO: rename to `setFields`
  updateFields(fields: Partial<TState>, updateParams: StoreUpdateParams = {}): void {
    this.update(state => ({ ...state, ...fields }), updateParams);
  }
}
