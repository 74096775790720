import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { LectaMathmlService } from '../services/lecta-mathml.service';
import { timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[lectaMathml]',
})
@DecorateUntilDestroy()
export class LectaMathmlDirective implements AfterViewInit, OnDestroy {
  constructor(private mathmlService: LectaMathmlService, private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const innerHTML = this.elementRef.nativeElement.innerHTML;

    // контент добавляется в ckeditor из vimbox.content через copy-paste разметки,
    // в этот момент ckeditor превращает его math-semantics-annotation-tex формат,
    // который не рендрится в ckeditor и рендрит оишбку "Math output error" в mathjax
    // вырезаем этот тег
    // eslint-disable-next-line functional/immutable-data
    this.elementRef.nativeElement.innerHTML = innerHTML.replace(
      /<math xmlns="http:\/\/www\.w3\.org\/1998\/Math\/MathML"><semantics><annotation encoding="application\/x-tex">(.*?)<\/annotation><\/semantics><\/math>/gmu,
      ``,
    );

    // таймер позволяет запустить рендер MathML после отображения содержимого директивы
    // необходимо для корретного расчета размера отрендеренных MathML элментов, так как рендерер пытается
    // адаптировать их размер под оккружающий текст
    timer(0)
      .pipe(switchMapTo(this.mathmlService.render([this.elementRef.nativeElement])), takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.elementRef.nativeElement) {
      this.mathmlService.clear([this.elementRef.nativeElement]);
    }
  }
}
