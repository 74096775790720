import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { CmsAuthService } from '../cms-auth/service/cms-auth-service';
import { catchError } from 'rxjs/operators';

const ANONYMOUS_URLS = [new RegExp('^/api/v1/login')];

@Injectable({ providedIn: 'root' })
export class CmsAuthInterceptor implements HttpInterceptor {
  constructor(private authService: CmsAuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (!request.url.includes(environment.endpoints.cmsApi)) {
      return next.handle(request);
    }

    const token = this.authService.getCurrentToken();

    if (!token && this.isAuthRequired(request.url)) {
      throw new Error('Try to send request without authorization token for:' + request.url);
    }

    if (!token) {
      return next.handle(request);
    }

    const modifiedRequest = request.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    });

    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse | Error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logoutAndRedirectToLogin();
        }

        throw error;
      })
    );
  }

  private isAuthRequired(url: string): boolean {
    if (!url.startsWith(environment.endpoints.api)) {
      return false;
    }
    const path = url.replace(environment.endpoints.api, '');

    return !ANONYMOUS_URLS.some(pattern => path.match(pattern));
  }
}
