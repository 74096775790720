import { Injectable, OnDestroy, Optional } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import {
  LectaRootSchedulerConfig,
  LectaScheduler,
  LectaSchedulerConfig,
  Scheduled,
  ScheduledInferredData,
  SchedulingSettings,
} from './interfaces';
import { SchedulerQueueInstance } from './scheduler-queue-instance';

const ROOT_QUEUE_KEY = 'root';

@Injectable()
export class LectaSchedulerService implements LectaScheduler, OnDestroy {
  private queues: Map<string, SchedulerQueueInstance> = new Map();

  constructor(@Optional() schedulerConfig?: LectaRootSchedulerConfig) {
    this.createOrGetQueue(ROOT_QUEUE_KEY, schedulerConfig);
  }

  createOrGetQueue(queueKey: string, schedulerConfig?: LectaSchedulerConfig): LectaScheduler {
    const queue = this.getQueue(queueKey);
    if (!queue) {
      const createdQueue = this.createQueue(schedulerConfig);
      this.queues.set(queueKey, createdQueue);
      return createdQueue;
    }
    return queue;
  }

  createNewAndReplaceExistingQueue(queueKey: string, schedulerConfig?: LectaSchedulerConfig): LectaScheduler {
    const createdQueue = this.createQueue(schedulerConfig);
    this.queues.set(queueKey, createdQueue);
    return createdQueue;
  }

  schedule<V, S = ScheduledInferredData<V>>(
    scheduleFactory: Scheduled<S>,
    settings: SchedulingSettings = {},
  ): Observable<S> {
    return this.getRootQueue().schedule(scheduleFactory, settings);
  }

  terminate(queueKey = ROOT_QUEUE_KEY): void {
    this.getQueue(queueKey)?.terminate();
  }

  skip(queueKey = ROOT_QUEUE_KEY): Observable<never> {
    return this.getQueue(queueKey)?.skip() ?? EMPTY;
  }

  destroy(queueKey = ROOT_QUEUE_KEY): void {
    this.getQueue(queueKey)?.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private getQueue(queueKey: string): SchedulerQueueInstance | undefined {
    return this.queues.get(queueKey);
  }

  private createQueue(schedulerConfig: LectaSchedulerConfig | undefined): SchedulerQueueInstance {
    return new SchedulerQueueInstance(this.getRootQueue(), schedulerConfig);
  }

  private getRootQueue(): SchedulerQueueInstance {
    return this.getQueue(ROOT_QUEUE_KEY)!;
  }
}
