import { Injectable } from '@angular/core';
import { LectaApiService } from 'core/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtmsAndRefferer } from 'tracking/service/utm';
import { SkysmartTrackingEvent } from '../interfaces/tracking.interface';

@Injectable({ providedIn: 'root' })
export class AnalyticsApi {
  private endpoints = [environment.endpoints.api];

  constructor(private apiService: LectaApiService) {}

  saveUtm(utmsAndRefferer: UtmsAndRefferer): Observable<void> {
    return this.apiService.post(this.endpoints, '/api/v1/analytics/save-utm', {
      params: utmsAndRefferer,
      retry: 1,
    });
  }

  trackEvent(event: SkysmartTrackingEvent): Observable<void> {
    return this.apiService.post(this.endpoints, '/api/v1/analytics/track-event', {
      params: event,
      retry: 1,
    });
  }
}
