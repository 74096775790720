export function joinObjectTruthyValues<T extends Object>(source: T, ...keys: (keyof T)[]): string {
  const fieldKeys = keys.length ? keys : (Object.keys(source) as (keyof T)[]);
  return (
    fieldKeys
      // Symbols can't be converted to string implicitly
      // It provide no sense any way so just skip it to prevent TypeError
      .filter(key => source[key] && typeof source[key] !== 'symbol')
      .map(key => source[key])
      .join(' ') || ''
  );
}
