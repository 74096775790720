import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreakpointService } from 'lecta-ui/layout';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-main-container',
  templateUrl: './lecta-main-container.component.html',
  styleUrls: ['./lecta-main-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaMainContainerComponent {
  isMobile: boolean;

  constructor(private breakpointService: BreakpointService) {this.isMobile = this.breakpointService.isSmall();}
}
