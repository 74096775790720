<app-ui-popup>
  <ng-container popupTitle>{{ title }}</ng-container>

  <ng-container popupBody>
    <div class="body" [innerHTML]="message"></div>
  </ng-container>

  <div popupFooterCustomContent>
    <lecta-button
      *ngIf="behaviour === 'confirm'"
      class="footer-button"
      type="primary-blue"
      size="s"
      (click)="confirm()"
    >
      {{ confirmText || 'Подтвердить' }}
    </lecta-button>
    <lecta-button
      *ngIf="behaviour === 'confirm'"
      class="footer-button"
      type="secondary-blue"
      size="s"
      (click)="cancel()"
    >
      {{ cancelText || 'Отменить' }}
    </lecta-button>

    <lecta-button
      *ngIf="behaviour === 'alert'"
      class="footer-button"
      type="primary-blue"
      size="s"
      (click)="confirm()"
    >
      {{ cancelText || 'Ok' }}
    </lecta-button>
  </div>
</app-ui-popup>
