import { Routes } from '@angular/router';
import { LogoutComponent } from '../../component/logout/logout';

export const userRoutes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'profile',
    loadChildren: () => import('../../../user/+profile/module').then(module => module.UserProfileModule),
  },
  {
    path: 'user-agreement',
    loadChildren: () => import('../../../agreement/+user-agreement/user-agreement.module').then(module => module.UserAgreementModule),

  }
];
