import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LectaRootSchedulerConfig, LectaSchedulerConfig } from './interfaces';
import { LectaSchedulerService } from './scheduler.service';

@NgModule({
  imports: [CommonModule],
})
export class LectaSchedulerModule {
  static forRoot(schedulerConfig?: LectaSchedulerConfig): ModuleWithProviders<LectaSchedulerModule> {
    return {
      ngModule: LectaSchedulerModule,
      providers: [{ provide: LectaRootSchedulerConfig, useValue: schedulerConfig }, LectaSchedulerService],
    };
  }
}
