import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { ConfirmData } from './lecta-confirm-popup.interface';
import { LectaDialogInstance } from 'lecta-ui/modal-dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-confirm-popup',
  templateUrl: './lecta-confirm-popup.component.html',
  styleUrls: ['./lecta-confirm-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaConfirmPopupComponent implements OnInit, ConfirmData {
  @Input() title: string;
  @Input() message?: string;
  @Input() confirmLabel?: string;
  @Input() cancelLabel?: string;

  constructor(
    @Inject(LectaDialogInstance)
    private dialogInstance: LectaDialogInstance<void, ConfirmData>,
  ) {}

  ngOnInit(): void {
    this.title = this.dialogInstance.data.title;
    this.message = this.dialogInstance.data.message;
    this.confirmLabel = this.dialogInstance.data.confirmLabel;
    this.cancelLabel = this.dialogInstance.data.cancelLabel;
  }

  confirm(): void {
    this.dialogInstance.close();
  }

  reject(): void {
    this.dialogInstance.close({ rejected: true });
  }
}
