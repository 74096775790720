import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserNavigationService } from 'user/core/service/navigation';
import { extractExternalProviderQueryParam } from 'integrations/prosv/helpers';
import { ProsvIdAuthService } from 'integrations/prosv/service/prosv-id-auth.service';
import { AuthService } from 'user/core/service/auth';
import { ProsvIdAuthState } from '../../prosv/interface';

@Injectable({ providedIn: 'root' })
export class VirtualClassStartAuthTeacherProviderGuard implements CanActivate {
  constructor(
    protected userNavigationService: UserNavigationService,
    private prosvIdAuthService: ProsvIdAuthService,
    private authService: AuthService,
  ) {}

  canActivate({ queryParams }: ActivatedRouteSnapshot): boolean {
    const provider = extractExternalProviderQueryParam(queryParams);

    if (provider && provider === 'vk') {
      this.authService.logout();
      const params = { provider, role: 'teacher' } as ProsvIdAuthState;

      this.prosvIdAuthService.openProsvIdAuth({ ...params });

      return false;
    } else {
      return true;
    }
  }
}
