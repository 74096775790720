export const KEY_CODE = {
  backspace: 8,
  enter: 13,
  escape: 27,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
  delete: 46,
};
