import { Inject, Injectable } from '@angular/core';
import { LectaPaymentServiceSDK } from 'payment-sdk';
import {
  PAYMENT_SDK_CONFIG,
  PaymentSdkConfigModel,
  PaymentSdkConstructorParams,
} from '../models/payment-sdk-config.model';
import { from, Observable } from 'rxjs';
import { PaymentSdkGetPaymentLinkResponse } from '@lecta/libs/payment-sdk/src/models/payment-sdk-api.model';

@Injectable({ providedIn: 'root' })
export class PaymentSdkService {
  readonly config: PaymentSdkConfigModel;
  private paymentSdk: LectaPaymentServiceSDK;

  constructor(@Inject(PAYMENT_SDK_CONFIG) sdkConfig: PaymentSdkConfigModel) {
    this.config = sdkConfig;
  }

  init(userId = null): void {
    this.paymentSdk = new LectaPaymentServiceSDK({ userId, ...this.config } as PaymentSdkConstructorParams);
  }

  updateUserId(userId: string | null): void {
    this.paymentSdk.updateUserId(userId);
  }

  getUserId(): string | null {
    return this.paymentSdk.userId;
  }

  getPaymentLink(nomenclatures: string[]): Observable<PaymentSdkGetPaymentLinkResponse> {
    return from(this.paymentSdk.getInstantPurchasePaymentLink(...nomenclatures));
  }
}
