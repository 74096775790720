import { getDocument } from './get-document.utility';
import { getDeviceOrientation } from './get-device-orientation.utility';

export function getDeviceWidth(): number {
  const document = getDocument();

  if (!document) {
    // treat as 720p notebook by default
    return 1360;
  }

  const deviceOrientation = getDeviceOrientation();

  return deviceOrientation === 'portrait'
    ? Math.max(document.documentElement!.clientWidth, window.innerWidth || 0)
    : Math.max(document.documentElement!.clientHeight, window.innerHeight || 0);
}
