import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IPopupCloseEvent } from './interface';

export class PopupInstance<TValue, TData> {
  private closed = new Subject<IPopupCloseEvent<TValue>>();
  private shouldClose = new Subject<void>();
  private value: TValue | undefined;
  private rejected: boolean;

  constructor(private overlayRef: OverlayRef, readonly data: TData) {
    this.overlayRef.detachments().subscribe(() => {
      this.closed.next({
        data: this.value,
        rejected: this.rejected,
      });
      this.closed.complete();
      this.overlayRef.dispose();
    });
  }

  close(options: { value?: TValue; rejected: boolean } = { rejected: false }): void {
    this.value = options.value;
    this.rejected = options.rejected;
    this.shouldClose.next();
    this.shouldClose.complete();
  }

  detach(): void {
    this.overlayRef.detach();
  }

  onShouldClose(): Observable<void> {
    return this.shouldClose.asObservable();
  }

  onClose(options: { skipRejected: boolean } = { skipRejected: false }): Observable<IPopupCloseEvent<TValue>> {
    if (options.skipRejected) {
      return this.closed.pipe(filter(event => !event.rejected));
    } else {
      return this.closed.asObservable();
    }
  }
}
