import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LectaConfirmPopupComponent } from './lecta-confirm-popup.component';
import { LectaModalDialogModule } from 'lecta-ui/modal-dialog';
import { LectaButtonModule } from 'lecta-ui/button';

@NgModule({
  imports: [CommonModule, LectaModalDialogModule, LectaButtonModule],
  declarations: [LectaConfirmPopupComponent],
})
export class LectaConfirmPopupModule {}
