import { Injectable } from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';

import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CurrentUserService} from "../../service/current-user";
import {UserAuthStateService} from "../../service/auth-state";

@Injectable({ providedIn: 'root' })
export class NotFullyRegisteredTeacherGuard implements CanActivate {
  constructor(   protected router: Router,
                 protected currentUserService: CurrentUserService,
                 private userAuthStateService: UserAuthStateService,) {
  }
  protected getPageUrlTree(): UrlTree {
    return this.router.createUrlTree(['/teacher/profile']);
  }

  canActivate(): UrlTree |boolean | Observable<UrlTree | boolean> {
    const user = this.currentUserService.getUser();
    if (!user) {
      return this.router.createUrlTree(['/login']);
    }

    return this.currentUserService.getConfig().pipe(
      map(userConfig => {
        if (!this.userAuthStateService.isFullyRegistered(userConfig)) {
          return true;
        } else {
          return this.getPageUrlTree();
        }
      }),
    );
  }
}
