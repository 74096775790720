import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { FullyRegisteredGuard } from './fully-reqistered-guard';

@Injectable({ providedIn: 'root' })
export class FullyRegisteredTeacherGuard extends FullyRegisteredGuard implements CanActivate {
  protected getNotRegisteredUserUrlTree(): UrlTree {
    return this.router.createUrlTree(['/login']);
  }
  protected getPageUrlTree(): UrlTree {
    return this.router.createUrlTree(['/login']);
  }
}
