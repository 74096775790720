<div class="root">
  <div class="header">
    <div class="header-content">
      <div class="logo">
        <ng-content select="[headerLogo]"></ng-content>
      </div>
      <div class="middle">
        <ng-content select="[headerMiddle]"></ng-content>
      </div>
      <div class="rest">
        <ng-content select="[headerRest]"></ng-content>
      </div>
    </div>
    <div class="header-progress">
      <ng-content select="[headerProgress]"></ng-content>
    </div>
  </div>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="footer">
    <div *ngIf="!isMobile" class="footer-progress">
      <ng-content select="[footerProgress]"></ng-content>
    </div>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
