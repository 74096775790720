import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeworkLayoutComponent } from './components/homework-layout/homework-layout.component';
import { ThreeColumnsComponent } from './components/three-columns/three-columns';

@NgModule({
  imports: [CommonModule],
  declarations: [HomeworkLayoutComponent, ThreeColumnsComponent],
  exports: [HomeworkLayoutComponent, ThreeColumnsComponent],
})
export class HomeworkLayoutModule {}
