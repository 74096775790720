import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import _cloneDeep from 'lodash/cloneDeep';
import { Observable, throwError } from 'rxjs';
import { LectaApiCacheService } from './cache';
import { delay, shareReplay, map, catchError } from 'rxjs/operators';
import { LECTA_API_CACHE_CONTEXT_TOKEN } from '../lecta-api.const';

const ALLOWED_METHODS_FOR_CACHING = ['GET', 'POST'];

@Injectable({ providedIn: 'root' })
export class LectaCacheInterceptor implements HttpInterceptor {
  constructor(private apiCacheService: LectaApiCacheService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!ALLOWED_METHODS_FOR_CACHING.includes(req.method) || !req.context.get(LECTA_API_CACHE_CONTEXT_TOKEN)) {
      return next.handle(req);
    }

    const cacheKey = LectaCacheInterceptor.getCacheKey(req);
    const cachedRequest = this.apiCacheService.get<Observable<HttpResponse<unknown>>>(cacheKey);
    if (cachedRequest) {
      return cachedRequest;
    }

    const originalRequest = next.handle(req).pipe(
      shareReplay({ refCount: false, bufferSize: 1 }),
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          // NOTE: remove `cloneDeep` usage when there are no mutations on all cached requests data
          return event.clone({ body: _cloneDeep(event.body) });
        }
        return event;
      }),
    );
    const sharedRequest = originalRequest.pipe(
      // NOTE: delay is required to avoid some race conditions (with store usage e.x.)
      delay(0),
    );
    this.apiCacheService.set(cacheKey, sharedRequest);

    return originalRequest.pipe(
      catchError((error: unknown) => {
        this.apiCacheService.remove(cacheKey);
        return throwError(error);
      }),
    );
  }

  private static getCacheKey(req: HttpRequest<unknown>): string {
    return req.urlWithParams;
  }
}
