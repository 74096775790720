import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LectaModalDialogComponent } from './components/modal-dialog/lecta-modal-dialog.component';
import { LectaIconModule } from 'lecta-ui/icon';
import { LectaDialogViewComponent } from 'lecta-ui/modal-dialog/components/dialog-view/lecta-dialog-view.component';
import { LectaDialogOverlayComponent } from 'lecta-ui/modal-dialog/components/dialog-overlay/lecta-dialog-overlay.component';
import { LectaDialogComponent } from 'lecta-ui/modal-dialog/components/dialog/lecta-dialog.component';

const COMPONENTS = [
  LectaModalDialogComponent,
  LectaDialogViewComponent,
  LectaDialogOverlayComponent,
  LectaDialogComponent,
];

@NgModule({
  imports: [CommonModule, LectaIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class LectaModalDialogModule {}
