import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopupComponent } from 'ui/popup/component/popup/popup';
import { NativeScrollModule } from 'ui/native-scroll/module';
import { BlackOverlayComponent } from './component/black-overlay-popup/black-overlay';
import { SimplePopupComponent } from './component/simple-popup/simple-popup';
import { PurePopupComponent } from './component/pure-popup/pure-popup';
import { LectaButtonModule } from 'lecta-ui/button';
import { LectaIconModule } from 'lecta-ui/icon';
import { PopupService } from '@lecta/ui/popup/service/popup';

/**
 * @deprecated use DialogModule instead
 */
@NgModule({
  imports: [CommonModule, NativeScrollModule, LectaButtonModule, LectaIconModule],
  providers: [PopupService],
  declarations: [PopupComponent, SimplePopupComponent, BlackOverlayComponent, PurePopupComponent],
  exports: [PopupComponent, SimplePopupComponent, BlackOverlayComponent, PurePopupComponent],
})
export class PopupModule {}
