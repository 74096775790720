import {ErrorHandler, Injectable} from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from 'environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: Error): void {
    if (error.message.includes("ChunkLoadError")) {
      Sentry.captureException("RELOADED "+error.message);
      if(!environment.app.production){
        console.error(error)
      }
      if(sessionStorage.getItem('reloaded') === 'true'){
        sessionStorage.setItem('reloaded', 'true');
        window.location.reload();
      }
    }else{
      const exception = error?.message ?? error;
      if(!environment.app.production){
        console.error(error)
      }
      Sentry.captureException(exception);
    }
  }
}
