import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './component/base-layout/base-layout';
import { LogoComponent } from './component/logo/logo';
import { CommonModule } from '@angular/common';
import { FooterLogoComponent } from './component/footer-logo/footer-logo.component';
import { HeaderLogoComponent } from './component/header-logo/header-logo.component';
import { AuthMenuComponent } from './component/auth-menu/auth-menu.component';
import { LectaButtonModule } from '../lecta-ui/button';
import { LectaIconModule } from '../lecta-ui/icon';
import { PopoverModule } from '@lecta/ui/popover/module';
import { CorePageLoaderService } from '@lecta/core/layout';

@NgModule({
  imports: [RouterModule, CommonModule, LectaButtonModule, PopoverModule, LectaIconModule],
  declarations: [LogoComponent, BaseLayoutComponent, FooterLogoComponent, HeaderLogoComponent, AuthMenuComponent],
  exports: [LogoComponent, BaseLayoutComponent, FooterLogoComponent, HeaderLogoComponent, AuthMenuComponent],
})
export class AppLayoutModule {
  constructor(private vimCorePageLoaderService: CorePageLoaderService) {
    this.vimCorePageLoaderService.start();
  }
}
