import { Routes } from '@angular/router';
import { SetStudentRoleGuard } from 'user/core/routing/guard/set-student-role';
import { SetTeacherRoleGuard } from 'user/core/routing/guard/set-teacher-role';
import { ExternalAuthProviderGuard } from 'integrations/prosv/guard/external-auth-provider-guard';
import { FullyRegisteredTeacherGuard } from 'user/core/routing/guard/fully-registered-teacher-guard';
import { SilentAuthGuard } from '../../../integrations/prosv/guard/silent-auth.guard';
import {SetTeacherOrStudentRoleGuard} from "../../../user/core/routing/guard/set-teacher-or-student-role";

export const homeworkRoutes: Routes = [
  {
    path: 'lesson/homework',
    canActivate: [SetStudentRoleGuard],
    loadChildren: () => import('../../../lesson/homework/+page/module').then(module => module.HomeworkLessonModule),
  },

  {
    path: 'homework/workbook/:workbookId/step/:stepUuid/preview',
    canActivate: [SetTeacherOrStudentRoleGuard],
    loadChildren: () =>
      import('../../../homework/+preview-page/module').then(module => module.HomeworkPreviewPageModule),
  },
  {
    path: 'homework/new',
    canActivate: [SilentAuthGuard, ExternalAuthProviderGuard, SetTeacherRoleGuard, FullyRegisteredTeacherGuard],
    loadChildren: () =>
      import('../../../teacher/+create-homework/module').then(module => module.TeacherCreateHomeworkModule),
  },

];
