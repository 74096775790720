import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
// import { Location } from '@angular/common';
import { MetaService } from 'meta/services/meta.service';
import { createNotFoundStatusState } from '../../../meta/utils/create-state';
// import { ORIGINAL_URL_QUERY_PARAM } from '@lecta/core/routing';
import mashImgSrc from "./cry.png";

@Component({
  selector: 'app-mash-unreachable',
  templateUrl: 'mash-unreachable.html',
  styleUrls: ['mash-unreachable.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MashUnreachableComponent implements OnInit {
  errorState = createNotFoundStatusState();
  imgSrc = mashImgSrc;
  anotherButton = {
    link: 'mash',
    description: 'В библиотеку МЭШ'
  }

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    // https://github.com/angular/angular/issues/17004#issuecomment-414459149
    // const originalUrl = this.activatedRoute.snapshot.queryParams[ORIGINAL_URL_QUERY_PARAM];
    //
    // if (originalUrl) {
    //   this.location.replaceState(originalUrl);
    // }

    this.metaService.setTitle('Страница не найдена');
    this.metaService.update([{ name: 'description', content: 'цифровые рабочие тетради «Просвещения» больше недоступны пользователям МЭШ' }]);
  }
}
