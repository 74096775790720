import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import imageSrc from './images/404.png';
import {Router} from "@angular/router";

interface AnotherAction{
  link: string;
  description: string;
}

@Component({
  selector: 'app-error-page-layout',
  templateUrl: 'error-page-layout.html',
  styleUrls: ['error-page-layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppErrorPageLayoutComponent {
  @Input() showReloadPageButton = false;
  @Input() anotherActionButton: AnotherAction | null = null;
  @Input() primaryActionButton: AnotherAction | null = null;
  @Input() isMobile: boolean;
  @Input() image: string = imageSrc;

  constructor(private router: Router) {
  }

  reload(): void {
    window.history.go(-1);
  }

  anotherAction(): void | boolean {
    if (this.anotherActionButton?.link !== 'mash') {
      this.router.navigate([this.anotherActionButton?.link]);
      return;
    } else {
      window.history.go(-1);
      return false;
    }
  }

  primaryAction(): void{
    window.location.href = this.primaryActionButton?.link as string;
  }
}
