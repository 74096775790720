import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { LectaScrollableDirective } from './directives/scrollable';
import { LectaFitTextDirective } from './directives/fit-text.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SafeHtmlPipe, SafeStylePipe, LectaScrollableDirective, LectaFitTextDirective],
  exports: [SafeHtmlPipe, SafeStylePipe, LectaScrollableDirective, LectaFitTextDirective],
})
export class MiscModule {}
