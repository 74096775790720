import { Injectable, Injector, Type, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { PopupInstance } from '../popup-instance';
import { SimplePopupComponent } from '../component/simple-popup/simple-popup';
import { IPopupConfig, IPopupCloseEvent, TSimplePopupInputs } from '../interface';
import { Observable } from 'rxjs';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';

@DecorateUntilDestroy()
@Injectable()
export class PopupService implements OnDestroy {
  private openedPopups: PopupInstance<unknown, unknown>[] = [];

  constructor(private overlay: Overlay, private injector: Injector) {}

  ngOnDestroy(): void {
    this.detachOpenPopups();
  }

  detachOpenPopups(): void {
    this.openedPopups.forEach(popupInstance => popupInstance.detach());
  }

  open<TComponentData = void, TValue = void>(
    component: Type<unknown>,
    componentData?: TComponentData,
    config: IPopupConfig = { preventScrollBlock: false },
  ): PopupInstance<TValue, TComponentData> {
    const overlayRef = this.createOverlay(config.preventScrollBlock);

    const popupInstance = this.createPopup<TComponentData, TValue>(overlayRef, component, componentData!);

    this.openedPopups = [...this.openedPopups, popupInstance] as PopupInstance<unknown, unknown>[];

    popupInstance
      .onClose()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => (this.openedPopups = this.openedPopups.filter(openedPopup => openedPopup !== popupInstance)));

    return popupInstance;
  }

  openConfirmPopup(
    inputs: TSimplePopupInputs,
    skipRejectedEvent = true,
    customComponent?: Type<unknown>,
  ): Observable<IPopupCloseEvent<void>> {
    return this.openSimplePopup(inputs, skipRejectedEvent, customComponent);
  }

  openAlertPopup(
    inputs: TSimplePopupInputs,
    skipRejectedEvent = true,
    customComponent?: Type<unknown>,
  ): Observable<IPopupCloseEvent<void>> {
    return this.openSimplePopup(
      {
        ...inputs,
        behaviour: 'alert',
      },
      skipRejectedEvent,
      customComponent,
    );
  }

  private openSimplePopup(
    inputs: TSimplePopupInputs,
    skipRejectedEvent = true,
    customComponent?: Type<unknown>,
  ): Observable<IPopupCloseEvent<void>> {
    const popupComponent = customComponent ? customComponent : SimplePopupComponent;
    return this.open(popupComponent, inputs).onClose({ skipRejected: skipRejectedEvent });
  }

  private createOverlay(preventScrollBlock: boolean): OverlayRef {
    const scrollingStrategy = preventScrollBlock
      ? this.overlay.scrollStrategies.noop()
      : this.overlay.scrollStrategies.block();

    return this.overlay.create({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: scrollingStrategy,
    });
  }

  private createPopup<TComponentData, TValue>(
    overlayRef: OverlayRef,
    component: Type<unknown>,
    componentData: TComponentData,
  ): PopupInstance<TValue, TComponentData> {
    const popupInstance = new PopupInstance<TValue, TComponentData>(overlayRef, componentData);

    const injector = new PortalInjector(this.injector, new WeakMap([[PopupInstance, popupInstance]]));

    overlayRef.attach(new ComponentPortal(component, undefined, injector));

    return popupInstance;
  }
}
