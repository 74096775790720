import { Injectable } from '@angular/core';
import { LectaApiService } from 'core/api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LectaFileAmazonS3Api {
  constructor(private apiService: LectaApiService) {}

  upload(url: string, formData: FormData): Observable<void> {
    return this.apiService.post<void>([''], url, {
      formParams: formData,
      responseType: 'text',
      longRequest: true,
    });
  }
}
