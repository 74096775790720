import { ElementRef, NgZone, AfterViewInit, Component, ChangeDetectionStrategy } from '@angular/core';

const CLASSES = {
  root: 'native-scroll-root',
  hide: 'native-scroll-hide',
};

// NOTE: Should be a component for proper styles import
@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: '[appNativeScroll]',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['native-scroll.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeScrollComponent implements AfterViewInit {
  constructor(private elementRef: ElementRef<HTMLElement>, private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      window.setTimeout(() => {
        const element = this.elementRef.nativeElement;

        element.classList.add(CLASSES.root);

        // this block of code is for browser to force element repainting
        // the simplest working solution http://stackoverflow.com/a/24753578
        element.classList.add(CLASSES.hide);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
        // @ts-ignore: required to force layout recalculation
        let offsetHeight = element.offsetHeight;
        element.classList.remove(CLASSES.hide);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offsetHeight = element.offsetHeight;
      });
    });
  }
}
