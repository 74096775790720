import { UserRoleTitle } from 'user/core/interface';

export type ExternalAuthProvider = 'mo' | 'eis' | 'dnevnik' | 'vk' | 'vs';

export interface ProsvIdAuthData {
  url: string;
}

export interface ProsvIdAuthState {
  homeworkHash?: string;
  homeworkClass?: number;
  litera?: string;
  class?: number;
  schoolName?: string;
  provider?: string;
  role?: UserRoleTitle | null;
  redirectUrl?: string | null;
}

export interface ProsvIdAuthenticationSuccessResponse {
  token: string;
  isRegistered: boolean;
  name: string;
  role: UserRoleTitle | null;
  forceToChooseRole: boolean;
  forceToChooseClass: boolean;
  stateParams: ProsvIdAuthState;
}

export enum ProsvIdAuthenticationFailCode {
  parentNotAllowed = 'parent_not_allowed',
}

export interface ProsvIdAuthorizationResponse {
  token: string;
}
