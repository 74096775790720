import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LectaApiCacheService {
  private cache: { [key: string]: unknown } = {};

  get<T>(key: string): T | undefined {
    return this.cache[key] as T | undefined;
  }

  remove(key: string): void {
    // eslint-disable-next-line functional/immutable-data
    delete this.cache[key];
  }

  set<T>(key: string, value: T): void {
    this.cache[key] = value;
  }

  removeAll(): void {
    this.cache = {};
  }
}
