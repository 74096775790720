import { Injectable } from '@angular/core';
import { AnimationBuilder, style, animate, AnimationPlayer } from '@angular/animations';
import { take, finalize, mapTo, switchMap } from 'rxjs/operators';
import { timer, Observable, of } from 'rxjs';
import { RoutingHelpersService } from '@lecta/core/routing';
import { isTestingBrowser } from '@lecta/core/device';

const BODY_LOADER_CLASS = 'vim-il-body-loader';
const INITIAL_LOADER_SELECTOR = 'body > .vim-il';
const ELEMENT_FADE_TIME_MS = 300;

@Injectable({ providedIn: 'root' })
export class CorePageLoaderService {
  constructor(private animationBuilder: AnimationBuilder, private routingHelpersService: RoutingHelpersService) {}

  start(): void {
    this.routingHelpersService.routeChanged$
      .pipe(
        take(1),
        switchMap(() => this.removeInitialLoader()),
      )
      .subscribe();
  }

  private removeInitialLoader(): Observable<void> {
    document.body.classList.remove(BODY_LOADER_CLASS);

    const initialLoaderElement = document.querySelector(INITIAL_LOADER_SELECTOR) as HTMLElement;

    if (!initialLoaderElement) {
      return of();
    }

    const loaderFadeOutAnimationPlayer = this.buildFadeOutAnimation(initialLoaderElement, ELEMENT_FADE_TIME_MS);

    // `Cannot read property 'then' of undefined` error in testing browser
    if (!isTestingBrowser()) {
      loaderFadeOutAnimationPlayer.play();
    }

    // We dont use onDone hook, since it's buggy and doesn't invoke done callback on testing servers
    return timer(ELEMENT_FADE_TIME_MS).pipe(
      finalize(() => {
        if (initialLoaderElement.parentNode) {
          initialLoaderElement.parentNode.removeChild(initialLoaderElement);
        }
      }),
      mapTo(undefined),
    );
  }

  private buildFadeOutAnimation(element: HTMLElement, fadeTime: number): AnimationPlayer {
    const loaderFadeOutAnimation = this.animationBuilder.build([
      style({ opacity: 1 }),
      animate(fadeTime, style({ opacity: 0 })),
    ]);

    return loaderFadeOutAnimation.create(element);
  }
}
