import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SetRoleGuard } from './set-role';
import { UserRole } from 'user/core/const';

@Injectable({ providedIn: 'root' })
export class SetTeacherOrStudentRoleGuard extends SetRoleGuard implements CanActivate {

  getRole(): UserRole | null {
    const currentUser = this.userRegistryService.getCurrentUser();
    if(currentUser?.jwtData.roles){
      if(currentUser?.jwtData.roles.includes(UserRole.teacher)){
        return UserRole.teacher;
      }

      if(currentUser?.jwtData.roles.includes(UserRole.student)){
        return UserRole.student;
      }
    }

    return null;
  }
}
