import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { KEY_CODE } from 'core/misc';
import { DecorateUntilDestroy, returnToAngularZone, takeUntilDestroyed } from 'core/rxjs';
import { PopupInstance } from 'ui/popup/popup-instance';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type TState = 'open' | 'close';

/**
 * @deprecated use DsDialogModule's components
 */
@DecorateUntilDestroy()
@Component({
  selector: 'app-ui-popup',
  templateUrl: 'popup.html',
  styleUrls: ['./popup.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('visibility', [
      transition('* => open', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition('* => close', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class PopupComponent implements OnInit, OnDestroy {
  @Input() closeable = true;
  @Input() big = false;
  @Input() width: string;
  @Input() maxHeight: string;
  @Input() footerFlexWrap = false;
  @Input() pageMode = false;
  @Input() removePadding = false;
  @Input() backgroundColor: string;
  @Input() closeValue?: unknown;
  /**
   * Включить или выключить вертикальный скрол.
   * По умолчанию контент в попапе скролится, под скролбар резервируется место, из-за чего контент смещен на 6px влево относительно центра.
   * Если выключить скрол, то контент будет выровнен правильно.
   */
  @Input() hasScroll = true;

  @ViewChild('body') bodyRef: ElementRef<HTMLElement>;
  @ViewChild('background') backgroundRef: ElementRef<HTMLElement>;

  state: TState = 'open';

  constructor(
    @Inject(PopupInstance) private popupInstance: PopupInstance<unknown, unknown>,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(document, 'keyup')
        .pipe(
          filter(event => event.keyCode === KEY_CODE.escape),
          returnToAngularZone(this.ngZone),
          takeUntilDestroyed(this),
        )
        .subscribe(() => this.close());
    });

    // компонент был скопирован из ui-kit с отсутствующим takeUntil/takeUntilDestroyed
    // см. оригинальный компонент для большей ясности
    /* eslint-disable rxjs-angular/prefer-takeuntil */
    this.popupInstance.onShouldClose().subscribe(() => {
      this.state = 'close';
      this.changeDetectorRef.markForCheck();
    });
    /* eslint-enable rxjs-angular/prefer-takeuntil */
  }

  ngOnDestroy(): void {}

  close(): void {
    if (this.closeable) {
      this.popupInstance.close({ rejected: true, value: this.closeValue });
    }
  }

  onAnimationDone(): void {
    if (this.state === 'close') {
      this.popupInstance.detach();
    }
  }

  scrollBodyToBottom(): void {
    if (!this.bodyRef || !this.bodyRef.nativeElement) {
      return;
    }

    this.bodyRef.nativeElement.scrollTo({
      top: this.bodyRef.nativeElement.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  onBackgroundClick(event: MouseEvent): void {
    if (!this.backgroundRef || event.target !== this.backgroundRef.nativeElement) {
      return;
    }

    this.close();
  }
}
