import { Routes } from '@angular/router';
import { NotFullyRegisteredGuard } from 'user/core/routing/guard/not-fully-registered-guard';

export const prosvRoutes: Routes = [
  {
    canActivate: [NotFullyRegisteredGuard],
    path: 'prosv',
    loadChildren: () =>
      import('../../../integrations/prosv/+auth/auth-page.module').then(module => module.AuthPageModule),
  },
  {
    path: 'login',
    canActivate: [NotFullyRegisteredGuard],
    loadChildren: () =>
      import('../../../integrations/prosv/+auth/auth-page.module').then(module => module.AuthPageModule),
  },
];
