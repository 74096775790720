<a
  class="logo"
  routerLinkActive="-active"
  [routerLink]="logoLink"
  [routerLinkActiveOptions]="{ exact: true }"
  [target]="target"
>
  <div *ngIf="isMainPage" class="logo-image-lecta"></div>
  <div *ngIf="!isMainPage" class="logo-image"></div>
  <div *ngIf="!(isMobile && isMainPage)" class="logo-separator"></div>
  <img *ngIf="!isMainPage" class="logo-notebook" alt="" [src]="logoSvg" />
  <div *ngIf="!(isAnonymousProviderUser$ | async) && !isMainPage" class="logo-title" >Рабочие тетради</div>
  <div *ngIf="isMainPage" class="logo-title-main" >Образовательная платформа <br>группы компаний «Просвещение»</div>

</a>
