import { Breakpoint } from './layout.interface';

export const BreakpointS: Breakpoint = {
  minWidth: null,
  maxWidth: 751,
};

export const BreakpointM: Breakpoint = {
  minWidth: 752,
  maxWidth: 1019,
};

export const BreakpointL: Breakpoint = {
  minWidth: 1020,
  maxWidth: 1259,
};

export const BreakpointXL: Breakpoint = {
  minWidth: 1260,
  maxWidth: null,
};
