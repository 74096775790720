import { Routes } from '@angular/router';
import { Page404Component } from '../component/page-404/page-404';
import {
  defaultErrorPagesRoutes,
  diariesIntegrationsRoutes,
  homeworkRoutes,
  landingRoutes,
  prosvRoutes,
  studentRoutes,
  teacherRoutes,
  userRoutes,
  cmsRoutes,
} from './route';
import {SetTeacherRoleGuard} from "../../user/core/routing/guard/set-teacher-role";
import {AuthTestComponent} from "../../auth-test/auth-test.component";
import {LoginComponent} from "../../integrations/prosv/+auth/component/login/login.component";

/*
 * RelocatePluginComponent заглушка на случай перезда.
 * Для того чтобы подключить заглушку необходимо добавить в Routes первым элементом - { path: '**', component: RelocatePluginComponent },
 * И не забыть импортировать RelocatePluginComponent
 * */
export const routes: Routes = [
  {
    canActivate: [SetTeacherRoleGuard],
    path: 'auth-test',
    component: AuthTestComponent,
  },
  {
    canActivate: [SetTeacherRoleGuard],
    path: 'auth-test-login',
    component: LoginComponent,
  },


  ...homeworkRoutes,
  ...studentRoutes,
  ...teacherRoutes,
  ...userRoutes,
  ...landingRoutes,
  ...diariesIntegrationsRoutes,
  ...defaultErrorPagesRoutes,
  ...prosvRoutes,
  ...cmsRoutes,

  // all missed pages
  { path: '**', component: Page404Component },
];
