<lecta-dialog>
  <div lectaDialogHeader>
    <div class="title-image"></div>
    <div class="title">Не удалось отправить задание в Дневник.ру</div>
  </div>

  <ng-container lectaDialogBody>
    <div class="dialog-body">
      Ссылка на задание создана, но нам не удалось передать ее в Дневник.ру. Вы можете скопировать ссылку и отправить ее
      ученикам самостоятельно любым удобным способом.
    </div>
  </ng-container>

  <div lectaDialogFooter>
    <lecta-button class="button" type="primary-blue" (click)="close()">Перейти к ссылке на задание</lecta-button>
  </div>
</lecta-dialog>
