import { NgModule, ModuleWithProviders } from '@angular/core';
import { ILectaApiConfig } from './lecta-api.interface';
import { LECTA_API_CONFIG } from './lecta-api.const';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LectaCacheInterceptor } from './service/cache-interceptor';

export function defaultConfigFactory(): ILectaApiConfig {
  return {};
}

@NgModule({
  imports: [HttpClientModule],
})
export class LectaApiModule {
  static forRoot(configFactory: () => ILectaApiConfig = defaultConfigFactory): ModuleWithProviders<LectaApiModule> {
    return {
      ngModule: LectaApiModule,
      providers: [
        { provide: LECTA_API_CONFIG, useFactory: configFactory },
        { provide: HTTP_INTERCEPTORS, useClass: LectaCacheInterceptor, multi: true },
      ],
    };
  }
}
