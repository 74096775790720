import { LectaTrackingEventData } from 'tracking/interfaces/lecta-tracking-event-data.interface';
import { LECTA_TRACKING_EVENT_NAME } from 'tracking/const';
import { createEventHelpers } from './create-event.utility';
import { LectaEvents } from 'tracking/classes/lecta-events.class';

const trackingEvents = new LectaEvents({ group: 'skysmart.edu.tracking' });

export const lectaTrackingEvents = {
  track: createEventHelpers<LectaTrackingEventData>(trackingEvents, LECTA_TRACKING_EVENT_NAME),
};
