import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TeacherAuthComponent } from './component/teacher-auth/teacher-auth';
import { TeacherAuthViewComponent } from './component/teacher-auth/teacher-auth-view';
import { LectaIconModule } from 'lecta-ui/icon';
import { LectaButtonModule } from 'lecta-ui/button';
import { PopoverModule } from 'ui/popover/module';

@NgModule({
  imports: [CommonModule, RouterModule, PopoverModule, LectaIconModule, LectaButtonModule],
  declarations: [TeacherAuthComponent, TeacherAuthViewComponent],
  exports: [TeacherAuthComponent],
})
export class TeacherAuthModule {}
