import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/module';
import { environment } from 'environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.app.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.thirdpartyServices.microSentry.dsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],
});
// dummy handler to fix issue with zone.js
// https://github.com/angular/angular/issues/45020
document.addEventListener('mousemove', () => {}, { passive: false, capture: true });

platformBrowserDynamic().bootstrapModule(AppModule);
