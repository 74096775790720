import { Injectable } from '@angular/core';
import { LectaLocalStorageService } from 'core/local-storage';
import { CurrentUserService } from 'user/core/service/current-user';
import { AnalyticsApi } from 'tracking/api/analytics';
import { Subject } from 'rxjs';
import { mapTo, switchMap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';

const approvedUtmMarks = [
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_campaign',
  'utm_content',
  'source_type',
  'product',
  'manager',
];

const UTMS_LS_KEY = 'last_utms';
const REFFERER_URL_LS_KEY = 'last_refferer_url';
const IS_UTMS_SAVED_LS_KEY = 'is_last_utms_saved';

export interface UtmKeyValue {
  utmKey: string;
  utmValue: string | null;
}

export interface UtmsAndRefferer {
  utmMarks: string;
  reffererUrl: string;
}

@Injectable({ providedIn: 'root' })
export class UtmService {
  filledUtms: UtmKeyValue[] = [];

  private changedUtmsNeedSave = new Subject<UtmsAndRefferer>();

  constructor(
    private localStorageService: LectaLocalStorageService,
    private currentUserService: CurrentUserService,
    private analyticsApi: AnalyticsApi,
  ) {
    this.changedUtmsNeedSave
      .pipe(
        switchMap(utms => this.currentUserService.authorizedUser$.pipe(take(1), mapTo(utms))),
        switchMap(utms => this.analyticsApi.saveUtm(utms)),
      )
      .subscribe({
        next: () => this.changedUtmsOrReffererSaved(),
        error: error => {
          throw new Error(error)
        },
      });
  }

  storeUtms(): void {
    const utmMarksString = window.location.search.slice(1);
    const reffererUrl = UtmService.getRefferer();

    if (!utmMarksString && !reffererUrl) {
      this.checkUnsavedUtms();
      return;
    }

    const queryParams = new URLSearchParams(utmMarksString);

    this.filledUtms = utmMarksString
      ? approvedUtmMarks
          .map(utmKey => ({ utmKey, utmValue: queryParams.get(utmKey) }))
          .filter(({ utmValue }) => !!utmValue)
      : [];

    if (this.filledUtms.length || reffererUrl) {
      this.saveUtmToLocalstorageIfChanged(utmMarksString, reffererUrl);
    }
  }

  getLastUtms(): string | undefined {
    return this.localStorageService.get(UTMS_LS_KEY) || undefined;
  }

  private saveUtmToLocalstorageIfChanged(utmMarksString: string, reffererUrl: string): void {
    const lastSavedUtms = this.getLastUtms() || '';
    const lastSavedRefferer = this.getLastRefferer() || '';

    if (lastSavedUtms !== utmMarksString || lastSavedRefferer !== reffererUrl) {
      this.localStorageService.set<string>(UTMS_LS_KEY, utmMarksString);
      this.localStorageService.set<string>(REFFERER_URL_LS_KEY, reffererUrl);
      this.localStorageService.set<boolean>(IS_UTMS_SAVED_LS_KEY, false);

      this.changedUtmsNeedSave.next({ utmMarks: utmMarksString, reffererUrl });
    }
  }

  private checkUnsavedUtms(): void {
    const isLastUtmsSaved = this.localStorageService.get(IS_UTMS_SAVED_LS_KEY);

    if (isLastUtmsSaved !== false) {
      return;
    }

    const utmMarks = this.getLastUtms()!;
    const reffererUrl = this.getLastRefferer()!;
    if (utmMarks || reffererUrl) {
      this.changedUtmsNeedSave.next({ utmMarks, reffererUrl });
    } else {
      this.changedUtmsOrReffererSaved();
    }
  }

  private changedUtmsOrReffererSaved(): void {
    this.localStorageService.set<boolean>(IS_UTMS_SAVED_LS_KEY, true);
  }

  private getLastRefferer(): string | undefined {
    return this.localStorageService.get(REFFERER_URL_LS_KEY) || undefined;
  }

  private static getRefferer(): string {
    const reffererUrl = document.referrer;
    return reffererUrl.includes(environment.app.rootDomain) ? '' : reffererUrl;
  }
}
