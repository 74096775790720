import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LectaMainContainerComponent } from 'lecta-ui/notification/components/main-container/lecta-main-container.component';
import { LectaMainNotificationsComponent } from 'lecta-ui/notification/components/main-notifications/lecta-main-notifications.component';
import { LectaNotificationContainerComponent } from './components/notification-container/lecta-notification-container.component';
import { LectaNotificationItemComponent } from './components/notification-item/lecta-notification-item.component';
import { LectaSecondaryContainerComponent } from './components/secondary-container/lecta-secondary-container.component';
import { LectaSecondaryNotificationsComponent } from './components/secondary-notifications/lecta-secondary-notifications.component';
import { LectaNotificationOffsetDirective } from './directives/lecta-notification-offset.directive';
import { LectaIconModule } from 'lecta-ui/icon';

@NgModule({
  imports: [CommonModule, LectaIconModule],
  declarations: [
    LectaMainContainerComponent,
    LectaMainNotificationsComponent,
    LectaNotificationContainerComponent,
    LectaNotificationItemComponent,
    LectaSecondaryContainerComponent,
    LectaSecondaryNotificationsComponent,
    LectaNotificationOffsetDirective,
  ],
  exports: [LectaNotificationOffsetDirective, LectaNotificationItemComponent],
})
export class LectaNotificationModule {}
