import { Injectable } from '@angular/core';
import { DnevnikruBindService } from '../../dnevnikru/core/service/bind';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(private dnevnikruBindService: DnevnikruBindService) {}

  init(): void {
    this.subscribeRedirectToBinding();
  }

  // once logged-in user will be redirected to the particular binding page
  // if integration params are set
  private subscribeRedirectToBinding(): void {
    this.dnevnikruBindService.subscribeRedirectToBinding();
  }
}
