import { Injectable } from '@angular/core';
import { TrackingTarget } from '../interfaces/tracking.interface';
import { AnalyticsApi } from '../api/analytics';
import { TrackingTargetKey } from '../const';
import { USER_AGENT } from 'core/device';

@Injectable({ providedIn: 'root' })
export class SkysmartTrackingTargetService implements TrackingTarget {
  key = TrackingTargetKey.skysmart;

  constructor(private analyticsApi: AnalyticsApi) {}

  init(): void {}

  trackEvent(event: string, data?: Object): boolean {
    this.analyticsApi.trackEvent({ eventType: event, payload: data, userAgent: USER_AGENT }).subscribe(
      () => {},
      error => {
        throw new Error(error);
      },
    );

    return true;
  }

  trackPageView(): void {
    // do nothing
  }

  setUserProperty(_prop: string, _value: unknown): boolean {
    return false;
  }

  isEventCanBeTrackedBy(): boolean {
    return true;
  }
}
