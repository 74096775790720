import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { createInternalServerErrorStatusState } from '../../../meta/utils/create-state';
import { ORIGINAL_URL_QUERY_PARAM } from '@lecta/core/routing';

@Component({
  selector: 'app-unknown-error-page',
  templateUrl: 'unknown-error-page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnknownErrorPageComponent implements OnInit {
  errorState = createInternalServerErrorStatusState();

  constructor(private activatedRoute: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    // https://github.com/angular/angular/issues/17004#issuecomment-414459149
    const originalUrl = this.activatedRoute.snapshot.queryParams[ORIGINAL_URL_QUERY_PARAM];

    if (originalUrl) {
      this.location.replaceState(originalUrl);
    }
  }
}
