import { NgZone } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';

export function returnToAngularZone<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => {
    return new Observable<T>(subscriber =>
      source.subscribe(
        value => ngZone.run(() => subscriber.next(value)),
        error => ngZone.run(() => subscriber.error(error)),
        () => ngZone.run(() => subscriber.complete()),
      ),
    );
  };
}
