import { LectaTrackingEventData, lectaTrackingEvents } from 'core/tracking';

const LECTA_FILE_CREATE_INTENTION_ERROR_EVENT_NAME = 'file_upload_createIntention_error';
const LECTA_FILE_AWS_UPLOAD_ERROR_EVENT_NAME = 'file_upload_awsUpload_error';
const LECTA_FILE_REPORT_UPLOAD_RESULT_ERROR_EVENT_NAME = 'file_upload_reportUploadResult_error';

const trackLectaOpenAnswerEvent = (scope: string, name: string) => (data: LectaTrackingEventData['data']) => {
  lectaTrackingEvents.track.send({ name, data: { ...(data || {}), scope } });
};

export const lectaFileEvents = {
  createIntentionError: (scope: string, data: LectaTrackingEventData['data']) =>
    trackLectaOpenAnswerEvent(scope, LECTA_FILE_CREATE_INTENTION_ERROR_EVENT_NAME)(data),
  awsUploadError: (scope: string, data: LectaTrackingEventData['data']) =>
    trackLectaOpenAnswerEvent(scope, LECTA_FILE_AWS_UPLOAD_ERROR_EVENT_NAME)(data),
  reportUploadResultError: (scope: string, data: LectaTrackingEventData['data']) =>
    trackLectaOpenAnswerEvent(scope, LECTA_FILE_REPORT_UPLOAD_RESULT_ERROR_EVENT_NAME)(data),
};

export const LECTA_FILE_EVENTS = [
  LECTA_FILE_CREATE_INTENTION_ERROR_EVENT_NAME,
  LECTA_FILE_AWS_UPLOAD_ERROR_EVENT_NAME,
  LECTA_FILE_REPORT_UPLOAD_RESULT_ERROR_EVENT_NAME,
];
