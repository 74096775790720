import { Params } from '@angular/router';
import { DnevnikruCreateHomeworkParams, DnevnikruParams, DnevnikruStartHomeworkParams } from './interface';
import { IJwtUser } from 'user/core/interface';

export function extractDnevnikruParams(queryParams: Params): DnevnikruParams | null {
  const dnevnikRuToken = queryParams.dnevnikRuToken;
  const dnevnikRuState = queryParams.dnevnikRuState;

  if (dnevnikRuToken) {
    return {
      dnevnikRuToken,
      dnevnikRuState,
    };
  }

  return null;
}

export function extractDnevnikruCreateHomeworkParams(queryParams: Params): DnevnikruCreateHomeworkParams | null {
  const dnevnikRuLesson = queryParams.dnevnikRuLesson;
  const dnevnikRuGroup = queryParams.dnevnikRuGroup;
  const dnevnikRuSubject = queryParams.dnevnikRuSubject;
  const dnevnikRuType = queryParams.dnevnikRuType;
  const providerType = queryParams.providerType;
  const returnUrl = queryParams.returnUrl;

  if (dnevnikRuLesson) {
    return {
      dnevnikRuLesson,
      dnevnikRuGroup,
      dnevnikRuSubject,
      dnevnikRuType,
      providerType,
      returnUrl,
    };
  }

  return null;
}

export function extractDnevnikruCreateHomeworkParamsNew(queryParams: Params): DnevnikruCreateHomeworkParams | null {
  const dnevnikRuLesson = queryParams.lesson_id;
  const dnevnikRuGroup = queryParams.group_id;
  const dnevnikRuSubject = queryParams.subject_id;
  const dnevnikRuType = queryParams.worktype_id;
  const providerType = queryParams.provider;

  if (dnevnikRuLesson) {
    return {
      dnevnikRuLesson,
      dnevnikRuGroup,
      dnevnikRuSubject,
      dnevnikRuType,
      providerType,
    };
  }

  return null;
}

export function extractDnevnikruStartHomeworkParams(queryParams: Params): DnevnikruStartHomeworkParams | null {
  const taskHash = queryParams.taskHash;

  if (taskHash) {
    return {
      taskHash,
    };
  }

  return null;
}

export function canBindUser(user: IJwtUser | null): boolean {
  return !!user && !!user.email;
}
