<div
  data-qa-id="popup-background"
  class="root"
  [class.-big]="big"
  [class.-page-mode]="pageMode"
  [@visibility]="state"
  (@visibility.done)="onAnimationDone()"
  (click)="onBackgroundClick($event)"
  #background
>
  <div
    class="popup"
    [class.-no-padding]="removePadding"
    [style.width]="width"
    [style.maxWidth]="width ? width : null"
    [style.maxHeight]="maxHeight"
    [style.backgroundColor]="backgroundColor ? backgroundColor : null"
  >
    <div class="popup-header" [class.-with-close-icon]="closeable">
      <ng-content select="[popupTitle]"></ng-content>
    </div>

    <lecta-icon
      *ngIf="closeable"
      class="popup-cross-icon"
      name="cross"
      width="40"
      height="40"
      (click)="close()"
      data-qa="close-popup-btn"
    ></lecta-icon>

    <div *ngIf="hasScroll" class="popup-body -has-scroll" appNativeScroll #body>
      <ng-container *ngTemplateOutlet="popupBody"></ng-container>
    </div>
    <div *ngIf="!hasScroll" class="popup-body" #body>
      <ng-container *ngTemplateOutlet="popupBody"></ng-container>
    </div>

    <ng-template #popupBody>
      <ng-content select="[popupBody]"></ng-content>
    </ng-template>

    <div class="popup-footer" [class.-flex-wrap]="footerFlexWrap">
      <ng-content select="[popupFooterCustomContent]"></ng-content>
    </div>
  </div>
</div>
