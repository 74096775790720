import { Injectable } from '@angular/core';
import { Icon, IconSize, PackName, Packs, SymbolAdded } from '../lecta-icon.interface';
import { ICON_GLOBAL_SVG_ID } from '../lecta-icon.const';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LectaIconService {
  private globalSvgElement: SVGElement;
  private packs: Packs = new Map();

  constructor() {
    this.initGlobalSvgElement();
  }

  add({ pack = 'default', name, content, size }: Partial<Icon> & Pick<Icon, 'name' | 'content'>): void {
    const sizes: IconSize[] = size ? [size] : ['s', 'm'];

    sizes.forEach(iterableSize => {
      if (this.getIcon(pack, name, iterableSize)) {
        if (!environment.app.production) {
          throw new Error(
            `IconService: icon (pack: '${pack}', name: '${name}' and size: '${iterableSize}') already exists`,
          );
        }
        return;
      }
      this.setIcon({ pack, name, size: iterableSize, content, symbolAdded: false });
    });
  }

  createIconSymbol(pack: PackName, name: string, size: IconSize = 'm'): void {
    const icon = this.getIcon(pack, name, size);

    if (icon && icon.symbolAdded) {
      return;
    } else if (!icon) {
      throw new Error(`IconService: icon (pack: '${pack}', name: '${name}' and size: '${size}') is not registered`);
    }

    const symbolId = this.constructSymbolId(pack, name, size);
    // cann't search for `#smth/smth` id, should be `#smth\\/smth`
    const symbolElementSelector = `#${symbolId.replace(/\//g, '\\/')}`;
    // don't override already created icon symbol (e.x. when loading icons both from widgets and platrofm)
    const alreadyAddedIconElement = this.globalSvgElement.querySelector(symbolElementSelector);

    if (!alreadyAddedIconElement) {
      const symbolElement = this.createIconSymbolElement(icon);

      this.globalSvgElement.appendChild(symbolElement);
    }

    this.setIcon({ ...icon, symbolAdded: true });
  }

  constructSymbolId(pack: PackName, name: string, size: IconSize = 'm'): string {
    return `${pack}/${name}/${size}`;
  }

  /**
   * @internal
   * For storybook only
   */
  getKnownIcons(): Packs {
    return this.packs;
  }

  private initGlobalSvgElement(): void {
    this.globalSvgElement =
      document.querySelector<SVGElement>(`#${ICON_GLOBAL_SVG_ID}`) ?? LectaIconService.createGlobalSvgElement();
  }

  private createIconSymbolElement(icon: Icon): SVGSymbolElement {
    const symbolElementHTML = icon.content.default.replace('<svg', '<symbol').replace('</svg>', '</symbol>');

    // cann't directly create symbol element
    const divElement = document.createElement('div');
    // eslint-disable-next-line functional/immutable-data
    divElement.innerHTML = `<svg>${symbolElementHTML}</svg>`;
    const symbolElement = divElement.querySelector('symbol')!;

    const id = this.constructSymbolId(icon.pack, icon.name, icon.size);
    symbolElement.setAttribute('id', id);

    symbolElement.removeAttribute('xmlns');
    symbolElement.removeAttribute('xmlns:xlink');
    // remove original size, will use size from parent element
    symbolElement.removeAttribute('width');
    symbolElement.removeAttribute('height');
    symbolElement.removeAttribute('fill');
    symbolElement.removeAttribute('stroke');
    symbolElement.removeAttribute('style');

    return symbolElement;
  }

  private setIcon(icon: Icon & SymbolAdded): void {
    if (!this.packs.has(icon.pack)) {
      this.packs.set(icon.pack, new Map());
    }

    const pack = this.packs.get(icon.pack)!;
    if (!pack.has(icon.name)) {
      pack.set(icon.name, new Map());
    }

    const iconsBySize = pack.get(icon.name)!;
    if (!iconsBySize.has(icon.size)) {
      iconsBySize.set(icon.size, icon);
    }
  }

  private getIcon(pack: PackName, name: string, size: IconSize): (Icon & SymbolAdded) | undefined {
    return this.packs.get(pack)?.get(name)?.get(size);
  }

  private static createGlobalSvgElement(): SVGElement {
    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svgElement.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
    svgElement.setAttribute('id', ICON_GLOBAL_SVG_ID);

    // eslint-disable-next-line functional/immutable-data
    svgElement.style.display = 'none';

    document.body.appendChild(svgElement);

    return svgElement;
  }
}
