import { InjectionToken } from '@angular/core';
import { TrackingTarget } from './interfaces/tracking.interface';

export const TRACKING_TARGETS_PROVIDER = new InjectionToken<TrackingTarget>('skysmart.tracking.targets');

export const enum TrackingTargetKey {
  ga = 'ga',
  ym = 'ym',
  skysmart = 'skysmart',
  vk = 'vk',
  fb = 'fb',
  tiktok = 'tiktok',
}

export const EVENT_STUDENT_VISIT_FINISH_PAGE = 'student_visit_finish_page';
export const EVENT_STUDENT_VISIT_CABINET_PAGE = 'student_visit_cabinet_page';

export const EVENT_STUDENT_LANDED_PROMO = 'student_landed_promo';
export const EVENT_STUDENT_HOMEWORK_CHOSEN = 'student_homework_chosen';

export const EVENT_INTERACTIVE_HINT_SHOW = 'interactive-hint_show';
export const EVENT_INTERACTIVE_HINT_OPEN = 'interactive-hint_open';
export const EVENT_INTERACTIVE_HINT_CONTINUE = 'interactive-hint_continue';
export const EVENT_INTERACTIVE_HINT_FINISH = 'interactive-hint_finish';
export const EVENT_INTERACTIVE_HINT_FEEDBACK = 'interactive-hint_feedback';
export const EVENT_INTERACTIVE_HINT_RATE = 'interactive-hint_rate';

export const EVENT_HW_NEW_TASK_OPENED = 'new_task_opened';
export const EVENT_HW_NEW_AUTH_STARTED = 'auth_started';

export const EVENT_SUBSCRIPTIONS_SUBSCRIPTIONS_OPENED = 'subscriptions_opened';
export const EVENT_SUBSCRIPTIONS_NEW_SUBSCRIPTION_REQUESTED = 'new_subscription_requested';

export const EVENT_STEP_PROMO_SHOWED = 'step_promo_showed';
export const EVENT_STEP_PROMO_SELECTED = 'step_promo_selected';
export const EVENT_STEP_PROMO_UNSELECTED = 'step_promo_unselected';
export const EVENT_STEP_PROMO_PREVIEW_CLICKED = 'step_promo_preview_clicked';

export const EVENT_STUDENT_CONTENT_FEED_SUBSCRIPTION_REQUESTED = 'student_content_feed_subscription_requested';
export const EVENT_STUDENT_FOOTER_SOCIAL_MEDIA_NAVIGATE = 'student_footer_social_media_navigate';
export const EVENT_STUDENT_FOOTER_COURSES_NAVIGATE = 'student_footer_courses_navigate';

export const EVENT_PROSVET_STEP_APPROVE = 'prosvet_step_approve';
export const EVENT_PROSVET_STEP_COMMENT = 'prosvet_step_comment';
export const EVENT_PROSVET_STEP_APPROVE_OR_COMMENT_ERROR = 'prosvet_step_approve_or_comment_error';

export const EVENT_TEACHER_JOURNAL_MARK_TYPE_CHANGED = 'teacher_journal_mark_type_changed';

export const LECTA_TRACKING_EVENT_NAME = 'track';
export const EVENTS_CUSTOM_EVENT_NAME = 'skyEventsCustomEvent';
export const EVENTS_BUFFER_DELAY_MS = 10;
