import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { IconSize, PackName } from '../../lecta-icon.interface';
import { LectaIconService } from '../../services/lecta-icon.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-icon',
  templateUrl: './lecta-icon.component.html',
  styleUrls: ['./lecta-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectaIconComponent implements OnInit, OnChanges {
  @Input() name?: string;
  @Input() pack: PackName = 'default';
  @Input() size: IconSize = 'm';
  @Input() svgContent?: string;
  // native class attr will write here. Needs to save outer classes due to overriding class attr by HostBinding
  @Input() class = '';
  @Input() width?: number | string;
  @Input() height?: number | string;

  @HostBinding('class') hostClasses: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private lectaIconService: LectaIconService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.setSizeClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.name || changes.pack || changes.size) && this.name && this.pack && this.size) {
      this.lectaIconService.createIconSymbol(this.pack, this.name!);
    }

    if (changes.size && (!changes.width || !changes.height)) {
      this.setSizeClass();
    }

    if (changes.width || changes.height) {
      this.updateSize();
    }
  }

  getSymbolId(): string {
    return this.lectaIconService.constructSymbolId(this.pack, this.name!);
  }

  private getSize(): IconSize {
    return this.size;
  }

  private setSizeClass(): void {
    this.hostClasses = this.getSize() && this.getSize() !== "default"  ? [this.class, `-size-${this.getSize()}`].join(' ') : this.class;
  }

  private updateSize(): void {
    const width = this.width ? `${this.width}px` : null;
    const height = this.height ? `${this.height}px` : null;

    this.renderer.setStyle(this.elementRef.nativeElement, 'width', width);
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', height);
  }
}
