import { Injectable } from '@angular/core';
import { LectaApiService } from 'core/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { AppFeatures } from '../interface';

@Injectable({ providedIn: 'root' })
export class ProductFeaturesApiService {
  private endpoints = [environment.endpoints.api];

  constructor(private apiService: LectaApiService) {}

  getFeatures(): Observable<{ items: AppFeatures }> {
    return this.apiService.get<{ items: AppFeatures }>(this.endpoints, '/api/v1/product-feature/', {
      retry: 1,
    });
  }
}
