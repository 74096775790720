import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LectaDialogInstance } from '@lecta/ui/modal-dialog';
import { DnevnikruCreateHomeworkSuccessPopupValue } from '../../core/interface';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dnevnikru-create-homework-success-popup',
  templateUrl: './dnevnikru-create-homework-success-popup.component.html',
  styleUrls: ['./dnevnikru-create-homework-success-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DnevnikruCreateHomeworkSuccessPopupComponent {
  lessonLink = 'https://schools.dnevnik.ru/journals/?tab=planning';

  constructor(
    @Inject(LectaDialogInstance)
    private dialogInstance: LectaDialogInstance<null | DnevnikruCreateHomeworkSuccessPopupValue, null>,
    private router: Router,
  ) {}

  close(): void {
    this.dialogInstance.close({ rejected: false });
  }

  goToLicense(): void {
    this.router.navigateByUrl('/teacher/terms-of-use');
    this.dialogInstance.close({ rejected: false });
  }
}
