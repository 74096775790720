import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ExtraOptions,
  NavigationStart,
  Router,
  ROUTER_CONFIGURATION,
  Scroll,
} from '@angular/router';
import {Observable, OperatorFunction, switchMap, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, shareReplay, startWith, take} from 'rxjs/operators';
import { EduRouteData } from '../interface';
import { LectaDeviceService } from 'core/device';
import { isDisabledScrollToTop } from '../scroll-behavior.utils';
import { Store } from 'core/store';
import _merge from 'lodash/merge';
import { getSkipLocationChangeExtras, RoutingHelpersService } from '@lecta/core/routing';
import {CurrentUserService} from "../../user/core/service/current-user";
import {ProductFeaturesService} from "../../app/service/product-features";
import {ProductFeatures} from "../../app/interface";

@Injectable({ providedIn: 'root' })
export class SkysmartRoutingHelperService {
  liveChatHidden$: Observable<boolean>;
  activeUrl$: Observable<string>;
  activePath$: Observable<string>;
  navigationStart$: Observable<NavigationStart>;

  private store = new Store({ previousUrl: undefined as string | undefined });

  constructor(
    private router: Router,
    private coreRoutingHelpersService: RoutingHelpersService,
    private viewportScroller: ViewportScroller,
    private currentUserService: CurrentUserService,
    private productFeatureService: ProductFeaturesService,
    @Inject(ROUTER_CONFIGURATION) private routerConfig: ExtraOptions,
  ) {
    const currentRouteData$ = this.coreRoutingHelpersService.routeChanged$.pipe(
      map(() => this.coreRoutingHelpersService.getCurrentRouteData<EduRouteData>()),
    );

    this.navigationStart$ = this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart),
    );

    this.liveChatHidden$ = currentRouteData$.pipe(
      map(routeData => !!routeData.hideReamaze || (LectaDeviceService.isMobile() && !routeData.showReamazeOnMobile)),
      distinctUntilChanged(),
    );

    this.activeUrl$ = this.coreRoutingHelpersService.routeChanged$.pipe(
      map(event => event.urlAfterRedirects),
      // по какой-то причине Router.url возвращает '/' вместо настоящего урла
      // поэтому для корректного первого значения, до след. routeChanged$
      // вместо него возвращаем url на основе window.location
      startWith(SkysmartRoutingHelperService.getCurrentUrlFromLocation()),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

    this.coreRoutingHelpersService.navigationStart$.subscribe(() =>
      this.store.updateFields({ previousUrl: this.getCurrentUrl() }),
    );
    this.activePath$ = this.activeUrl$.pipe(map(url => url.split('?')[0]));
    this.consumeScrollEvents();


  }

  getHierarchicalRouteParams<TParams extends Object = {}>(): Observable<TParams> {
    return this.coreRoutingHelpersService.routeChanged$.pipe(map(() => this.getCurrentRouteParams()));
  }

  getCurrentRouteParams<TParams extends Object = {}>(route?: ActivatedRouteSnapshot): TParams {
    const snapshot = route ?? this.router.routerState.snapshot.root;
    let params = {} as TParams;

    if (!snapshot.root) {
      return params;
    }

    let currentRoute: ActivatedRouteSnapshot | null = snapshot.root;

    while (currentRoute) {
      params = _merge(params, currentRoute.params);
      currentRoute = currentRoute.firstChild;
    }

    return params;
  }

  // analog libs/vimbox-core/routing/src/service/routing-helpers.ts#getCurrentRouteData только с дип-мержом для переопределения параметров
  getCurrentRouteData<TParams extends Object = {}>(): TParams {
    const snapshot = this.router.routerState.snapshot;
    let data = {} as TParams;

    if (!snapshot.root) {
      return data;
    }
    this.checkMash(snapshot.url);
    let currentRoute: ActivatedRouteSnapshot | null = snapshot.root;

    while (currentRoute) {
      data = _merge(data, currentRoute.data);
      currentRoute = currentRoute.firstChild;
    }

    return data;
  }

  fallbackToErrorPage<T>(url: string): OperatorFunction<T, T> {
    return catchError(error => {
      const skipLocationChangeExtras = getSkipLocationChangeExtras(url);
      if (error instanceof HttpErrorResponse) {
        const status = error.status ?? 0;
        const command = SkysmartRoutingHelperService.getRouterCommandByResponseStatus(status);
        this.router.navigate(command, skipLocationChangeExtras);
      } else {
        this.router.navigate(['/unknown-error'], skipLocationChangeExtras);
      }

      return throwError(error);
    });
  }

  getCurrentUrl(): string {
    return this.router.url;
  }

  getPreviousUrl(): string | undefined {
    return this.store.get('previousUrl');
  }

  private static getRouterCommandByResponseStatus(status: number): string[] {
    switch (status) {
      case 401:
      case 403:
        return ['/403'];
      case 404:
        return ['/404'];
      case 0:
        return ['/network-error'];
      default:
        return ['/unknown-server-error'];
    }
  }

  private static getCurrentUrlFromLocation(): string {
    return window.location.pathname + window.location.search + window.location.hash;
  }

  private consumeScrollEvents(): void {
    this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(_e => {
      const skipPositionRestorationToTop = isDisabledScrollToTop(this.router.getCurrentNavigation());
      this.traceCustomScrollStrategy(skipPositionRestorationToTop);
      if (skipPositionRestorationToTop) {
        return;
      }
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  private traceCustomScrollStrategy(_isSkipped: boolean): void {
    if (this.routerConfig.enableTracing) {
      // console.group(`Custom Handler for Router Event: Scroll`);
      // console.log(`Scroll strategy - ${isSkipped ? 'scroll skipped' : 'scroll to top'}`);
      // console.groupEnd();
    }
  }

  /**
   * Блокирует доступ пользователям МЭШ кроме страниц с превью
   * @param url
   * @private
   */
  private checkMash(url: string): void{
    if(url.includes('preview')) {
      return;
    }
    this.productFeatureService
      .isFeatureEnabled(ProductFeatures.isMeshAvailable)
      .pipe(
        switchMap((feature: boolean) => {
          return this.currentUserService.isAnonymousProviderUser$.pipe(take(1), map(isAnonymous => [isAnonymous, feature]))
        }),
      ).subscribe(([isAnonymous, feature]) =>{
        if(isAnonymous && !feature) {
          this.router.navigate(['/mash-unreachable']);
        }
      })
  }
}
