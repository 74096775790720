import { UserFullname } from 'misc/interface';
import { joinObjectTruthyValues } from './join-object-truthy-values';

export function getFullnameWithLeadingName(userFullname: UserFullname, isTeacher: boolean = false): string {
  if(isTeacher) {
    return joinObjectTruthyValues(userFullname, 'name', 'patronymic', 'surname');
  } else {
    return joinObjectTruthyValues(userFullname, 'name', 'surname');
  }
}

export function getFullnameWithLeadingSurname(userFullname: UserFullname): string {
  return joinObjectTruthyValues(userFullname, 'surname', 'name');
}
