import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProsvIdAuthService } from '../service/prosv-id-auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CurrentUserService } from '../../../user/core/service/current-user';
import { filterEmpty } from '@lecta/core/rxjs';
import { extractExternalProviderQueryParam } from '../helpers';

@Injectable({ providedIn: 'root' })
export class SilentAuthGuard implements CanActivate {
  constructor(
    private prosvIdAuthService: ProsvIdAuthService,
    private currentUserService: CurrentUserService,
  ) {}

  canActivate({ queryParams }: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const provider = extractExternalProviderQueryParam(queryParams);
    const authState = new BehaviorSubject<any | null>(null);
    const authState$ = authState.asObservable().pipe(filterEmpty());

    if (provider) {
      return true;
    }

    return this.currentUserService.user$.pipe(
      switchMap(user => {
        if (user) {
          return of(true);
        }

        return of(null);
      }),
      tap(value => {
        if (typeof value == 'boolean' || value === null) {
          return;
        }

        // @ts-ignore
        value.ready(state => {
          authState.next(state);
        });
      }),
      switchMap(value => {
        if (typeof value == 'boolean' || value === null) {
          return of(value);
        }

        return authState$;
      }),
      map(value => {
        if (typeof value == 'boolean') {
          return value;
        }

        if(!value){
          return true;
        }

        if (value.authorized()) {
          this.prosvIdAuthService.openProsvIdAuth({ redirectUrl: state.url });

          return false;
        }

        return true;
      }),
    );
  }
}
