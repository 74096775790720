import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { createForbiddenStatusState } from '../../../meta/utils/create-state';
import { ORIGINAL_URL_QUERY_PARAM } from '@lecta/core/routing';

@Component({
  selector: 'app-page-403',
  templateUrl: 'page-403.html',
  styleUrls: ['page-403.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page403Component implements OnInit {
  errorState = createForbiddenStatusState();
  anotherButton = {
    link: '/',
    description: 'Вернуться на главную'
  }

  constructor(private activatedRoute: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    // https://github.com/angular/angular/issues/17004#issuecomment-414459149
    const originalUrl = this.activatedRoute.snapshot.queryParams[ORIGINAL_URL_QUERY_PARAM];

    if (originalUrl) {
      this.location.replaceState(originalUrl);
    }
  }
}
