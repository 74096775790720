<lecta-dialog>
  <span class="title" lectaDialogHeader>{{ title }}</span>
  <span class="message" lectaDialogBody *ngIf="message">{{ message }}</span>

  <div lectaDialogFooter>
    <lecta-button class="button" type="primary-blue" (click)="confirm()">
      {{ confirmLabel ?? 'Подтвердить' }}
    </lecta-button>
    <lecta-button class="button" type="secondary-blue" (click)="reject()">{{ cancelLabel ?? 'Отменить' }}</lecta-button>
  </div>
</lecta-dialog>
