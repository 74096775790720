import { Injectable } from '@angular/core';
import { UserAuthState, UserConfigStatus } from 'user/core/const';
import { UserConfig } from 'user/core/interface';

@Injectable({ providedIn: 'root' })
export class UserAuthStateService {
  getRegisterState(config: UserConfig): UserAuthState {
    if (config.status === UserConfigStatus.registered) {
      return UserAuthState.fullyRegistered;
    }

    return UserAuthState.partiallyRegistered;
  }

  isFullyRegistered(config: UserConfig): boolean {
    return this.getRegisterState(config) === UserAuthState.fullyRegistered;
  }

  isAnonymousProviderUser(config: UserConfig): boolean {
    return config.providerType === 'mediateka';
  }
}
