import { Injectable} from '@angular/core';
import { filterEmpty } from 'core/rxjs';
import Cookies from 'js-cookie';
import { Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, switchMap } from 'rxjs/operators';
import { Store } from 'core/store';
import { UserRegistryService } from './user-registry';
import { IEduJwtData, IJwtData } from '../interface';
import { LectaApiService } from 'core/api';
import { CurrentUserService } from 'user/core/service/current-user';
import {isProdDomain} from "../../../meta/helpers";
import * as Sentry from "@sentry/angular-ivy";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IStoreEvents {
  authorize: void;
  logout: void;
}

const EDU_TOKEN_NAME = 'token_edu_skysmart';

// this value is not supposed to be equal to actual jwt token TTL ("exp" minus "iat")
// for now it is just a way to manually control token refreshing
const EDU_TOKEN_LOCAL_TTL_MS = 10 * 24 * 3600 * 1000;

@Injectable({ providedIn: 'root' })
export class AuthService {
  protected store = new Store<void, IStoreEvents>();

  onAuthorize$ = this.store.on('authorize');
  onLogout$ = this.store.on('logout');

  constructor(
    private currentUserService: CurrentUserService,
    private userRegistryService: UserRegistryService,
    private apiService: LectaApiService,
  ) {
    this.init();
  }

  authorize(): Observable<void> {
    this.store.fire('authorize');
    return timer(0).pipe(mapTo(undefined));
  }

  saveJwtData(jwtToken: string): void {
    const sendError = (data: IEduJwtData | undefined): void => {
      if(!data){
        throw Error('token is empty');
      }
    }

    try {
      const jwtData = AuthService.getJwtDataFromToken(jwtToken);
      sendError(jwtData);
      if(jwtData){
        this.userRegistryService.addUser(jwtData.id, jwtData, jwtToken);
      }
    } catch(error) {
      Sentry.captureException((error as Error).message+' AuthService');
    }
  }

  logout(): Observable<void> {
    this.clearToken();
    this.userRegistryService.cleanUp();
    this.store.fire('logout');

    return of(undefined);
  }

  clearToken(): void {
    if(isProdDomain()){
      return Cookies.remove(EDU_TOKEN_NAME,{domain:'.lecta.ru'});
    }else{
      return Cookies.remove(EDU_TOKEN_NAME);
    }

  }

  getToken(): string | undefined {
    return Cookies.get(EDU_TOKEN_NAME);
  }

  getJwtData(): IJwtData | null{
    const eduJwtToken = this.getToken();

    if (!eduJwtToken) {
      // throw new Error('AuthService: JWT token is not found');
      return null;
    }

    let jwtData: IEduJwtData | undefined;

    try {
      jwtData = AuthService.parseEduJwtToken(eduJwtToken);
    } catch (error) {
      this.clearToken();
      Sentry.captureException( (error as Error).message+' AuthService');
    }

    return AuthService.mergeDefaultJwtFields(jwtData) as IJwtData;
  }

  // do not use it as login page url depends on current user role
  getLoginPageUrl(): string {
    return '';
  }

  redirectToLoginPage(redirectTo = window.location.href): void {
    redirectTo = encodeURIComponent(redirectTo);

    // eslint-disable-next-line functional/immutable-data
    window.location.href = `/login?returnUrl=${redirectTo}`;
  }

  private init(): void {
    // update current user
    this.userRegistryService.current$
      .pipe(
        distinctUntilChanged(),
        map(currentUser => (currentUser ? { ...currentUser.jwtData } : null)),
      )
      .subscribe(user => {
        this.apiService.invalidateAllResponseCache();
        this.currentUserService.setCurrentUser(user);
      });

    // this is required for auto-loginner that works via cookies
    this.updateJwtDataFromCookies();
    this.initLogoutByTokenExpiration();
    this.initTokenAutorefresh();
  }

  private updateJwtDataFromCookies(): void {
    try {
      const jwtData = this.getJwtData() as IEduJwtData;
      const token = this.getToken();
      // should be filled from cookies just once
      if (!this.userRegistryService.hasUserWithId(jwtData.id)) {
        this.userRegistryService.addUser(jwtData.id, jwtData, token!);
      }
    } catch(error)  {
      Sentry.captureException((error as Error).message+' AuthService');
    }
  }

  private initTokenAutorefresh(): void {
    this.userRegistryService.current$
      .pipe(
        filterEmpty(),
        map(currentUser => currentUser.token),
        filterEmpty(),
        map(token => AuthService.getMsLeftBeforeTokenRefresh(token))
      )
      .subscribe(
        () => {},
        error => {
          throw new Error(error);
        },
      );
  }

  private initLogoutByTokenExpiration(): void {
    // check token actual expiration and do logout in order to reset user state
    this.userRegistryService.current$
      .pipe(
        distinctUntilChanged(),
        filterEmpty(),
        filter(currentUser => {
          return Date.now() >= currentUser.jwtData.exp * 1000;
        }),
        switchMap(currentUser => this.logout().pipe(mapTo(currentUser.jwtData))),
      )
      .subscribe(() => {
        this.redirectToLoginPage();
      });
  }

  private static getJwtDataFromToken(token: string): IEduJwtData | undefined {
    let jwtData: IEduJwtData | undefined;

    try {
      jwtData = AuthService.parseEduJwtToken(token);
    } catch (error) {
      Sentry.captureException((error as Error).message+' AuthService');
    }

    return jwtData ? AuthService.mergeDefaultJwtFields(jwtData) : undefined;
  }

  private static parseEduJwtToken(jwtToken: string): IEduJwtData {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((char: string) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
    const data = JSON.parse(jsonPayload);

    return { ...data, id: data.userId } as IEduJwtData;
  }

  private static mergeDefaultJwtFields(jwtData: IEduJwtData | undefined): IEduJwtData | undefined {
    if(!jwtData){
      return undefined;
    }
    return {
      uiLanguage: null,
      locale: null,
      jwtType: 1,
      ...jwtData,
      name: jwtData.name || '',
      surname: jwtData.surname || '',
      patronymic: jwtData.patronymic || '',
      email: jwtData.email || '',
      avatarUrl: jwtData.avatarUrl || '',
      birthday: jwtData.birthday || '',
    };
  }

  private static getTokenIssuedAtTimestampMs(token: string): number {
    const jwtData = AuthService.parseEduJwtToken(token) as IJwtData & { iat: number };
    return jwtData.iat * 1000;
  }

  private static getMsLeftBeforeTokenRefresh(token: string): number {
    const issuedAt = AuthService.getTokenIssuedAtTimestampMs(token);
    const past = Date.now() - issuedAt;
    return Math.max(EDU_TOKEN_LOCAL_TTL_MS - past, 0);
  }
}
