import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LectaSpinnerComponent } from 'lecta-ui/spinner/components/spinner/lecta-spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LectaSpinnerComponent],
  exports: [LectaSpinnerComponent],
})
export class LectaSpinnerModule {}
