import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LECTA_FILE_CONFIG } from 'core/file/lecta-file.consts';
import { LectaFileConfig } from 'core/file/lecta-file.interfaces';

@NgModule({
  imports: [CommonModule],
})
export class LectaFileModule {
  static forRoot(configFactory: () => LectaFileConfig): ModuleWithProviders<LectaFileModule> {
    return {
      ngModule: LectaFileModule,
      providers: [{ provide: LECTA_FILE_CONFIG, useFactory: configFactory }],
    };
  }
}
