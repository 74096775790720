import { InjectionToken } from '@angular/core';
import { FileConverter, LectaFileConfig } from 'core/file/lecta-file.interfaces';
import { LectaHeicImageConverterService } from 'core/file/services/lecta-heic-image-converter.service';

export const LECTA_FILE_CONFIG = new InjectionToken<LectaFileConfig>('libs.lecta.file.config');

export const FILE_CONVERTER = new InjectionToken<FileConverter[]>('lectaFileConverters', {
  providedIn: 'root',
  factory: () => [new LectaHeicImageConverterService()],
});

export enum LectaFileUploadProcessStepResultType {
  error,
  success,
}

export enum LectaAwsFileUploadEventType {
  complete,
  error,
  unknown,
}
