<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="errorState"
  [showReloadPageButton]="true"
  [anotherActionButton]="anotherButton"
>
  <ng-container title>Ошибка авторизации</ng-container>
  <ng-container description>
    <p>Пожалуйста, попробуйте снова через несколько минут.</p>
  </ng-container>
</app-error-page-layout>
