import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LectaConfirmPopupComponent } from './lecta-confirm-popup.component';
import { ConfirmConfig, ConfirmData } from './lecta-confirm-popup.interface';
import { LectaDialogCloseEvent, LectaDialogService } from 'lecta-ui/modal-dialog';

@Injectable({ providedIn: 'root' })
export class LectaConfirmPopupService {
  constructor(private dialogService: LectaDialogService) {}

  open<T = unknown>(
    data: ConfirmData,
    { skipRejected }: ConfirmConfig = { skipRejected: true },
    component?: Type<T>,
  ): Observable<LectaDialogCloseEvent<void>> {
    const onClose = this.dialogService.open<ConfirmData, void>(component ?? LectaConfirmPopupComponent, data).onClose();

    if (skipRejected) {
      return onClose.pipe(filter(({ rejected }) => !rejected));
    }

    return onClose;
  }
}
