import { Renderer2, ElementRef, RendererStyleFlags2 } from '@angular/core';
import { DS_CSS_VAR_PREFIX, DS_CSS_VAR_DEFAULT_POSTFIX } from './theme.consts';

export function updateComponentCssVars({
  renderer,
  rootElement,
  params,
  componentPrefix,
  type,
}: {
  renderer: Renderer2;
  rootElement: ElementRef<HTMLElement> | null;
  params: string[];
  componentPrefix: string;
  type: string;
}): void {
  if (!rootElement) {
    throw new Error("Didn't pass root element in dsUpdateComponentCssVars function");
  }

  params.forEach(param => {
    const localVar = `${DS_CSS_VAR_PREFIX}-${componentPrefix}-${param}`;
    const varName = `${DS_CSS_VAR_PREFIX}-${componentPrefix}-${type}-${param}`;
    const defaultVarName = `${varName}-${DS_CSS_VAR_DEFAULT_POSTFIX}`;
    const themeVar = `var(${varName}, var(${defaultVarName}))`;

    renderer.setStyle(rootElement!.nativeElement, localVar, themeVar, RendererStyleFlags2.DashCase);
  });
}
